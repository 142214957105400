import React from 'react';
import { Link } from 'react-router-dom';

export function LoginLinks() {
  return (
    <div className="text-sm">
      <Link
        to="/forgot-password"
        className="font-medium text-blue-600 hover:text-blue-500"
      >
        パスワードをお忘れですか？
      </Link>
    </div>
  );
}