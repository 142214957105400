import React from 'react';
import { TimeComparisonGrid } from './TimeComparisonGrid';
import { TimeComparisonSummary } from './TimeComparisonSummary';

export function TimeComparison() {
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">
        作業時間を大幅に削減
      </h2>
      <TimeComparisonGrid />
      <TimeComparisonSummary />
    </div>
  );
}