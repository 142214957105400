import React, { useRef, useState } from 'react';
import { Upload, Download, AlertCircle, CheckCircle } from 'lucide-react';
import { useTemplateStore } from '../../stores/templateStore';
import { useDescriptionTemplateStore } from '../../stores/descriptionTemplateStore';
import { downloadTemplates, readTemplateFile } from '../../utils/templates/importExport';
import { replaceAllTemplates } from '../../db/operations/templateOperations';

export function TemplateImportExport() {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { 
    templates: productTemplates, 
    replaceTemplates: replaceProductTemplates,
    setActiveTemplate,
    folders,
    replaceFolders
  } = useTemplateStore();
  
  const { 
    templates: descriptionTemplates, 
    replaceTemplates: replaceDescriptionTemplates 
  } = useDescriptionTemplateStore();

  const handleExport = async () => {
    try {
      await downloadTemplates(productTemplates, descriptionTemplates, folders);
      setSuccess('テンプレートをエクスポートしました');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      setError('エクスポートに失敗しました');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleImport = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setIsImporting(true);
    setError(null);
    setSuccess(null);

    try {
      const result = await readTemplateFile(file);
      if (!result) {
        setError('テンプレートファイルの読み込みに失敗しました');
        return;
      }

      // Save templates to IndexedDB first
      await replaceAllTemplates(result.productTemplates);

      // Then update the stores
      replaceFolders(result.folders);
      replaceProductTemplates(result.productTemplates);
      replaceDescriptionTemplates(result.descriptionTemplates);
      
      // Reset active template selection
      setActiveTemplate(null);

      setSuccess(`${result.productTemplates.length}個のテンプレートをインポートしました`);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      setError('テンプレートのインポートに失敗しました');
    } finally {
      setIsImporting(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <button
          onClick={() => fileInputRef.current?.click()}
          disabled={isImporting}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          <Upload className="w-4 h-4" />
          <span>{isImporting ? 'インポート中...' : 'インポート'}</span>
          <input
            ref={fileInputRef}
            type="file"
            accept=".zip"
            className="hidden"
            onChange={handleImport}
          />
        </button>

        <button
          onClick={handleExport}
          disabled={productTemplates.length === 0 && descriptionTemplates.length === 0}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          <Download className="w-4 h-4" />
          <span>エクスポート</span>
        </button>
      </div>

      {error && (
        <div className="flex items-center gap-2 text-sm text-red-600 bg-red-50 p-3 rounded-md">
          <AlertCircle className="w-4 h-4 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      {success && (
        <div className="flex items-center gap-2 text-sm text-green-600 bg-green-50 p-3 rounded-md">
          <CheckCircle className="w-4 h-4 flex-shrink-0" />
          <p>{success}</p>
        </div>
      )}
    </div>
  );
}