import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tag, Gavel } from 'lucide-react';

interface PriceEditorProps {
  listingType: 'auction' | 'fixed';
  startPrice: number;
  buyItNowPrice?: number;
  onSave: (startPrice: number, buyItNowPrice?: number) => void;
  onCancel: () => void;
}

export function PriceEditor({
  listingType,
  startPrice,
  buyItNowPrice,
  onSave,
  onCancel
}: PriceEditorProps) {
  const [newStartPrice, setNewStartPrice] = useState(startPrice);
  const [newBuyItNowPrice, setNewBuyItNowPrice] = useState(buyItNowPrice);
  const startPriceRef = useRef<HTMLInputElement>(null);
  const buyItNowPriceRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (startPriceRef.current) {
      startPriceRef.current.focus();
      startPriceRef.current.select();
    }
  }, []);

  const handleStartPriceBlur = () => {
    if (newStartPrice !== startPrice) {
      onSave(newStartPrice, newBuyItNowPrice);
    }
  };

  const handleBuyItNowPriceBlur = () => {
    if (newBuyItNowPrice !== buyItNowPrice) {
      onSave(newStartPrice, newBuyItNowPrice);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, field: 'start' | 'buyItNow') => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (field === 'start' && buyItNowPriceRef.current) {
        buyItNowPriceRef.current.focus();
        buyItNowPriceRef.current.select();
      } else {
        onSave(newStartPrice, newBuyItNowPrice);
      }
    } else if (e.key === 'Escape') {
      onCancel();
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return (
    <motion.form
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();
        onSave(newStartPrice, newBuyItNowPrice);
      }}
    >
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {listingType === 'auction' ? '開始価格' : '販売価格'}
          </label>
          <div className="relative rounded-md shadow-sm">
            <input
              ref={startPriceRef}
              type="number"
              value={newStartPrice}
              onChange={(e) => setNewStartPrice(Number(e.target.value))}
              onBlur={handleStartPriceBlur}
              onFocus={handleFocus}
              onKeyDown={(e) => handleKeyDown(e, 'start')}
              min="0"
              className="block w-full rounded-md border-gray-300 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm">円</span>
            </div>
          </div>
        </div>

        {listingType === 'auction' && (
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              即決価格
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                ref={buyItNowPriceRef}
                type="number"
                value={newBuyItNowPrice || ''}
                onChange={(e) => setNewBuyItNowPrice(Number(e.target.value) || undefined)}
                onBlur={handleBuyItNowPriceBlur}
                onFocus={handleFocus}
                onKeyDown={(e) => handleKeyDown(e, 'buyItNow')}
                min="0"
                className="block w-full rounded-md border-gray-300 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">円</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end gap-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
        >
          キャンセル
        </button>
        <button
          type="submit"
          className="px-3 py-1.5 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
        >
          保存
        </button>
      </div>
    </motion.form>
  );
}