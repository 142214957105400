import React, { useState } from 'react';
import { X, CreditCard, Sparkles } from 'lucide-react';
import { Member } from '../../../types/member';
import { generateMockPaymentHistory } from '../../../utils/admin/paymentHistory';

interface PaymentHistoryModalProps {
  member: Member;
  onClose: () => void;
  startDate?: string;
  endDate?: string;
}

export function PaymentHistoryModal({ 
  member, 
  onClose,
  startDate,
  endDate
}: PaymentHistoryModalProps) {
  const [filter, setFilter] = useState<'all' | 'subscription' | 'ai_credits'>('all');
  const paymentHistory = generateMockPaymentHistory(member, startDate, endDate);

  const filteredHistory = paymentHistory.filter(payment => 
    filter === 'all' ? true : payment.type === filter
  );

  const totals = paymentHistory
    .filter(record => record.status === 'success')
    .reduce((acc, record) => {
      if (record.type === 'subscription') {
        acc.subscription += record.amount;
      } else {
        acc.aiCredits += record.amount;
      }
      return acc;
    }, { subscription: 0, aiCredits: 0 });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] flex flex-col">
        {/* Fixed Header */}
        <div className="flex items-center justify-between p-6 border-b flex-shrink-0">
          <div>
            <h2 className="text-lg font-medium text-gray-900">支払い履歴</h2>
            {startDate && endDate && (
              <p className="text-sm text-gray-500 mt-1">
                {new Date(startDate).toLocaleDateString('ja-JP')} ～ {new Date(endDate).toLocaleDateString('ja-JP')}
              </p>
            )}
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Scrollable Content Area */}
        <div className="flex-1 overflow-hidden">
          <div className="p-6 space-y-6 h-full overflow-y-auto">
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg p-4">
                <div className="flex items-center gap-2 text-blue-600 mb-2">
                  <CreditCard className="w-5 h-5" />
                  <span className="font-medium">月額プラン合計</span>
                </div>
                <p className="text-2xl font-bold text-gray-900">
                  ¥{totals.subscription.toLocaleString()}
                </p>
              </div>
              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-4">
                <div className="flex items-center gap-2 text-purple-600 mb-2">
                  <Sparkles className="w-5 h-5" />
                  <span className="font-medium">AIクレジット合計</span>
                </div>
                <p className="text-2xl font-bold text-gray-900">
                  ¥{totals.aiCredits.toLocaleString()}
                </p>
              </div>
              <div className="bg-gradient-to-br from-green-50 to-emerald-50 rounded-lg p-4">
                <div className="flex items-center gap-2 text-green-600 mb-2">
                  <span className="font-medium">総合計</span>
                </div>
                <p className="text-2xl font-bold text-gray-900">
                  ¥{(totals.subscription + totals.aiCredits).toLocaleString()}
                </p>
              </div>
            </div>

            {/* Filter Buttons - Sticky */}
            <div className="sticky top-0 bg-white py-2 z-10 border-b">
              <div className="flex gap-2">
                <button
                  onClick={() => setFilter('all')}
                  className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                    filter === 'all'
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  すべて
                </button>
                <button
                  onClick={() => setFilter('subscription')}
                  className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                    filter === 'subscription'
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  月額プラン
                </button>
                <button
                  onClick={() => setFilter('ai_credits')}
                  className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                    filter === 'ai_credits'
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  AIクレジット
                </button>
              </div>
            </div>

            {/* Payment History Table */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      日付
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      種別
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      プラン/内容
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      金額
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ステータス
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredHistory.map((payment, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {new Date(payment.date).toLocaleString('ja-JP')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium ${
                          payment.type === 'subscription'
                            ? 'bg-blue-50 text-blue-700'
                            : 'bg-purple-50 text-purple-700'
                        }`}>
                          {payment.type === 'subscription' ? (
                            <>
                              <CreditCard className="w-3 h-3" />
                              月額プラン
                            </>
                          ) : (
                            <>
                              <Sparkles className="w-3 h-3" />
                              AIクレジット
                            </>
                          )}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {payment.plan}
                        {payment.credits && (
                          <span className="ml-2 text-gray-500">
                            ({payment.credits.toLocaleString()}回分)
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ¥{payment.amount.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          payment.status === 'success' ? 'bg-green-100 text-green-800' :
                          payment.status === 'failed' ? 'bg-red-100 text-red-800' :
                          'bg-yellow-100 text-yellow-800'
                        }`}>
                          {payment.status === 'success' ? '成功' :
                           payment.status === 'failed' ? '失敗' : '返金'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Fixed Footer */}
        <div className="flex justify-end gap-3 px-6 py-4 bg-gray-50 rounded-b-lg border-t flex-shrink-0">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
}