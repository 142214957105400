import type { Member } from '../../types/member';

export interface CompensationResult {
  memberId: string;
  previousCredits: number;
  newCredits: number;
  compensationAmount: number;
}

export function calculateCompensation(
  member: Member,
  amount: number,
  allowExceedLimit: boolean = false
): CompensationResult {
  const previousCredits = member.aiCredits;
  let newCredits;

  if (allowExceedLimit) {
    // 管理者による補償の場合は上限を超えて付与可能
    newCredits = previousCredits + amount;
  } else {
    // 通常の付与の場合は上限を超えない
    newCredits = Math.min(previousCredits + amount, member.maxAiCredits);
  }

  return {
    memberId: member.id,
    previousCredits,
    newCredits,
    compensationAmount: newCredits - previousCredits
  };
}

export function validateCompensationAmount(amount: number): {
  isValid: boolean;
  error?: string;
} {
  if (isNaN(amount) || amount <= 0) {
    return {
      isValid: false,
      error: '有効な数値を入力してください'
    };
  }

  if (amount > 100000) { // より大きな上限に設定
    return {
      isValid: false,
      error: '一度に付与できるクレジットは100,000までです'
    };
  }

  return { isValid: true };
}