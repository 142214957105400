import React, { useState } from 'react';
import { Phone, Check, AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface TwoFactorAuthSetupProps {
  onClose: () => void;
}

export function TwoFactorAuthSetup({ onClose }: TwoFactorAuthSetupProps) {
  const [step, setStep] = useState<'phone' | 'verify'>('phone');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const formatPhoneNumber = (value: string) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length <= 3) return numbers;
    if (numbers.length <= 7) return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7, 11)}`;
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    if (formatted.replace(/-/g, '').length <= 11) {
      setPhoneNumber(formatted);
      setError(null);
    }
  };

  const handleSendCode = async (e: React.FormEvent) => {
    e.preventDefault();
    const numbers = phoneNumber.replace(/-/g, '');
    if (numbers.length !== 11) {
      setError('有効な電話番号を入力してください');
      return;
    }

    setIsSendingCode(true);
    setError(null);

    try {
      // 実際の実装では、APIを呼び出して認証コードを送信
      await new Promise(resolve => setTimeout(resolve, 1500));
      setStep('verify');
    } catch (error) {
      setError('認証コードの送信に失敗しました');
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleVerifyCode = async (e: React.FormEvent) => {
    e.preventDefault();
    if (verificationCode.length !== 6) {
      setError('6桁の認証コードを入力してください');
      return;
    }

    setIsVerifying(true);
    setError(null);

    try {
      // 実際の実装では、APIを呼び出して検証
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      if (verificationCode === '123456') { // デモ用の検証
        onClose();
      } else {
        setError('認証コードが正しくありません');
      }
    } catch (error) {
      setError('認証に失敗しました。もう一度お試しください。');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResendCode = async () => {
    setIsSendingCode(true);
    setError(null);

    try {
      // 実際の実装では、APIを呼び出して認証コードを再送信
      await new Promise(resolve => setTimeout(resolve, 1500));
      setError('認証コードを再送信しました');
    } catch (error) {
      setError('認証コードの再送信に失敗しました');
    } finally {
      setIsSendingCode(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-md w-full mx-4">
        <div className="p-6 border-b">
          <h3 className="text-lg font-medium text-gray-900">
            二段階認証の設定
          </h3>
        </div>

        <div className="p-6">
          <AnimatePresence mode="wait">
            {step === 'phone' ? (
              <motion.form
                key="phone"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                onSubmit={handleSendCode}
                className="space-y-6"
              >
                <div className="text-sm text-gray-600">
                  <p>
                    ログイン時の本人確認のため、電話番号を登録してください。
                    認証コードをSMSで送信します。
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    電話番号
                  </label>
                  <div className="relative">
                    <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      className="pl-10 pr-3 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      placeholder="090-1234-5678"
                      required
                    />
                  </div>
                </div>

                {error && (
                  <div className="flex items-center gap-2 text-sm text-red-600 bg-red-50 p-3 rounded-md">
                    <AlertCircle className="w-4 h-4 flex-shrink-0" />
                    <p>{error}</p>
                  </div>
                )}

                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={onClose}
                    disabled={isSendingCode}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                  >
                    キャンセル
                  </button>
                  <button
                    type="submit"
                    disabled={phoneNumber.replace(/-/g, '').length !== 11 || isSendingCode}
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {isSendingCode ? '送信中...' : '認証コードを送信'}
                  </button>
                </div>
              </motion.form>
            ) : (
              <motion.form
                key="verify"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                onSubmit={handleVerifyCode}
                className="space-y-6"
              >
                <div className="text-sm text-gray-600">
                  <p>
                    {phoneNumber}に送信された6桁の認証コードを入力してください。
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    認証コード
                  </label>
                  <input
                    type="text"
                    value={verificationCode}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '').slice(0, 6);
                      setVerificationCode(value);
                      setError(null);
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="123456"
                    maxLength={6}
                    required
                  />
                  <button
                    type="button"
                    onClick={handleResendCode}
                    disabled={isSendingCode}
                    className="mt-2 text-sm text-blue-600 hover:text-blue-500"
                  >
                    {isSendingCode ? '送信中...' : '認証コードを再送信'}
                  </button>
                </div>

                {error && (
                  <div className="flex items-center gap-2 text-sm text-red-600 bg-red-50 p-3 rounded-md">
                    <AlertCircle className="w-4 h-4 flex-shrink-0" />
                    <p>{error}</p>
                  </div>
                )}

                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setStep('phone')}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                  >
                    戻る
                  </button>
                  <button
                    type="submit"
                    disabled={verificationCode.length !== 6 || isVerifying}
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    {isVerifying ? '検証中...' : '設定を完了'}
                  </button>
                </div>
              </motion.form>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}