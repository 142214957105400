import React from 'react';

interface CostBreakdownProps {
  hours: number;
  laborCost: number;
  systemCost: number;
  totalCost: number;
}

export function CostBreakdown({ hours, laborCost, systemCost, totalCost }: CostBreakdownProps) {
  return (
    <div className="mt-4 space-y-2">
      <div className="flex justify-between">
        <span className="text-gray-600">作業時間</span>
        <span className="font-medium">{hours.toFixed(1)}時間</span>
      </div>
      <div className="flex justify-between">
        <span className="text-gray-600">人件費 (¥1,200/時)</span>
        <span className="font-medium">¥{laborCost.toLocaleString()}</span>
      </div>
      <div className="flex justify-between">
        <span className="text-gray-600">システム利用料</span>
        <span className="font-medium">¥{systemCost.toLocaleString()}</span>
      </div>
      <div className="pt-2 border-t">
        <div className="flex justify-between">
          <span className="font-medium text-gray-900">合計コスト</span>
          <span className="font-bold text-gray-900">¥{totalCost.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
}