import React from 'react';
import { PricingHeader } from './PricingHeader';
import { PricingPlans } from './PricingPlans';
import { AdditionalChargesSection } from './sections/AdditionalChargesSection';

export function PricingPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <PricingHeader />
        <PricingPlans />
        <AdditionalChargesSection />
      </div>
    </div>
  );
}