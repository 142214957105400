import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Product } from '../types';
import { createTemplateFromProduct } from '../utils/templates/productTemplates';
import type { ProductTemplate } from '../utils/templates/productTemplates';
import { saveTemplate, getTemplates, deleteTemplate } from '../utils/templates/templateStorage';

interface Folder {
  id: string;
  name: string;
}

interface TemplateState {
  templates: ProductTemplate[];
  activeTemplateId: string | null;
  isLoading: boolean;
  folders: Folder[];
  loadTemplates: () => Promise<void>;
  addTemplate: (name: string, product: Product, folderId: string) => Promise<void>;
  updateTemplate: (id: string, updates: Partial<ProductTemplate>) => Promise<void>;
  deleteTemplate: (id: string) => Promise<void>;
  setActiveTemplate: (id: string | null) => void;
  getTemplateById: (id: string) => ProductTemplate | undefined;
  replaceTemplates: (templates: ProductTemplate[]) => void;
  replaceFolders: (folders: Folder[]) => void;
  addFolder: (id: string, name: string) => void;
  updateFolder: (id: string, name: string) => void;
  deleteFolder: (id: string) => void;
}

export const useTemplateStore = create<TemplateState>()(
  persist(
    (set, get) => ({
      templates: [],
      activeTemplateId: null,
      isLoading: false,
      folders: [{ id: 'default', name: 'お気に入り' }],

      loadTemplates: async () => {
        set({ isLoading: true });
        try {
          const templates = await getTemplates();
          const activeTemplates = templates.filter(t => !t.isDeleted);
          set({ templates: activeTemplates, isLoading: false });
        } catch (error) {
          console.error('Failed to load templates:', error);
          set({ isLoading: false });
        }
      },

      addTemplate: async (name, product, folderId) => {
        const template = createTemplateFromProduct(product, name, folderId);
        try {
          await saveTemplate(template);
          set(state => ({
            templates: [template, ...state.templates],
            activeTemplateId: template.id
          }));
        } catch (error) {
          console.error('Failed to add template:', error);
          throw error;
        }
      },

      updateTemplate: async (id, updates) => {
        const template = get().templates.find(t => t.id === id);
        if (!template) return;

        const updatedTemplate = {
          ...template,
          ...updates,
          updatedAt: new Date().toISOString()
        };

        try {
          await saveTemplate(updatedTemplate);
          set(state => ({
            templates: state.templates.map(t =>
              t.id === id ? updatedTemplate : t
            )
          }));
        } catch (error) {
          console.error('Failed to update template:', error);
          throw error;
        }
      },

      deleteTemplate: async (id) => {
        try {
          await deleteTemplate(id);
          set(state => ({
            templates: state.templates.filter(t => t.id !== id),
            activeTemplateId: state.activeTemplateId === id ? null : state.activeTemplateId
          }));
        } catch (error) {
          console.error('Failed to delete template:', error);
          throw error;
        }
      },

      setActiveTemplate: (id) => {
        set({ activeTemplateId: id });
      },

      getTemplateById: (id) => {
        return get().templates.find(t => t.id === id);
      },

      replaceTemplates: (templates) => {
        set({ 
          templates: templates.filter(t => !t.isDeleted),
          activeTemplateId: null
        });
      },

      replaceFolders: (folders) => {
        // Ensure default folder exists
        if (!folders.some(f => f.id === 'default')) {
          folders.unshift({ id: 'default', name: 'お気に入り' });
        }
        set({ folders });
      },

      addFolder: (id, name) => {
        set(state => ({
          folders: [...state.folders, { id, name }]
        }));
      },

      updateFolder: (id, name) => {
        set(state => ({
          folders: state.folders.map(folder =>
            folder.id === id ? { ...folder, name } : folder
          )
        }));
      },

      deleteFolder: (id) => {
        if (id === 'default') return; // Prevent deletion of default folder
        
        set(state => ({
          folders: state.folders.filter(folder => folder.id !== id),
          templates: state.templates.map(template =>
            template.folderId === id
              ? { ...template, folderId: 'default' }
              : template
          )
        }));
      }
    }),
    {
      name: 'template-storage',
      partialize: (state) => ({
        templates: state.templates,
        folders: state.folders,
        activeTemplateId: state.activeTemplateId
      })
    }
  )
);