import React from 'react';

export function NotificationSettings() {
  return (
    <div className="space-y-4">
      <label className="flex items-center">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          defaultChecked
        />
        <span className="ml-2 text-sm text-gray-700">
          AIクレジット残高が少なくなった時に通知する
        </span>
      </label>

      <label className="flex items-center">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          defaultChecked
        />
        <span className="ml-2 text-sm text-gray-700">
          新機能やアップデートの通知を受け取る
        </span>
      </label>

      <label className="flex items-center">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          defaultChecked
        />
        <span className="ml-2 text-sm text-gray-700">
          メンテナンス情報を受け取る
        </span>
      </label>
    </div>
  );
}