import React from 'react';
import { Calendar } from 'lucide-react';

interface DateRangeSelectorProps {
  startDate: string;
  endDate: string;
  onChange: (startDate: string, endDate: string) => void;
}

export function DateRangeSelector({ startDate, endDate, onChange }: DateRangeSelectorProps) {
  // Get first day of current month as default start date
  const getDefaultStartDate = () => {
    const date = new Date();
    date.setDate(1);
    return date.toISOString().split('T')[0];
  };

  // Get current date as default end date
  const getDefaultEndDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(startDate, e.target.value);
  };

  return (
    <div className="flex items-center gap-2">
      <Calendar className="w-4 h-4 text-gray-500" />
      <div className="flex items-center gap-2 text-sm">
        <input
          type="date"
          value={startDate || getDefaultStartDate()}
          onChange={handleStartDateChange}
          className="h-8 px-2 rounded-md border-gray-300 bg-gray-50 text-sm"
        />
        <span className="text-gray-500">～</span>
        <input
          type="date"
          value={endDate || getDefaultEndDate()}
          onChange={handleEndDateChange}
          className="h-8 px-2 rounded-md border-gray-300 bg-gray-50 text-sm"
        />
      </div>
    </div>
  );
}