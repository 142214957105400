import { useMemo } from 'react';
import type { PricingPlan } from '../types/pricing';

export function usePricingPlans() {
  const plans = useMemo<PricingPlan[]>(() => [
    {
      id: 'basic',
      name: 'ベーシック',
      price: 2980,
      aiLimit: 200,
    },
    {
      id: 'standard',
      name: 'スタンダード',
      price: 4980,
      aiLimit: 800,
    },
    {
      id: 'professional',
      name: 'プロフェッショナル',
      price: 9800,
      aiLimit: 2000,
    },
    {
      id: 'enterprise',
      name: 'エンタープライズ',
      price: 14800,
      aiLimit: 4000,
    }
  ], []);

  return { plans };
}