import React from 'react';
import { GroupSizeAdjuster } from './GroupSizeAdjuster';
import { DraggableImageGrid } from './DraggableImageGrid';
import { GroupSettingsCollapsible } from './GroupSettingsCollapsible';
import type { ImageGroup } from '../../utils/imageImportUtils';

interface ImageGroupPreviewProps {
  group: ImageGroup;
  groupSize: number;
  onGroupSizeChange: (size: number) => void;
  index: number;
  totalImages: number;
  onDeleteImage?: (groupIndex: number, imageIndex: number) => void;
  onReorderImages?: (groupIndex: number, fromIndex: number, toIndex: number) => void;
  onSettingsChange: (groupIndex: number, updates: { 
    category?: string; 
    startPrice?: number; 
    condition?: string;
    memo?: string;
    shippingMethods?: string[];
    customShippingMethods?: any[];
  }) => void;
  category: string;
  startPrice: number;
  condition: string;
  memo: string;
  shippingMethods?: string[];
  customShippingMethods?: any[];
}

export function ImageGroupPreview({ 
  group, 
  groupSize, 
  onGroupSizeChange,
  index,
  totalImages,
  onDeleteImage,
  onReorderImages,
  onSettingsChange,
  category,
  startPrice,
  condition,
  memo,
  shippingMethods,
  customShippingMethods
}: ImageGroupPreviewProps) {
  const handleDeleteImage = (imageIndex: number) => {
    if (onDeleteImage) {
      onDeleteImage(index, imageIndex);
    }
  };

  const handleReorderImages = (fromIndex: number, toIndex: number) => {
    if (onReorderImages) {
      onReorderImages(index, fromIndex, toIndex);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-base font-medium text-gray-900">
          商品 {index + 1}
        </h3>
        <GroupSizeAdjuster
          currentSize={groupSize}
          onChange={onGroupSizeChange}
          maxSize={totalImages}
          totalImages={totalImages}
        />
      </div>

      <DraggableImageGrid
        images={group.thumbnails}
        groupSize={groupSize}
        groupIndex={index}
        onDeleteImage={handleDeleteImage}
        onReorderImages={handleReorderImages}
      />

      <GroupSettingsCollapsible
        groupIndex={index}
        category={category}
        startPrice={startPrice}
        condition={condition}
        memo={memo}
        shippingMethods={shippingMethods}
        customShippingMethods={customShippingMethods}
        onChange={onSettingsChange}
      />
    </div>
  );
}