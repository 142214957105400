import React, { useState } from 'react';
import { Zap, Info } from 'lucide-react';
import { PaymentModal } from './PaymentModal';

interface CreditPack {
  credits: number;
  amount: number;
}

const CREDIT_PACKS: CreditPack[] = [
  { credits: 500, amount: 2980 },
  { credits: 1000, amount: 4980 }
];

export function AICreditsCharge() {
  const [selectedPack, setSelectedPack] = useState<CreditPack | null>(null);

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-8">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-blue-100 rounded-lg">
            <Zap className="w-6 h-6 text-blue-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900">追加AIクレジット</h2>
        </div>
        <div className="flex items-center gap-2 text-sm text-blue-600 bg-blue-50 px-3 py-1.5 rounded-lg">
          <Info className="w-4 h-4" />
          <span>プランのAIクレジットとは別に購入可能</span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {CREDIT_PACKS.map((pack) => (
          <div key={pack.credits} className="bg-white bg-opacity-50 rounded-lg p-6">
            <div>
              <p className="text-lg text-gray-600 mb-2">
                {pack.credits.toLocaleString()}回分のAIタイトル生成クレジット
              </p>
              <div className="flex items-baseline gap-2 mb-4">
                <span className="text-3xl font-bold text-gray-900">
                  {pack.amount.toLocaleString()}
                </span>
                <span className="text-gray-600">円</span>
              </div>
              <button
                onClick={() => setSelectedPack(pack)}
                className="w-full px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-md font-medium transition-colors"
              >
                クレジットを購入
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6 bg-white bg-opacity-50 rounded-lg p-4">
        <div className="flex items-start gap-3">
          <Info className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
          <div className="text-sm text-gray-600">
            <p className="font-medium text-gray-900 mb-1">AIクレジットについて</p>
            <ul className="space-y-1">
              <li>• プランに含まれるAIクレジットとは別に追加購入できます</li>
              <li>• 追加購入したクレジットに有効期限はありません</li>
              <li>• プランのクレジットを使い切った後に追加クレジットが消費されます</li>
            </ul>
          </div>
        </div>
      </div>

      {selectedPack && (
        <PaymentModal
          credits={selectedPack.credits}
          amount={selectedPack.amount}
          onClose={() => setSelectedPack(null)}
        />
      )}
    </div>
  );
}