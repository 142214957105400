import React from 'react';
import { formatTimeUntilReset } from '../../../utils/aiCredits/dateUtils';
import { Sparkles, Infinity } from 'lucide-react';

interface AiCreditsTooltipProps {
  planCredits: number;
  maxPlanCredits: number;
  chargedCredits: number;
  maxChargedCredits: number;
}

export function AiCreditsTooltip({ 
  planCredits = 0,
  maxPlanCredits = 0,
  chargedCredits = 0,
  maxChargedCredits = 0
}: AiCreditsTooltipProps) {
  const timeUntilReset = formatTimeUntilReset();

  return (
    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-72 bg-gray-900 text-white rounded-lg shadow-xl p-4 whitespace-normal z-50">
      <div className="space-y-4">
        {/* Plan Credits */}
        <div>
          <div className="flex items-center gap-2 mb-2">
            <Sparkles className="w-4 h-4 text-violet-400" />
            <p className="font-medium">プランAIクレジット</p>
          </div>
          <div className="bg-gray-800 rounded-lg p-3 space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">残りクレジット</span>
              <span className="text-white font-medium">
                {planCredits.toLocaleString()} / {maxPlanCredits.toLocaleString()}
              </span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">リセットまで</span>
              <span className="text-white font-medium">{timeUntilReset}</span>
            </div>
          </div>
        </div>

        {/* Charged Credits */}
        <div>
          <div className="flex items-center gap-2 mb-2">
            <Infinity className="w-4 h-4 text-amber-400" />
            <p className="font-medium">追加AIクレジット</p>
          </div>
          <div className="bg-gray-800 rounded-lg p-3 space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">残りクレジット</span>
              <span className="text-white font-medium">
                {chargedCredits.toLocaleString()} / {maxChargedCredits.toLocaleString()}
              </span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">有効期限</span>
              <span className="text-white font-medium">無期限</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900" />
    </div>
  );
}