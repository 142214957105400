import React from 'react';
import { AdminHeader } from './AdminHeader';
import { MemberList } from './members/MemberList';
import { useMemberStore } from '../../stores/memberStore';

export function AdminDashboard() {
  const { members } = useMemberStore();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <AdminHeader />
        <MemberList members={members} />
      </div>
    </div>
  );
}