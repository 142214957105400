import React from 'react';
import { useNavigate } from 'react-router-dom';

export function PlanDetails() {
  const navigate = useNavigate();

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-500">現在のプラン</label>
        <p className="mt-1 text-sm text-gray-900">ベーシックプラン</p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-500">AIクレジット残高</label>
        <p className="mt-1 text-sm text-gray-900">150 / 200</p>
      </div>

      <div>
        <button
          onClick={() => navigate('/pricing')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
        >
          プランを変更
        </button>
      </div>
    </div>
  );
}