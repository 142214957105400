import React from 'react';
import { Sparkles, Zap } from 'lucide-react';

interface AiCreditsIconProps {
  isLowCredits: boolean;
  type: 'plan' | 'charged';
}

export function AiCreditsIcon({ isLowCredits, type }: AiCreditsIconProps) {
  if (type === 'charged') {
    return <Zap className="w-4 h-4 text-amber-600" />;
  }

  return (
    <Sparkles className={`w-4 h-4 ${
      isLowCredits ? 'text-red-500' : 'text-purple-600'
    }`} />
  );
}