import React, { useState } from 'react';
import { X, AlertCircle, Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { validateCompensationAmount } from '../../../../utils/aiCredits/validation';

interface AICreditsCompensationProps {
  selectedCount: number;
  onCompensate: (amount: number) => void;
  onClose: () => void;
}

export function AICreditsCompensation({
  selectedCount,
  onCompensate,
  onClose
}: AICreditsCompensationProps) {
  const [amount, setAmount] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const handleAmountChange = (value: string) => {
    setAmount(value);
    setError(null);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const credits = parseInt(amount);
    const validation = validateCompensationAmount(credits);
    
    if (!validation.isValid) {
      setError(validation.error);
      return;
    }

    if (!isConfirmed) {
      setError('補償を実行する前に確認チェックボックスにチェックを入れてください');
      return;
    }

    onCompensate(credits);
  };

  const handleClose = async () => {
    setIsCanceling(true);
    await new Promise(resolve => setTimeout(resolve, 300));
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-lg max-w-md w-full mx-4"
      >
        <div className="p-6 border-b">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900">AIクレジットの補償</h3>
            <motion.button
              onClick={handleClose}
              disabled={isCanceling}
              whileTap={{ scale: 0.95 }}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </motion.button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="bg-blue-50 border border-blue-200 rounded-md p-4">
            <div className="flex items-start gap-3">
              <Info className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-700">
                <p>選択された{selectedCount}名のユーザーに対してAIクレジットを付与します。</p>
                <p className="mt-1">一度に付与できるクレジットは1,000までです。</p>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              付与するクレジット数
            </label>
            <div className="mt-1">
              <input
                type="number"
                value={amount}
                onChange={(e) => handleAmountChange(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="例: 100"
                min="1"
                max="1000"
              />
            </div>
          </div>

          <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4">
            <label className="flex items-start gap-3">
              <input
                type="checkbox"
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
                className="mt-1 rounded border-yellow-400 text-yellow-600 focus:ring-yellow-500"
              />
              <span className="text-sm text-yellow-800">
                この変更の影響を理解し、実行することを確認します。
                この操作により、選択された{selectedCount}名のユーザーに{amount ? `${parseInt(amount).toLocaleString()}回分の` : ''}AIクレジットが付与されます。
              </span>
            </label>
          </div>

          {error && (
            <div className="flex items-center gap-2 text-sm text-red-600 bg-red-50 p-3 rounded-md">
              <AlertCircle className="w-4 h-4 flex-shrink-0" />
              <p>{error}</p>
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={handleClose}
              disabled={isCanceling}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              キャンセル
            </button>
            <button
              type="submit"
              disabled={!amount || !isConfirmed}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              補償する
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
}