import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { HelpItem } from './HelpItem';
import type { HelpContent } from './types';

interface HelpSectionProps {
  title: string;
  items: HelpContent[];
}

export function HelpSection({ title, items }: HelpSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-8">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full px-6 py-4 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
      >
        <h2 className="text-lg font-medium text-gray-900">{title}</h2>
        {isExpanded ? (
          <ChevronUp className="w-5 h-5 text-gray-500" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-500" />
        )}
      </button>

      {isExpanded && (
        <div className="mt-4 space-y-4">
          {items.map((item, index) => (
            <HelpItem 
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      )}
    </div>
  );
}