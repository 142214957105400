import React from 'react';
import { LogOut, HelpCircle, CreditCard, Home, Users, User } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigationItem } from './NavigationItem';

interface UserDropdownProps {
  onLogout: () => void;
}

export function UserDropdown({ onLogout }: UserDropdownProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const isMainPage = location.pathname === '/';

  return (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50">
      <div className="px-4 py-2 border-b border-gray-100">
        <p className="text-sm font-medium text-gray-900">アカウント</p>
      </div>

      <div className="py-1">
        {!isMainPage && (
          <NavigationItem
            icon={<Home className="w-4 h-4" />}
            label="メインページ"
            onClick={() => navigate('/')}
          />
        )}

        <NavigationItem
          icon={<User className="w-4 h-4" />}
          label="マイアカウント"
          onClick={() => navigate('/account')}
        />
        
        <NavigationItem
          icon={<CreditCard className="w-4 h-4" />}
          label="プラン一覧"
          onClick={() => navigate('/pricing')}
        />

        <NavigationItem
          icon={<Users className="w-4 h-4" />}
          label="管理画面"
          onClick={() => navigate('/admin')}
        />

        <NavigationItem
          icon={<HelpCircle className="w-4 h-4" />}
          label="ヘルプ"
          onClick={() => navigate('/help')}
        />
      </div>

      <div className="border-t border-gray-100">
        <NavigationItem
          icon={<LogOut className="w-4 h-4" />}
          label="ログアウト"
          onClick={onLogout}
          className="text-red-600 hover:bg-red-50"
        />
      </div>
    </div>
  );
}