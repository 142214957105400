import { create } from 'zustand';
import { mockMembers } from '../utils/mockData';
import type { Member } from '../types/member';

interface MemberState {
  members: Member[];
  setMembers: (members: Member[]) => void;
  updateMember: (id: string, updates: Partial<Member>) => void;
}

export const useMemberStore = create<MemberState>((set) => ({
  members: mockMembers,
  setMembers: (members) => set({ members }),
  updateMember: (id, updates) => set((state) => ({
    members: state.members.map(member =>
      member.id === id ? { ...member, ...updates } : member
    )
  }))
}));