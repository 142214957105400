import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useAICreditsEdit } from './hooks/useAICreditsEdit';
import type { Member } from '../../../../types/member';

interface AICreditsEditModalProps {
  member: Member;
  onUpdate: (newCredits: number) => void;
  onClose: () => void;
}

export function AICreditsEditModal({ member, onUpdate, onClose }: AICreditsEditModalProps) {
  const {
    credits,
    confirmed,
    error,
    handleCreditsChange,
    handleConfirmChange,
    validate,
    parsedCredits
  } = useAICreditsEdit(member.aiCredits);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      onUpdate(parsedCredits);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-md w-full mx-4">
        <div className="p-6 border-b">
          <h2 className="text-xl font-semibold text-gray-900">AIクレジットを編集</h2>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4">
            <div className="flex items-start gap-3">
              <AlertTriangle className="w-5 h-5 text-yellow-600 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-yellow-700">
                <p className="font-medium">警告: 手動でのクレジット編集</p>
                <p className="mt-1">
                  この操作は直接AIクレジットを変更します。この変更は即座に反映され、元に戻すことはできません。
                </p>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              新しいクレジット数
            </label>
            <div className="mt-1">
              <input
                type="number"
                value={credits}
                onChange={(e) => handleCreditsChange(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                min="0"
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              現在の値: {member.aiCredits.toLocaleString()} / {member.maxAiCredits.toLocaleString()}
            </p>
          </div>

          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={confirmed}
              onChange={(e) => handleConfirmChange(e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">
              この変更の影響を理解し、実行することを確認します
            </span>
          </label>

          {error && (
            <div className="text-sm text-red-600 bg-red-50 p-3 rounded-md">
              {error}
            </div>
          )}

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              キャンセル
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              変更を保存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}