import React, { useState } from 'react';
import { CATEGORIES } from '../../utils/categories';
import { ShippingMethodModal } from './ShippingMethodModal';
import { MemoInput } from './MemoInput';
import { MemoPreview } from './MemoPreview';
import { useTemplateStore } from '../../stores/templateStore';
import type { ProductTemplate } from '../../utils/templates/productTemplates';

interface GroupSettingsProps {
  groupIndex: number;
  category: string;
  startPrice: number;
  condition: string;
  memo: string;
  shippingMethods?: string[];
  customShippingMethods?: any[];
  shippingResponsibility?: string;
  shippingLater?: boolean;
  shippingCOD?: boolean;
  nekoEazySize?: string;
  nekoEazyWeight?: string;
  yuPackSize?: string;
  yuPackWeight?: string;
  onChange: (groupIndex: number, updates: { 
    category?: string; 
    startPrice?: number; 
    condition?: string;
    memo?: string;
    shippingMethods?: string[];
    customShippingMethods?: any[];
    shippingResponsibility?: string;
    shippingLater?: boolean;
    shippingCOD?: boolean;
    nekoEazySize?: string;
    nekoEazyWeight?: string;
    yuPackSize?: string;
    yuPackWeight?: string;
  }) => void;
}

export function GroupSettings({ 
  groupIndex, 
  category, 
  startPrice, 
  condition,
  memo,
  shippingMethods = [],
  customShippingMethods = [],
  shippingResponsibility = 'buyer',
  shippingLater = false,
  shippingCOD = false,
  nekoEazySize = '',
  nekoEazyWeight = '',
  yuPackSize = '',
  yuPackWeight = '',
  onChange 
}: GroupSettingsProps) {
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [title, setTitle] = useState('');
  const { templates } = useTemplateStore();

  const handleTemplateSelect = (templateId: string) => {
    if (!templateId) return;

    const template = templates.find(t => t.id === templateId);
    if (!template) return;

    onChange(groupIndex, {
      category: template.fields.category,
      startPrice: template.fields.startPrice,
      condition: template.fields.condition,
      shippingMethods: [
        ...(template.fields.nekoposu ? ['nekoposu'] : []),
        ...(template.fields.nekoCompact ? ['nekoCompact'] : []),
        ...(template.fields.nekoEazy ? ['nekoEazy'] : []),
        ...(template.fields.yuPacketMini ? ['yuPacketMini'] : []),
        ...(template.fields.yuPacket ? ['yuPacket'] : []),
        ...(template.fields.yuPacketPlus ? ['yuPacketPlus'] : []),
        ...(template.fields.yuPack ? ['yuPack'] : [])
      ],
      customShippingMethods: [...template.fields.customShippingMethods],
      shippingResponsibility: template.fields.shippingResponsibility,
      shippingLater: template.fields.shippingLater,
      shippingCOD: template.fields.shippingCOD,
      nekoEazySize: template.fields.nekoEazySize,
      nekoEazyWeight: template.fields.nekoEazyWeight,
      yuPackSize: template.fields.yuPackSize,
      yuPackWeight: template.fields.yuPackWeight
    });
  };

  const handleMemoChange = (newMemo: string) => {
    onChange(groupIndex, { memo: newMemo });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return (
    <div className="mt-4 space-y-4">
      {/* Upper row */}
      <div className="grid grid-cols-3 gap-4">
        {/* Start Price */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">開始価格</label>
          <input
            type="number"
            value={startPrice || ''}
            onChange={(e) => onChange(groupIndex, { startPrice: Number(e.target.value) })}
            min="0"
            placeholder="開始価格を入力"
            className="w-full h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
          />
        </div>

        {/* Condition */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">商品の状態</label>
          <select
            value={condition}
            onChange={(e) => onChange(groupIndex, { condition: e.target.value })}
            className="w-full h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="new">未使用</option>
            <option value="like-new">未使用に近い</option>
            <option value="good">目立った傷や汚れなし</option>
            <option value="fair">やや傷や汚れあり</option>
            <option value="poor">傷や汚れあり</option>
            <option value="bad">全体的に状態が悪い</option>
          </select>
        </div>

        {/* Category */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">カテゴリ</label>
          <select
            value={category}
            onChange={(e) => onChange(groupIndex, { category: e.target.value })}
            className="w-full h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            {CATEGORIES.map(category => (
              <option key={category.id} value={category.id}>
                {category.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Lower row */}
      <div className="grid grid-cols-3 gap-4">
        {/* Memo */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">メモ</label>
          <input
            type="text"
            value={memo}
            onChange={(e) => handleMemoChange(e.target.value)}
            placeholder="商品のメモを入力"
            className="w-full h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
          />
        </div>

        {/* Template */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">テンプレート</label>
          <select
            onChange={(e) => handleTemplateSelect(e.target.value)}
            className="w-full h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            {templates.map(template => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>

        {/* Shipping Methods */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">配送方法</label>
          <button
            onClick={() => setShowShippingModal(true)}
            className={`w-full h-[30px] text-center px-4 rounded-md border text-sm transition-colors ${
              shippingMethods.length > 0 || customShippingMethods.length > 0
                ? 'bg-blue-50 border-blue-200 text-blue-700 hover:bg-blue-100'
                : 'bg-gray-50 border-gray-300 text-gray-600 hover:bg-gray-100'
            }`}
          >
            変更する
          </button>
        </div>
      </div>

      {showShippingModal && (
        <ShippingMethodModal
          selectedMethods={shippingMethods}
          customMethods={customShippingMethods}
          shippingResponsibility={shippingResponsibility}
          shippingLater={shippingLater}
          shippingCOD={shippingCOD}
          nekoEazySize={nekoEazySize}
          nekoEazyWeight={nekoEazyWeight}
          yuPackSize={yuPackSize}
          yuPackWeight={yuPackWeight}
          onSelect={(methods, customMethods, options) => {
            onChange(groupIndex, {
              shippingMethods: methods,
              customShippingMethods: customMethods,
              ...options
            });
          }}
          onClose={() => setShowShippingModal(false)}
        />
      )}

      {memo && (
        <div className="space-y-2">
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            placeholder="タイトルを入力してプレビュー（*を含めると置換されます）"
            className="w-full rounded-md border-gray-300 bg-gray-50 text-sm"
          />
          <MemoPreview title={title} memo={memo} />
        </div>
      )}
    </div>
  );
}