import { Member } from '../../types/member';

export interface PaymentRecord {
  date: string;
  memberId: string;
  email: string;
  plan: string;
  amount: number;
  status: 'success' | 'failed' | 'refunded';
  type: 'subscription' | 'ai_credits';
  credits?: number;
}

export function generateMockPaymentHistory(member: Member, startDate?: string, endDate?: string): PaymentRecord[] {
  const records: PaymentRecord[] = [];
  const start = startDate ? new Date(startDate) : new Date(member.registeredAt);
  const end = endDate ? new Date(endDate) : new Date();
  const currentDate = new Date(start);
  
  while (currentDate <= end) {
    // Monthly subscription payment
    if (Math.random() > 0.2) { // 80% success rate
      records.push({
        date: currentDate.toISOString(),
        memberId: member.id,
        email: member.email,
        plan: member.plan,
        amount: member.plan === 'ベーシック' ? 2980 :
               member.plan === 'スタンダード' ? 4980 :
               member.plan === 'プロフェッショナル' ? 9800 : 14800,
        status: Math.random() > 0.1 ? 'success' : Math.random() > 0.5 ? 'failed' : 'refunded',
        type: 'subscription'
      });
    }

    // Random AI credit charges (30% chance per month)
    if (Math.random() < 0.3) {
      const creditPacks = [
        { credits: 1000, amount: 3000 },
        { credits: 3000, amount: 8000 },
        { credits: 5000, amount: 12000 }
      ];
      const pack = creditPacks[Math.floor(Math.random() * creditPacks.length)];
      
      records.push({
        date: new Date(currentDate.getTime() + Math.random() * 86400000 * 30).toISOString(), // Random day in the month
        memberId: member.id,
        email: member.email,
        plan: `AIクレジット ${pack.credits.toLocaleString()}回分`,
        amount: pack.amount,
        status: Math.random() > 0.05 ? 'success' : 'failed', // 95% success rate for AI credits
        type: 'ai_credits',
        credits: pack.credits
      });
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  
  // Sort by date in descending order
  return records.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
}

function escapeCSV(field: string | number): string {
  const stringField = String(field);
  if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
    return `"${stringField.replace(/"/g, '""')}"`;
  }
  return stringField;
}

export async function downloadPaymentHistory(members: Member[], startDate?: string, endDate?: string): Promise<void> {
  // Generate payment history for all selected members
  const allRecords = members.flatMap(member => generateMockPaymentHistory(member, startDate, endDate));
  
  // Sort by date
  allRecords.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  
  // Calculate totals
  const totals = allRecords
    .filter(record => record.status === 'success')
    .reduce((acc, record) => {
      if (record.type === 'subscription') {
        acc.subscription += record.amount;
      } else {
        acc.aiCredits += record.amount;
      }
      return acc;
    }, { subscription: 0, aiCredits: 0 });
  
  // Create CSV content
  const headers = ['日付', '会員ID', 'メールアドレス', '種別', 'プラン/内容', '金額', 'ステータス'];
  const rows = allRecords.map(record => [
    escapeCSV(new Date(record.date).toLocaleString('ja-JP')),
    escapeCSV(record.memberId),
    escapeCSV(record.email),
    escapeCSV(record.type === 'subscription' ? '月額プラン' : 'AIクレジット'),
    escapeCSV(record.plan),
    escapeCSV(`¥${record.amount.toLocaleString()}`),
    escapeCSV(record.status === 'success' ? '成功' :
              record.status === 'failed' ? '失敗' : '返金')
  ]);
  
  // Add summary rows
  rows.push([]);
  rows.push([
    escapeCSV('集計期間'),
    escapeCSV(startDate ? new Date(startDate).toLocaleDateString('ja-JP') : '登録日'),
    escapeCSV('～'),
    escapeCSV(endDate ? new Date(endDate).toLocaleDateString('ja-JP') : '現在'),
    '',
    '',
    ''
  ]);
  rows.push([
    escapeCSV('月額プラン合計'),
    '',
    '',
    '',
    '',
    escapeCSV(`¥${totals.subscription.toLocaleString()}`),
    ''
  ]);
  rows.push([
    escapeCSV('AIクレジット合計'),
    '',
    '',
    '',
    '',
    escapeCSV(`¥${totals.aiCredits.toLocaleString()}`),
    ''
  ]);
  rows.push([
    escapeCSV('総合計'),
    '',
    '',
    '',
    '',
    escapeCSV(`¥${(totals.subscription + totals.aiCredits).toLocaleString()}`),
    ''
  ]);
  
  const csvContent = [
    headers.map(escapeCSV).join(','),
    ...rows.map(row => row.join(','))
  ].join('\n');
  
  // Create and download file
  const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvContent], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `payment_history_${new Date().toISOString().split('T')[0]}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}