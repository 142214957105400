import React from 'react';

interface AccountSectionProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export function AccountSection({ icon, title, children }: AccountSectionProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-gray-50 rounded-lg">
          {icon}
        </div>
        <h2 className="text-xl font-medium text-gray-900">{title}</h2>
      </div>
      {children}
    </div>
  );
}