export interface ValidationResult {
  isValid: boolean;
  error?: string;
}

export function validateCompensationAmount(amount: number): ValidationResult {
  if (isNaN(amount) || amount <= 0) {
    return {
      isValid: false,
      error: '有効な数値を入力してください'
    };
  }

  if (amount > 1000) {
    return {
      isValid: false,
      error: '一度に付与できるクレジットは1,000までです'
    };
  }

  return { isValid: true };
}

export function validateCreditsEdit(credits: number): ValidationResult {
  if (isNaN(credits) || credits < 0) {
    return {
      isValid: false,
      error: '有効な数値を入力してください'
    };
  }

  return { isValid: true };
}