import React, { useState } from 'react';
import { Settings } from 'lucide-react';
import type { BulkUpdates } from '../../../types/bulk';

interface BulkConditionFieldProps {
  onUpdate: (updates: BulkUpdates) => void;
}

export function BulkConditionField({ onUpdate }: BulkConditionFieldProps) {
  const [condition, setCondition] = useState('');

  const handleApply = () => {
    if (condition) {
      onUpdate({ condition });
      setCondition('');
    }
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Settings className="w-5 h-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">商品の状態を変更</span>
      </div>
      <div className="flex items-center gap-4">
        <select
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          className="w-48 h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
        >
          <option value="">選択してください</option>
          <option value="new">未使用</option>
          <option value="like-new">未使用に近い</option>
          <option value="good">目立った傷や汚れなし</option>
          <option value="fair">やや傷や汚れあり</option>
          <option value="poor">傷や汚れあり</option>
          <option value="bad">全体的に状態が悪い</option>
        </select>
        <button
          onClick={handleApply}
          disabled={!condition}
          className={`h-[30px] px-3 rounded-md text-sm font-medium transition-colors ${
            condition
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          }`}
        >
          変更
        </button>
      </div>
    </div>
  );
}