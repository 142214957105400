import type { HelpCategories } from './types';

export const helpContent: HelpCategories = {
  "認証・ユーザー管理関連": [
    {
      question: "アカウントのパスワードを忘れた場合、どうすればよいですか？",
      answer: "ログイン画面にある「パスワードを忘れた方」リンクをクリックし、登録済みのメールアドレスを入力してください。リセットのご案内が送られます。"
    },
    {
      question: "複数のユーザー アカウントを作成できますか?",
      answer: "はい、必要に応じて複数のアカウントを作成できます。"
    },
    {
      question: "ユーザーアカウントの削除はどのように行いますか?",
      answer: "管理画面の「設定」から「退会」を選択し、退会操作を行います。"
    }
  ],
  "画像アップロード・処理": [
    {
      question: "画像を一括でアップロードする方法はありますか？",
      answer: "はい、ドラッグ&ドロップ機能、一括インポート機能を使って複数の画像を一度にアップロードできます。"
    },
    {
      question: "アップロードした画像のサイズを圧縮できますか?",
      answer: "自動圧縮機能が搭載されており、アップロード時に最適化されます。"
    },
    {
      question: "画像がアップロードできない場合はどうですか？",
      answer: "ファイル形式（JPEG、PNG推奨）やサイズを確認し、対応している形式でもう一度試してください。"
    }
  ],
  "商品管理機能関連": [
    {
      question: "商品情報をCSVで一括登録できますか?",
      answer: "はい、専用のCSVフォーマットを使用し、インポート機能で登録可能です。"
    },
    {
      question: "商品タイトルを自動生成できますか？",
      answer: "AIタイトル生成機能を利用すると、商品情報から最適なタイトルを提案します。"
    },
    {
      question: "商品情報を編集するにはどうすればよいですか？",
      answer: "商品リストから対象商品を選択し、詳細ページで編集が可能です。"
    }
  ],
  "テンプレート管理関連": [
    {
      question: "商品説明のテンプレートを作成できますか？",
      answer: "はい、「テンプレート管理」機能を使って、カスタムテンプレートを作成・保存できます。"
    },
    {
      question: "テンプレートを編集する方法を教えてください。",
      answer: "商品入力フォームで好きな設定を行った後、「テンプレートとして保存」ボタンを押してください。"
    }
  ],
  "配送オプション関連": [
    {
      question: "配送方法はカスタマイズできますか?",
      answer: "はい、「配送設定」画面でカスタム配送方法を設定できます。"
    },
    {
      question: "サイズごとの配送料金を設定しますか?",
      answer: "配送オプションの「サイズ別設定」で料金を入力してください。"
    },
    {
      question: "日本郵便やヤマト便に対応していますか?",
      answer: "対応しています。選択リストから配送サービスを選択します。"
    }
  ],
  "AI機能関連": [
    {
      question: "AIクレジットって何ですか？",
      answer: "AI機能（画像解析、タイトル生成）を使用する際の利用回数です。クレジット残高は「マイページ」で確認できます。"
    },
    {
      question: "AIを使って商品画像を解析する方法は？",
      answer: "画像アップロード後、「AI解析」ボタンをクリックすると1枚目～3枚目の画像を解析した結果が表示されます。"
    },
    {
      question: "AIを使ったエラーが発生した場合の対処法は?",
      answer: "AI機能の通信エラーの場合は、しばらく待ってから再試行してください。"
    }
  ],
  "一般的な設定": [
    {
      question: "アプリの言語を変更できますか?",
      answer: "現在は日本語のみです。"
    },
    {
      question: "商品価格やその他の一括編集は可能ですか?",
      answer: "はい、「一括編集」機能を使い、価格やカテゴリなどをまとめて変更できます。"
    },
    {
      question: "サポートにるにはどうすればいいですか？",
      answer: "アプリ内の「ヘルプ」または「サポート」セクションからお問い合わせフォームにアクセスしてお問い合わせください。"
    }
  ]
};