import React, { useState, useEffect } from 'react';
import { FileText, Folder, ChevronDown, ChevronUp, Trash2, Check, Edit2, Save, X, Plus, Settings } from 'lucide-react';
import { useTemplateStore } from '../../stores/templateStore';
import { motion, AnimatePresence } from 'framer-motion';
import { TemplateContentEditor } from './TemplateContentEditor';

export function TemplateList() {
  const { 
    templates, 
    activeTemplateId, 
    deleteTemplate, 
    setActiveTemplate,
    folders,
    addFolder,
    updateFolder,
    deleteFolder,
    updateTemplate
  } = useTemplateStore();

  // Initialize with all folders closed
  const [openFolders, setOpenFolders] = useState<Set<string>>(new Set());
  const [editingFolderId, setEditingFolderId] = useState<string | null>(null);
  const [editingName, setEditingName] = useState('');
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [editingTemplateId, setEditingTemplateId] = useState<string | null>(null);
  const [editingTemplateName, setEditingTemplateName] = useState('');
  const [editingTemplateContent, setEditingTemplateContent] = useState<string | null>(null);
  const [draggedTemplateId, setDraggedTemplateId] = useState<string | null>(null);
  const [dragOverFolderId, setDragOverFolderId] = useState<string | null>(null);

  const toggleFolder = (folderId: string) => {
    setOpenFolders(prev => {
      const newSet = new Set(prev);
      if (prev.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  };

  const handleDragStart = (e: React.DragEvent, templateId: string) => {
    setDraggedTemplateId(templateId);
    e.dataTransfer.effectAllowed = 'move';
    
    // Create a custom drag image
    const dragImage = document.createElement('div');
    dragImage.className = 'bg-white p-2 rounded shadow-lg border border-blue-500';
    const template = templates.find(t => t.id === templateId);
    dragImage.textContent = template?.name || 'テンプレート';
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 0, 0);
    
    requestAnimationFrame(() => {
      document.body.removeChild(dragImage);
    });
  };

  const handleDragOver = (e: React.DragEvent, folderId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverFolderId(folderId);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverFolderId(null);
  };

  const handleDrop = (e: React.DragEvent, folderId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverFolderId(null);

    if (!draggedTemplateId) return;

    const template = templates.find(t => t.id === draggedTemplateId);
    if (template && template.folderId !== folderId) {
      updateTemplate(draggedTemplateId, {
        ...template,
        folderId,
        updatedAt: new Date().toISOString()
      });
    }

    setDraggedTemplateId(null);
  };

  const startEditing = (folderId: string, name: string) => {
    setEditingFolderId(folderId);
    setEditingName(name);
  };

  const startEditingTemplate = (templateId: string, name: string) => {
    setEditingTemplateId(templateId);
    setEditingTemplateName(name);
  };

  const startEditingTemplateContent = (templateId: string) => {
    setEditingTemplateContent(templateId);
  };

  const saveEditing = () => {
    if (!editingName.trim() || !editingFolderId) return;
    
    updateFolder(editingFolderId, editingName.trim());
    setEditingFolderId(null);
    setEditingName('');
  };

  const saveTemplateEditing = () => {
    if (!editingTemplateName.trim() || !editingTemplateId) return;
    
    const template = templates.find(t => t.id === editingTemplateId);
    if (template) {
      updateTemplate(editingTemplateId, {
        ...template,
        name: editingTemplateName.trim()
      });
    }
    setEditingTemplateId(null);
    setEditingTemplateName('');
  };

  const handleCreateFolder = () => {
    if (!newFolderName.trim()) return;

    const folderId = crypto.randomUUID();
    addFolder(folderId, newFolderName.trim());
    setShowNewFolderInput(false);
    setNewFolderName('');
  };

  return (
    <div className="space-y-4">
      {/* New Folder Button/Input */}
      {showNewFolderInput ? (
        <div className="p-3 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-2">
            <Folder className="w-4 h-4 text-gray-400" />
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="フォルダ名を入力"
              className="flex-1 px-2 py-1 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleCreateFolder();
                if (e.key === 'Escape') {
                  setShowNewFolderInput(false);
                  setNewFolderName('');
                }
              }}
            />
            <button
              onClick={handleCreateFolder}
              disabled={!newFolderName.trim()}
              className="p-1 text-green-600 hover:text-green-700 hover:bg-green-50 rounded-full disabled:text-gray-400 disabled:hover:bg-transparent"
            >
              <Save className="w-4 h-4" />
            </button>
            <button
              onClick={() => {
                setShowNewFolderInput(false);
                setNewFolderName('');
              }}
              className="p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setShowNewFolderInput(true)}
          className="flex items-center gap-2 px-3 py-2 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md w-full"
        >
          <Plus className="w-4 h-4" />
          <span>新規フォルダを作成</span>
        </button>
      )}

      {/* Folders */}
      <div className="space-y-2">
        {folders.map(folder => (
          <div key={folder.id} className="space-y-2">
            <div
              className={`flex items-center justify-between p-3 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-md transition-colors ${
                dragOverFolderId === folder.id ? 'bg-blue-50 border-2 border-blue-300' : ''
              }`}
              onDragOver={(e) => handleDragOver(e, folder.id)}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, folder.id)}
            >
              {editingFolderId === folder.id ? (
                <div className="flex items-center gap-2 flex-1">
                  <Folder className="w-4 h-4" />
                  <input
                    type="text"
                    value={editingName}
                    onChange={(e) => setEditingName(e.target.value)}
                    className="flex-1 px-2 py-1 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') saveEditing();
                      if (e.key === 'Escape') {
                        setEditingFolderId(null);
                        setEditingName('');
                      }
                    }}
                  />
                  <button
                    onClick={saveEditing}
                    className="p-1 text-green-600 hover:text-green-700 hover:bg-green-50 rounded-full"
                  >
                    <Save className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => {
                      setEditingFolderId(null);
                      setEditingName('');
                    }}
                    className="p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ) : (
                <>
                  <button
                    onClick={() => toggleFolder(folder.id)}
                    className="flex items-center gap-2 flex-1"
                  >
                    <Folder className="w-4 h-4" />
                    <span>{folder.name}</span>
                    <span className="text-xs text-gray-500">
                      ({templates.filter(t => t.folderId === folder.id).length})
                    </span>
                  </button>
                  <div className="flex items-center gap-2">
                    {folder.id !== 'default' && (
                      <button
                        onClick={() => startEditing(folder.id, folder.name)}
                        className="p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
                      >
                        <Edit2 className="w-4 h-4" />
                      </button>
                    )}
                    {folder.id !== 'default' && (
                      <button
                        onClick={() => deleteFolder(folder.id)}
                        className="p-1 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-full"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    )}
                    {openFolders.has(folder.id) ? (
                      <ChevronUp className="w-4 h-4" />
                    ) : (
                      <ChevronDown className="w-4 h-4" />
                    )}
                  </div>
                </>
              )}
            </div>

            <AnimatePresence>
              {openFolders.has(folder.id) && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="pl-4 space-y-2"
                >
                  {templates
                    .filter(template => template.folderId === folder.id)
                    .map((template) => (
                      <div
                        key={template.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, template.id)}
                        onDragEnd={() => setDraggedTemplateId(null)}
                        className={`flex items-center justify-between p-3 rounded-lg border cursor-move ${
                          activeTemplateId === template.id
                            ? 'border-blue-500 bg-blue-50'
                            : draggedTemplateId === template.id
                            ? 'border-gray-300 opacity-50'
                            : 'border-gray-200 hover:bg-gray-50'
                        }`}
                      >
                        <button
                          onClick={() => setActiveTemplate(activeTemplateId === template.id ? null : template.id)}
                          className="flex items-center gap-3 flex-1 text-left"
                        >
                          <FileText className="w-5 h-5 text-gray-400" />
                          <div className="flex-1">
                            {editingTemplateId === template.id ? (
                              <input
                                type="text"
                                value={editingTemplateName}
                                onChange={(e) => setEditingTemplateName(e.target.value)}
                                className="w-full px-2 py-1 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') saveTemplateEditing();
                                  if (e.key === 'Escape') {
                                    setEditingTemplateId(null);
                                    setEditingTemplateName('');
                                  }
                                }}
                                onClick={(e) => e.stopPropagation()}
                              />
                            ) : (
                              <h3 className="text-sm font-medium text-gray-900">{template.name}</h3>
                            )}
                            <p className="text-xs text-gray-500">
                              最終更新: {new Date(template.updatedAt).toLocaleDateString()}
                            </p>
                          </div>
                          {activeTemplateId === template.id && (
                            <Check className="w-5 h-5 text-blue-500" />
                          )}
                        </button>
                        <div className="flex items-center gap-2 ml-4">
                          {editingTemplateId === template.id ? (
                            <>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  saveTemplateEditing();
                                }}
                                className="p-1 text-green-600 hover:text-green-700 hover:bg-green-50 rounded-full"
                              >
                                <Save className="w-4 h-4" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingTemplateId(null);
                                  setEditingTemplateName('');
                                }}
                                className="p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  startEditingTemplateContent(template.id);
                                }}
                                className="p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
                                title="テンプレートの内容を編集"
                              >
                                <Settings className="w-4 h-4" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  startEditingTemplate(template.id, template.name);
                                }}
                                className="p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
                              >
                                <Edit2 className="w-4 h-4" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteTemplate(template.id);
                                }}
                                className="p-1 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-full"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  {templates.filter(template => template.folderId === folder.id).length === 0 && (
                    <div className="text-sm text-gray-500 text-center py-2">
                      テンプレートがありません
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>

      {/* Template Content Editor Modal */}
      {editingTemplateContent && (
        <TemplateContentEditor
          template={templates.find(t => t.id === editingTemplateContent)!}
          onSave={(updatedTemplate) => {
            updateTemplate(editingTemplateContent, updatedTemplate);
            setEditingTemplateContent(null);
          }}
          onClose={() => setEditingTemplateContent(null)}
        />
      )}
    </div>
  );
}