import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, X } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';

interface DeleteAccountModalProps {
  onClose: () => void;
}

export function DeleteAccountModal({ onClose }: DeleteAccountModalProps) {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const user = useAuthStore(state => state.user);
  const logout = useAuthStore(state => state.logout);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) return;
    if (password !== user.password) { // 実際の実装ではパスワードの検証ロジックを使用
      setError('パスワードが正しくありません');
      return;
    }
    if (!isConfirmed) {
      setError('確認チェックボックスにチェックを入れてください');
      return;
    }

    setIsDeleting(true);
    try {
      // Simulate account deletion process
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Log out the user
      logout();
      
      // Redirect to login page
      navigate('/login');
    } catch (error) {
      setError('退会処理に失敗しました。もう一度お試しください。');
      setIsDeleting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-md w-full mx-4">
        <div className="p-6 border-b">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <AlertTriangle className="w-6 h-6 text-red-600" />
              <h3 className="text-lg font-medium text-gray-900">退会の確認</h3>
            </div>
            <button
              onClick={onClose}
              disabled={isDeleting}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="bg-red-50 border border-red-200 rounded-lg p-4">
            <p className="text-sm font-medium text-red-800 mb-2">
              重要な警告
            </p>
            <ul className="text-sm text-red-700 space-y-2">
              <li>• 退会すると、すべてのデータが完全に削除されます</li>
              <li>• この操作は取り消すことができません</li>
              <li>• 未使用のAIクレジットは返金されません</li>
              <li>• 保存されたテンプレートやフォルダも削除されます</li>
            </ul>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              確認のため、現在のパスワードを入力してください
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              placeholder="パスワード"
              required
            />
          </div>

          <label className="flex items-start gap-3">
            <input
              type="checkbox"
              checked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
              className="mt-1 rounded border-red-300 text-red-600 focus:ring-red-500"
            />
            <span className="text-sm text-gray-900">
              退会による影響を理解し、すべてのデータが完全に削除されることに同意します。
              この操作は取り消すことができません。
            </span>
          </label>

          {error && (
            <div className="flex items-center gap-2 text-sm text-red-600 bg-red-50 p-3 rounded-md">
              <AlertTriangle className="w-4 h-4 flex-shrink-0" />
              <p>{error}</p>
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              disabled={isDeleting}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              キャンセル
            </button>
            <button
              type="submit"
              disabled={!password || !isConfirmed || isDeleting}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              {isDeleting ? '処理中...' : '退会する'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}