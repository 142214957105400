import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { calculateCharacterCount } from '../utils/characterCount';
import type { Product } from '../types';

interface EditableTitleProps {
  title: string;
  isGenerating: boolean;
  product: Product;
  onTitleChange: (newTitle: string) => void;
  onEditStateChange?: (isEditing: boolean) => void;
  isGeneratingBulk?: boolean;
}

export function EditableTitle({ 
  title, 
  isGenerating,
  product,
  onTitleChange,
  onEditStateChange,
  isGeneratingBulk = false
}: EditableTitleProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    onEditStateChange?.(isEditing);
  }, [isEditing, onEditStateChange]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newTitle = e.target.value;
    // Calculate character count with the new title
    const testProduct = {
      ...product,
      title: newTitle
    };
    const charCount = calculateCharacterCount(testProduct);
    
    // Only update if within character limit
    if (charCount.total <= 65) {
      setEditedTitle(newTitle);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (editedTitle !== title) {
      onTitleChange(editedTitle);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleBlur();
    }
    if (e.key === 'Escape') {
      setEditedTitle(title);
      setIsEditing(false);
      onEditStateChange?.(false);
    }
  };

  if (isEditing) {
    return (
      <textarea
        ref={inputRef}
        value={editedTitle}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        className="w-full text-base font-medium text-gray-900 border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        rows={3}
      />
    );
  }

  return (
    <div className="group relative flex items-start gap-1">
      <button
        onClick={(e) => {
          e.stopPropagation();
          if (!isGenerating && !isGeneratingBulk) {
            setIsEditing(true);
            onEditStateChange?.(true);
          }
        }}
        className="flex-shrink-0 p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors mt-0.5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-4 h-4"
        >
          <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
          <path d="m15 5 4 4" />
        </svg>
      </button>
      <h3 className="flex-1 text-base font-medium text-gray-900 min-h-[3em] line-clamp-2">
        {(isGenerating || isGeneratingBulk) ? (
          <motion.div className="flex flex-wrap overflow-hidden">
            {title.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 1, y: 0 }}
                animate={{
                  y: [0, -8, 0],
                  scale: [1, 1.1, 1],
                  opacity: [1, 0.7, 1],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  delay: index * 0.08,
                  ease: "easeInOut"
                }}
                className="inline-block"
                style={{
                  textShadow: '0 0 12px rgba(59, 130, 246, 0.7)',
                }}
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
          </motion.div>
        ) : (
          <span>{title || '(タイトルなし)'}</span>
        )}
      </h3>
    </div>
  );
}