import React, { useState } from 'react';
import { Tag, Gavel } from 'lucide-react';
import type { BulkUpdates } from '../../../types/bulk';

interface BulkListingTypeFieldProps {
  onUpdate: (updates: BulkUpdates) => void;
}

export function BulkListingTypeField({ onUpdate }: BulkListingTypeFieldProps) {
  const [listingType, setListingType] = useState<'auction' | 'fixed' | ''>('');

  const handleApply = () => {
    if (listingType) {
      onUpdate({ listingType });
      setListingType('');
    }
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Tag className="w-5 h-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">出品形式を変更</span>
      </div>
      <div className="flex items-center gap-4">
        <select
          value={listingType}
          onChange={(e) => setListingType(e.target.value as 'auction' | 'fixed')}
          className="w-48 h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
        >
          <option value="">選択してください</option>
          <option value="auction">オークション</option>
          <option value="fixed">定額</option>
        </select>
        <button
          onClick={handleApply}
          disabled={!listingType}
          className={`h-[30px] px-3 rounded-md text-sm font-medium transition-colors ${
            listingType
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          }`}
        >
          変更
        </button>
      </div>
    </div>
  );
}