import React, { useState } from 'react';
import { FolderOpen } from 'lucide-react';
import { CATEGORIES } from '../../../utils/categories';
import type { BulkUpdates } from '../../../types/bulk';

interface BulkCategoryFieldProps {
  onUpdate: (updates: BulkUpdates) => void;
}

export function BulkCategoryField({ onUpdate }: BulkCategoryFieldProps) {
  const [category, setCategory] = useState('');

  const handleApply = () => {
    if (category) {
      onUpdate({ category });
      setCategory('');
    }
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <FolderOpen className="w-5 h-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">カテゴリを変更</span>
      </div>
      <div className="flex items-center gap-4">
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="w-48 h-[30px] rounded-md border-gray-300 bg-gray-50 text-sm"
        >
          <option value="">選択してください</option>
          {CATEGORIES.map(category => (
            <option key={category.id} value={category.id}>
              {category.label}
            </option>
          ))}
        </select>
        <button
          onClick={handleApply}
          disabled={!category}
          className={`h-[30px] px-3 rounded-md text-sm font-medium transition-colors ${
            category
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          }`}
        >
          変更
        </button>
      </div>
    </div>
  );
}