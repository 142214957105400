import React, { useState } from 'react';
import { Tag, Gavel } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { PriceEditor } from './PriceEditor';

interface PriceDisplayProps {
  listingType: 'auction' | 'fixed';
  startPrice: number;
  buyItNowPrice?: number;
  onUpdate?: (startPrice: number, buyItNowPrice?: number) => void;
  className?: string;
}

export function PriceDisplay({
  listingType,
  startPrice,
  buyItNowPrice,
  onUpdate,
  className = ''
}: PriceDisplayProps) {
  const [isEditing, setIsEditing] = useState(false);

  const handlePriceClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onUpdate) {
      setIsEditing(true);
    }
  };

  return (
    <div className={`relative ${className}`} onClick={handlePriceClick}>
      <div className="flex items-center gap-4 cursor-pointer hover:bg-gray-50 p-2 rounded-md -mx-2">
        <div className="flex items-center gap-2">
          {listingType === 'auction' ? (
            <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-amber-50 text-amber-600">
              <Gavel className="w-4 h-4" />
              オークション
            </span>
          ) : (
            <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-red-50 text-red-600">
              <Tag className="w-4 h-4" />
              定額
            </span>
          )}
        </div>
        <div className="flex items-center gap-2">
          <div className="text-sm">
            <span className="text-gray-600">
              {listingType === 'auction' ? '開始価格: ' : '販売価格: '}
            </span>
            <span className="font-medium text-gray-900">
              {startPrice.toLocaleString()}円
            </span>
          </div>
          {listingType === 'auction' && buyItNowPrice > 0 && (
            <div className="text-sm">
              <span className="text-gray-600">即決価格: </span>
              <span className="font-medium text-gray-900">
                {buyItNowPrice.toLocaleString()}円
              </span>
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {isEditing && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="overflow-hidden"
            onClick={e => e.stopPropagation()}
          >
            <div className="pt-3">
              <PriceEditor
                listingType={listingType}
                startPrice={startPrice}
                buyItNowPrice={buyItNowPrice}
                onSave={(newStartPrice, newBuyItNowPrice) => {
                  if (onUpdate) {
                    onUpdate(newStartPrice, newBuyItNowPrice);
                  }
                  setIsEditing(false);
                }}
                onCancel={() => setIsEditing(false)}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}