import { getUserByEmail, updateUser } from '../../db';

export async function sendPasswordResetEmail(email: string): Promise<void> {
  try {
    const user = await getUserByEmail(email);
    if (!user) {
      throw new Error('このメールアドレスは登録されていません');
    }

    // TODO: 実際のメール送信ロジックを実装
    // 開発環境では模擬的な遅延を入れる
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    console.log(`Password reset email sent to: ${email}`);
  } catch (error) {
    console.error('Password reset error:', error);
    throw error;
  }
}

export async function resetPassword(email: string, newPassword: string): Promise<void> {
  try {
    const user = await getUserByEmail(email);
    if (!user) {
      throw new Error('ユーザーが見つかりません');
    }

    await updateUser(email, {
      ...user,
      password: newPassword, // Note: 実際の実装ではパスワードをハッシュ化する
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Password update error:', error);
    throw error;
  }
}