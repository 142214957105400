import React from 'react';
import { AlertTriangle } from 'lucide-react';
import type { Member } from '../../../types/member';

interface CancelDeletionModalProps {
  member: Member;
  onConfirm: () => void;
  onCancel: () => void;
}

export function CancelDeletionModal({ member, onConfirm, onCancel }: CancelDeletionModalProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center gap-3 mb-4">
          <div className="flex-shrink-0">
            <AlertTriangle className="w-6 h-6 text-blue-600" />
          </div>
          <h3 className="text-lg font-medium text-gray-900">強制退会の取り消し</h3>
        </div>
        
        <p className="text-sm text-gray-600 mb-6">
          このアカウントの強制退会処理を取り消しますか？
          <br />
          取り消すと、アカウントは通常の利用が可能な状態に戻ります。
        </p>

        <div className="mb-6">
          <h4 className="text-sm font-medium text-gray-700 mb-2">対象のアカウント</h4>
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-xs text-gray-500">会員ID</p>
                <p className="text-sm font-medium text-gray-900">{member.id}</p>
              </div>
              <div>
                <p className="text-xs text-gray-500">メールアドレス</p>
                <p className="text-sm font-medium text-gray-900">{member.email}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            キャンセル
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            取り消しを実行
          </button>
        </div>
      </div>
    </div>
  );
}