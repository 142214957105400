import React from 'react';

interface NavigationItemProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  className?: string;
}

export function NavigationItem({ icon, label, onClick, className = 'text-gray-700 hover:bg-gray-50' }: NavigationItemProps) {
  return (
    <button
      className={`w-full px-4 py-2 text-sm flex items-center gap-2 ${className}`}
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </button>
  );
}