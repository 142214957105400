import React from 'react';
import { Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';

interface DownloadProgressProps {
  current: number;
  total: number;
  isVisible: boolean;
}

export function DownloadProgress({ current, total, isVisible }: DownloadProgressProps) {
  if (!isVisible) return null;

  const progress = Math.round((current / total) * 100);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
        <div className="flex items-center justify-center mb-4">
          <Loader2 className="w-12 h-12 text-blue-600 animate-spin" />
        </div>
        
        <p className="text-gray-700 text-center mb-4">
          画像を圧縮してダウンロードの準備をしています...
        </p>
        
        <div className="space-y-2">
          <div className="flex justify-between text-sm text-gray-600">
            <span>{current} / {total}</span>
            <span>{progress}%</span>
          </div>
          <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
            <motion.div
              className="absolute inset-y-0 left-0 bg-blue-600 rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.3 }}
            />
          </div>
          <p className="text-sm text-gray-500 text-center">
            画像{current}枚目を処理中...
          </p>
        </div>
      </div>
    </div>
  );
}