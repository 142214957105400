import React from 'react';

export function MemberListHeader() {
  return (
    <div className="hidden lg:grid lg:grid-cols-8 gap-4 px-6 py-3 bg-gray-50 text-sm font-medium text-gray-500 rounded-t-lg">
      <div>会員ID</div>
      <div className="-ml-[50px]">メールアドレス</div>
      <div>プラン</div>
      <div>登録日</div>
      <div>AIクレジット</div>
      <div>紹介者コード</div>
      <div>売上金</div>
      <div>ステータス</div>
    </div>
  );
}