import React from 'react';
import { Send } from 'lucide-react';
import { useContactForm } from './hooks/useContactForm';
import { ErrorMessage } from '../../auth/login/ErrorMessage';
import { SuccessMessage } from './SuccessMessage';

export function ContactForm() {
  const {
    name,
    email,
    category,
    message,
    error,
    success,
    isLoading,
    handleNameChange,
    handleEmailChange,
    handleCategoryChange,
    handleMessageChange,
    handleSubmit
  } = useContactForm();

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          会員ID
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={handleNameChange}
          className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-blue-500 focus:ring-blue-500 cursor-not-allowed"
          required
          readOnly
        />
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          メールアドレス
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-blue-500 focus:ring-blue-500 cursor-not-allowed"
          required
          readOnly
        />
      </div>

      <div>
        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
          お問い合わせ種類
        </label>
        <select
          id="category"
          value={category}
          onChange={handleCategoryChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        >
          <option value="">選択してください</option>
          <option value="account">アカウント関連</option>
          <option value="product">商品管理関連</option>
          <option value="template">テンプレート関連</option>
          <option value="shipping">配送関連</option>
          <option value="ai">AI機能関連</option>
          <option value="other">その他</option>
        </select>
      </div>

      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
          お問い合わせ内容
        </label>
        <textarea
          id="message"
          value={message}
          onChange={handleMessageChange}
          rows={6}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        />
      </div>

      <ErrorMessage error={error} />
      <SuccessMessage message={success} />

      <div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400 disabled:cursor-not-allowed"
        >
          <Send className="w-4 h-4" />
          <span>{isLoading ? '送信中...' : '送信する'}</span>
        </button>
      </div>
    </form>
  );
}