export async function sendVerificationEmail(email: string): Promise<void> {
  // TODO: 実際のメール送信ロジックを実装
  // 開発環境では模擬的な遅延を入れる
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  console.log(`Verification email sent to: ${email}`);
}

export async function verifyEmail(token: string): Promise<boolean> {
  // TODO: 実際のメール認証ロジックを実装
  // 開発環境では常に成功を返す
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  return true;
}