import React from 'react';
import { HelpSection } from './HelpSection';
import { TutorialVideo } from './TutorialVideo';
import { ContactForm } from './contact/ContactForm';
import { helpContent } from './helpContent';

export function HelpPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">ヘルプセンター</h1>
          
          {/* Tutorial Video Section */}
          <TutorialVideo />

          {/* Help Sections */}
          {Object.entries(helpContent).map(([category, items]) => (
            <HelpSection 
              key={category} 
              title={category} 
              items={items}
            />
          ))}

          {/* Contact Form */}
          <div className="mt-12">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-6">お問い合わせ</h2>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}