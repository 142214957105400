import React from 'react';
import { Zap } from 'lucide-react';
import { CostBreakdown } from '../shared/CostBreakdown';
import { useCostCalculation } from '../hooks/useCostCalculation';

export function BoostHackCard() {
  const { boostTotalHours, boostLaborCost, boostTotalCost, STANDARD_PLAN_MONTHLY } = useCostCalculation();

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-6 shadow-md border border-blue-100">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-blue-100 rounded-lg">
          <Zap className="w-6 h-6 text-blue-600" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900">Boost Hackを使用</h3>
      </div>
      
      <div className="space-y-4">
        <div className="bg-white bg-opacity-50 rounded-lg p-4">
          <p className="font-medium text-gray-900">月間1,000商品の場合</p>
          <CostBreakdown
            hours={boostTotalHours}
            laborCost={boostLaborCost}
            systemCost={STANDARD_PLAN_MONTHLY}
            totalCost={boostTotalCost}
          />
          <div className="mt-3 text-sm text-gray-600 bg-blue-50 p-3 rounded-lg">
            <p>※ 同一カテゴリや商品コンディションが整理されている場合の処理時間です。</p>
          </div>
        </div>
      </div>
    </div>
  );
}