import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2 } from 'lucide-react';

interface MobileProgressMessageProps {
  isVisible: boolean;
  currentIndex: number;
  totalCount: number;
}

export function MobileProgressMessage({ isVisible, currentIndex, totalCount }: MobileProgressMessageProps) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-r from-blue-600 to-indigo-600 text-white shadow-lg"
        >
          <div className="max-w-7xl mx-auto px-4 py-3 flex items-center gap-3">
            <Loader2 className="w-5 h-5 animate-spin" />
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium">
                タイトルを生成中... ({currentIndex + 1}/{totalCount})
              </p>
              <div className="mt-1 h-1 bg-white/20 rounded-full overflow-hidden">
                <motion.div
                  className="h-full bg-white"
                  initial={{ width: 0 }}
                  animate={{ width: `${((currentIndex + 1) / totalCount) * 100}%` }}
                  transition={{ duration: 0.3 }}
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}