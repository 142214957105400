import { useAiCreditsStore } from '../../stores/aiCreditsStore';
import { getMonthlyResetDate } from './dateUtils';

export function scheduleMonthlyReset() {
  // Get next reset date
  const nextReset = getMonthlyResetDate();
  const now = new Date();
  
  // Calculate delay until next reset
  const delay = nextReset.getTime() - now.getTime();
  
  // Schedule reset
  setTimeout(() => {
    resetCredits();
    // Schedule next month's reset
    scheduleMonthlyReset();
  }, delay);
}

function resetCredits() {
  const store = useAiCreditsStore.getState();
  const { maxCredits } = store;
  
  // Reset credits to max amount
  store.resetCredits(maxCredits);
  
  console.log(`AI credits reset to ${maxCredits} at ${new Date().toISOString()}`);
}