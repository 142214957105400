export const DEFAULT_ADMIN = {
  email: 'admin@test.com',
  password: 'admin123',
  role: 'admin',
  name: '管理者',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  lastLoginAt: new Date().toISOString(),
  settings: {
    defaultListingType: 'auction',
    defaultAuctionDuration: 7,
    defaultEndTime: '22',
    defaultSerialNumber: 0
  }
};