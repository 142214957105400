import React from 'react';
import { X } from 'lucide-react';
import { Member } from '../../../types/member';
import { formatDate } from '../../../utils/dateUtils';
import { MemberStatus } from './MemberStatus';

interface MemberDetailsModalProps {
  member: Member;
  onClose: () => void;
  onCompensate: (amount: number) => void;
}

export function MemberDetailsModal({ member, onClose, onCompensate }: MemberDetailsModalProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full mx-4">
        <div className="flex items-center justify-between p-6 border-b">
          <h2 className="text-xl font-medium text-gray-900">会員詳細</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h3 className="text-sm font-medium text-gray-500">会員ID</h3>
              <p className="mt-1 text-sm text-gray-900">{member.id}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">ステータス</h3>
              <div className="mt-1">
                <MemberStatus status={member.status} />
              </div>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">メールアドレス</h3>
              <p className="mt-1 text-sm text-gray-900">{member.email}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">プラン</h3>
              <p className="mt-1 text-sm text-gray-900">{member.plan}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">登録日</h3>
              <p className="mt-1 text-sm text-gray-900">{formatDate(member.registeredAt)}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">最終ログイン</h3>
              <p className="mt-1 text-sm text-gray-900">{formatDate(member.lastLoginAt)}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">AIクレジット残高</h3>
              <p className="mt-1 text-sm text-gray-900">
                {member.aiCredits.toLocaleString()} / {member.maxAiCredits.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">紹介者コード</h3>
              <p className="mt-1 text-sm text-gray-900">
                {member.referralCode || '-'}
              </p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">売上金</h3>
              <p className="mt-1 text-sm text-gray-900">
                ¥{member.salesAmount.toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3 px-6 py-4 bg-gray-50 rounded-b-lg">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
}