import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProductList } from './components/ProductList';
import { FolderList } from './components/folders/FolderList';
import { Header } from './components/Header';
import { DownloadActions } from './components/downloads/DownloadActions';
import { PricingPage } from './components/pricing/PricingPage';
import { AdminDashboard } from './components/admin/AdminDashboard';
import { LoginPage } from './components/auth/LoginPage';
import { SignupPage } from './components/auth/signup/SignupPage';
import { PasswordResetPage } from './components/auth/password-reset/PasswordResetPage';
import { VerifyEmailPage } from './components/auth/verification/VerifyEmailPage';
import { HelpPage } from './components/help/HelpPage';
import { AccountPage } from './components/account/AccountPage';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { useProductStore } from './stores/productStore';
import { useFolderStore } from './stores/folderStore';
import type { BulkUpdates } from './types/bulk';

export function App() {
  const {
    products,
    updateProduct,
    deleteProduct,
    updateMultipleProducts
  } = useProductStore();

  const {
    currentFolderId
  } = useFolderStore();

  const filteredProducts = products.filter(product => {
    if (currentFolderId === null) {
      return true;
    }
    return product.folderId === currentFolderId;
  });

  const handleBulkUpdate = (updates: BulkUpdates, selectedIds: number[]) => {
    updateMultipleProducts(updates, selectedIds);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgot-password" element={<PasswordResetPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <div className="min-h-screen bg-gray-50">
                <Header />
                <div className="py-4 sm:py-8">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <DownloadActions />
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                      <div className="lg:col-span-1">
                        <FolderList />
                      </div>
                      <div className="lg:col-span-3">
                        <ProductList
                          products={filteredProducts}
                          onUpdate={updateProduct}
                          onDelete={deleteProduct}
                          onBulkUpdate={handleBulkUpdate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/account"
          element={
            <PrivateRoute>
              <>
                <Header />
                <AccountPage />
              </>
            </PrivateRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <PrivateRoute>
              <>
                <Header />
                <PricingPage />
              </>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <>
                <Header />
                <AdminDashboard />
              </>
            </PrivateRoute>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoute>
              <>
                <Header />
                <HelpPage />
              </>
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}