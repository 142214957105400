import React from 'react';
import { Calculator } from 'lucide-react';
import { CostComparisonGrid } from './CostComparisonGrid';
import { CostComparisonSummary } from './CostComparisonSummary';

export function CostComparison() {
  return (
    <div className="mt-12">
      <div className="flex items-center justify-center gap-3 mb-8">
        <Calculator className="w-6 h-6 text-gray-600" />
        <h2 className="text-2xl font-bold text-gray-900">費用対効果の比較</h2>
      </div>
      <CostComparisonGrid />
      <CostComparisonSummary />
    </div>
  );
}