import React from 'react';
import { AICreditsCharge } from '../charges/AICreditsCharge';
import { TimeComparison } from '../comparison/TimeComparison';
import { CostComparison } from '../comparison/CostComparison';

export function AdditionalChargesSection() {
  return (
    <div className="space-y-12">
      <AICreditsCharge />
      <TimeComparison />
      <CostComparison />
    </div>
  );
}