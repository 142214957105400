import React, { useState } from 'react';
import { PricingCard } from './PricingCard';
import { usePricingPlans } from '../../hooks/usePricingPlans';

export function PricingPlans() {
  const { plans } = usePricingPlans();
  const [selectedPlanId, setSelectedPlanId] = useState<string>('standard'); // Default to standard plan

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
      {plans.map((plan) => (
        <PricingCard
          key={plan.id}
          plan={plan}
          isSelected={selectedPlanId === plan.id}
          onSelect={() => setSelectedPlanId(plan.id)}
        />
      ))}
    </div>
  );
}