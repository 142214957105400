import React from 'react';
import { X } from 'lucide-react';

interface WatermarkSettingsProps {
  enabled: boolean;
  size: number;
  quality: number;
  onEnabledChange: (enabled: boolean) => void;
  onSizeChange: (size: number) => void;
  onQualityChange: (quality: number) => void;
  onClose: () => void;
}

export function WatermarkSettings({
  enabled,
  size,
  quality,
  onEnabledChange,
  onSizeChange,
  onQualityChange,
  onClose
}: WatermarkSettingsProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-md w-full mx-4">
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-lg font-medium text-gray-900">画像設定</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* Watermark Settings */}
          <div className="space-y-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={enabled}
                onChange={(e) => onEnabledChange(e.target.checked)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">
                管理番号を画像に埋め込む
              </span>
            </label>
            <p className="text-sm text-gray-500 ml-6">
              画像の左下に管理番号を白い縁取り付きで追加します
            </p>
          </div>

          {enabled && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                文字サイズ ({size}%)
              </label>
              <input
                type="range"
                min="10"
                max="200"
                value={size}
                onChange={(e) => onSizeChange(Number(e.target.value))}
                className="w-full"
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>小</span>
                <span>大</span>
              </div>
            </div>
          )}

          {/* Compression Quality Settings */}
          <div className="space-y-2 pt-4 border-t">
            <label className="block text-sm font-medium text-gray-700">
              画質 ({quality}%)
            </label>
            <input
              type="range"
              min="10"
              max="100"
              value={quality}
              onChange={(e) => onQualityChange(Number(e.target.value))}
              className="w-full"
            />
            <div className="flex justify-between text-xs text-gray-500">
              <span>低画質・小サイズ</span>
              <span>高画質・大サイズ</span>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              画質を下げるとファイルサイズを小さくできます。
              {quality < 50 && '低画質では画像が粗くなる可能性があります。'}
            </p>
          </div>
        </div>

        <div className="flex justify-end gap-3 px-6 py-4 bg-gray-50 rounded-b-lg">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
}