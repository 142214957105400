import { Product } from '../types';
import { PREDEFINED_SHIPPING_METHODS } from './shipping/constants';

export function getShippingMethodsText(product: Product): string {
  const methods = [];
  
  // Yamato Transport methods
  if (product.nekoposu) methods.push('ネコポス');
  if (product.nekoCompact) methods.push('宅急便コンパクト');
  if (product.nekoEazy) {
    const size = product.nekoEazySize ? `${product.nekoEazySize}サイズ` : '';
    const weight = product.nekoEazyWeight ? `${product.nekoEazyWeight}kg` : '';
    const details = [size, weight].filter(Boolean).join('、');
    methods.push(`宅急便${details ? `（${details}）` : ''}`);
  }

  // Japan Post methods
  if (product.yuPacketMini) methods.push('ゆうパケットポストmini');
  if (product.yuPacket) methods.push('ゆうパケット');
  if (product.yuPacketPlus) methods.push('ゆうパケットプラス');
  if (product.yuPack) {
    const size = product.yuPackSize ? `${product.yuPackSize}サイズ` : '';
    const weight = product.yuPackWeight ? `${product.yuPackWeight}kg` : '';
    const details = [size, weight].filter(Boolean).join('、');
    methods.push(`ゆうパック${details ? `（${details}）` : ''}`);
  }

  // Custom shipping methods
  if (product.customShippingMethods?.length > 0) {
    product.customShippingMethods.forEach(method => {
      if (method.name === 'other' && method.carrierName) {
        // For custom shipping methods, include price if available and not shippingLater
        const details = [];
        if (!product.shippingLater) {
          if (method.price) details.push(`${method.price}円`);
          if (method.hokkaido) details.push(`北海道：${method.hokkaido}円`);
          if (method.okinawa) details.push(`沖縄：${method.okinawa}円`);
          if (method.island) details.push(`離島：${method.island}円`);
        }
        const detailsText = details.length > 0 ? `（${details.join('、')}）` : '';
        methods.push(`${method.carrierName}${detailsText}`);
      } else if (method.name) {
        // For predefined methods
        const predefinedMethod = PREDEFINED_SHIPPING_METHODS.find(m => m.value === method.name);
        if (predefinedMethod) {
          const details = [];
          if (!product.shippingLater) {
            if (method.price) details.push(`${method.price}円`);
            if (method.hokkaido) details.push(`北海道：${method.hokkaido}円`);
            if (method.okinawa) details.push(`沖縄：${method.okinawa}円`);
            if (method.island) details.push(`離島：${method.island}円`);
          }
          const detailsText = details.length > 0 ? `（${details.join('、')}）` : '';
          methods.push(`${predefinedMethod.label}${detailsText}`);
        }
      }
    });
  }

  if (methods.length === 0) {
    return '';
  }

  return `配送方法：\n${methods.map(method => `・${method}`).join('\n')}`;
}

export function updateDescriptionWithShippingMethods(description: string | undefined, product: Product): string {
  if (!description) return '';
  
  // Remove existing shipping methods section
  const shippingIndex = description.indexOf('\n\n配送方法：');
  const cleanDescription = shippingIndex !== -1 
    ? description.substring(0, shippingIndex)
    : description;

  // Add shipping methods
  const shippingText = getShippingMethodsText(product);
  return shippingText ? `${cleanDescription}\n\n${shippingText}` : cleanDescription;
}