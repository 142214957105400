import React, { useState, useRef, useEffect } from 'react';
import { LogOut, ChevronDown, User, Settings, RefreshCw, Menu, X, Home } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { useProductStore } from '../stores/productStore';
import { useFolderStore } from '../stores/folderStore';
import { UserDropdown } from './header/UserDropdown';
import { AiCreditsDisplay } from './header/AiCreditsDisplay';
import { motion, AnimatePresence } from 'framer-motion';

export function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuthStore();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const loadProducts = useProductStore(state => state.loadProducts);
  const loadFolders = useFolderStore(state => state.loadFolders);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleRefresh = async () => {
    if (isRefreshing) return;
    
    setIsRefreshing(true);
    try {
      await Promise.all([
        loadProducts(),
        loadFolders()
      ]);
    } catch (error) {
      console.error('Failed to refresh data:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Close mobile menu when route changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  const isMainPage = location.pathname === '/';

  return (
    <header className="relative bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 shadow-lg">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(96,165,250,0.1),transparent_50%),radial-gradient(circle_at_70%_50%,rgba(245,158,11,0.1),transparent_50%)]"></div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 transform -rotate-12">
              <svg viewBox="0 0 24 24" className="w-full h-full filter drop-shadow-lg">
                <path
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                  fill="url(#boost-gradient)"
                  className="filter drop-shadow-md"
                />
                <defs>
                  <linearGradient id="boost-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#60A5FA' }} />
                    <stop offset="100%" style={{ stopColor: '#F59E0B' }} />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <h1 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-amber-400 bg-clip-text text-transparent drop-shadow">
              Boost Hack
            </h1>
          </div>
          
          {/* Mobile menu button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="lg:hidden p-2 text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md"
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>

          {/* Desktop navigation */}
          {user && (
            <div className="hidden lg:flex items-center gap-4">
              <button
                onClick={handleRefresh}
                disabled={isRefreshing}
                className={`flex items-center gap-2 px-3 py-2 text-sm text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md transition-colors ${
                  isRefreshing ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                title="データを更新"
              >
                <RefreshCw className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
                <span className="hidden sm:inline">更新</span>
              </button>

              <AiCreditsDisplay />

              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center gap-2 px-3 py-2 text-sm text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md transition-colors"
                >
                  <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-amber-500 flex items-center justify-center shadow-lg">
                    <User className="w-4 h-4 text-white" />
                  </div>
                  <span className="hidden sm:inline">{user.email}</span>
                  <ChevronDown className={`w-4 h-4 transition-transform duration-200 ${
                    isDropdownOpen ? 'rotate-180' : ''
                  }`} />
                </button>

                {isDropdownOpen && (
                  <UserDropdown onLogout={handleLogout} />
                )}
              </div>
            </div>
          )}
        </div>

        {/* Mobile menu */}
        <AnimatePresence>
          {isMobileMenuOpen && user && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="lg:hidden"
            >
              <div className="px-2 pt-2 pb-3 space-y-1 border-t border-gray-700">
                {/* AI Credits Display - Moved to top */}
                <div className="px-3 py-2">
                  <AiCreditsDisplay />
                </div>

                {!isMainPage && (
                  <button
                    onClick={() => navigate('/')}
                    className="w-full flex items-center gap-2 px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md"
                  >
                    <Home className="w-5 h-5" />
                    <span>メインページ</span>
                  </button>
                )}

                <button
                  onClick={handleRefresh}
                  disabled={isRefreshing}
                  className="w-full flex items-center gap-2 px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md"
                >
                  <RefreshCw className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
                  <span>データを更新</span>
                </button>

                <button
                  onClick={() => navigate('/account')}
                  className="w-full flex items-center gap-2 px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md"
                >
                  <User className="w-5 h-5" />
                  <span>マイアカウント</span>
                </button>

                <button
                  onClick={() => navigate('/pricing')}
                  className="w-full flex items-center gap-2 px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md"
                >
                  <Settings className="w-5 h-5" />
                  <span>プラン一覧</span>
                </button>

                <button
                  onClick={() => navigate('/admin')}
                  className="w-full flex items-center gap-2 px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md"
                >
                  <Settings className="w-5 h-5" />
                  <span>管理画面</span>
                </button>

                <button
                  onClick={() => navigate('/help')}
                  className="w-full flex items-center gap-2 px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-md"
                >
                  <Settings className="w-5 h-5" />
                  <span>ヘルプ</span>
                </button>

                <button
                  onClick={handleLogout}
                  className="w-full flex items-center gap-2 px-3 py-2 text-base text-red-400 hover:text-red-300 hover:bg-red-900/20 rounded-md"
                >
                  <LogOut className="w-5 h-5" />
                  <span>ログアウト</span>
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
}