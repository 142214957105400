import type { Member } from '../types/member';

export const mockMembers: Member[] = [
  {
    id: 'M001',
    email: 'yamada@example.com',
    plan: 'ベーシック',
    status: 'active',
    registeredAt: '2024-01-15T10:30:00Z',
    lastLoginAt: '2024-03-10T15:45:00Z',
    aiCredits: 150,
    maxAiCredits: 200,
    referralCode: 'REF001',
    salesAmount: 125000
  },
  {
    id: 'M002',
    email: 'suzuki@example.com',
    plan: 'スタンダード',
    status: 'active',
    registeredAt: '2024-01-20T14:20:00Z',
    lastLoginAt: '2024-03-11T09:15:00Z',
    aiCredits: 800,
    maxAiCredits: 1000,
    referralCode: 'REF002',
    salesAmount: 350000
  },
  {
    id: 'M003',
    email: 'sato@example.com',
    plan: 'プロフェッショナル',
    status: 'suspended',
    registeredAt: '2024-02-01T09:00:00Z',
    lastLoginAt: '2024-03-05T11:30:00Z',
    aiCredits: 2500,
    maxAiCredits: 3000,
    referralCode: 'REF003',
    salesAmount: 780000
  }
];