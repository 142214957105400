import React from 'react';
import { Play } from 'lucide-react';

export function TutorialVideo() {
  return (
    <div className="mt-12 bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-red-100 rounded-lg">
          <Play className="w-5 h-5 text-red-600" />
        </div>
        <h2 className="text-xl font-bold text-gray-900">使い方マニュアル動画</h2>
      </div>

      <div className="relative pb-[56.25%] h-0 rounded-lg overflow-hidden">
        <iframe
          src="https://www.youtube.com/embed/u0qRa42JTPI"
          title="使い方マニュアル動画"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full rounded-lg"
        />
      </div>
    </div>
  );
}