import React from 'react';
import { Check } from 'lucide-react';
import type { PricingPlan } from '../../types/pricing';

interface PricingCardProps {
  plan: PricingPlan;
  isSelected?: boolean;
  onSelect?: () => void;
}

export function PricingCard({ plan, isSelected = false, onSelect }: PricingCardProps) {
  return (
    <div
      className={`relative bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 ${
        isSelected 
          ? 'ring-4 ring-blue-500 ring-opacity-50 scale-105 shadow-xl' 
          : 'hover:scale-102 hover:shadow-xl'
      }`}
    >
      {isSelected && (
        <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700" />
      )}
      
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-2xl font-bold text-gray-900">{plan.name}</h3>
          {isSelected && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              <Check className="w-4 h-4 mr-1" />
              選択中
            </span>
          )}
        </div>

        <div className="flex items-baseline mb-6">
          <span className="text-4xl font-bold text-gray-900">{plan.price.toLocaleString()}</span>
          <span className="text-gray-600 ml-1">円/月</span>
        </div>

        <ul className="space-y-4 mb-8">
          <li className="flex items-start gap-3">
            <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
            <span className="text-gray-600">基本機能</span>
          </li>
          <li className="flex items-start gap-3">
            <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
            <span className="text-gray-600">
              AIタイトル生成 月{plan.aiLimit.toLocaleString()}回まで
            </span>
          </li>
        </ul>
      </div>

      <div className="px-6 pb-6">
        <button
          onClick={onSelect}
          className={`w-full py-3 px-4 rounded-md font-medium transition-colors ${
            isSelected
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
          }`}
        >
          {isSelected ? '選択中のプラン' : 'このプランを選択'}
        </button>
      </div>
    </div>
  );
}