import React, { useState, useEffect } from 'react';
import { Product } from '../types';
import { ShippingOptions } from './shipping/ShippingOptions';
import { ShippingMethodsGroup } from './shipping/ShippingMethodsGroup';
import { AutoExtensionSettings } from './AutoExtensionSettings';
import { AuctionOptions } from './AuctionOptions';
import { ShippingLocation } from './shipping/ShippingLocation';
import { FeaturedAuctionSettings } from './FeaturedAuctionSettings';
import { DescriptionInput } from './DescriptionInput';
import { BasicSettings } from './settings/BasicSettings';
import { CategorySelector } from './settings/CategorySelector';
import { ConditionSettings } from './settings/ConditionSettings';
import { updateDescriptionWithShippingMethods } from '../utils/shippingUtils';
import { useDescriptionTemplateStore } from '../stores/descriptionTemplateStore';

interface DetailSettingsProps {
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  useDescriptionTemplate: boolean;
  onDescriptionTemplateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DetailSettings: React.FC<DetailSettingsProps> = ({
  product,
  onChange,
  useDescriptionTemplate,
  onDescriptionTemplateChange
}) => {
  const [customShippingMethods, setCustomShippingMethods] = useState(product.customShippingMethods || []);
  const { getTemplateByCondition } = useDescriptionTemplateStore();

  // Sync description with condition changes and shipping methods
  useEffect(() => {
    let updatedDescription = product.description;

    if (useDescriptionTemplate && product.condition) {
      // Get template content first
      const templateId = product.descriptionTemplateId;
      const templates = useDescriptionTemplateStore.getState().templates;
      const template = templateId ? 
        templates.find(t => t.id === templateId)?.contents[product.condition] :
        getTemplateByCondition(product.condition);

      if (template) {
        updatedDescription = template;
      }
    }

    // Always update shipping methods, regardless of template usage
    const descriptionWithShipping = updateDescriptionWithShippingMethods(updatedDescription || '', product);
    
    if (descriptionWithShipping !== product.description) {
      onChange({
        target: { name: 'description', value: descriptionWithShipping }
      } as React.ChangeEvent<HTMLTextAreaElement>);
    }
  }, [
    product.condition,
    product.descriptionTemplateId,
    useDescriptionTemplate,
    product.nekoposu,
    product.nekoCompact,
    product.nekoEazy,
    product.nekoEazySize,
    product.nekoEazyWeight,
    product.yuPacketMini,
    product.yuPacket,
    product.yuPacketPlus,
    product.yuPack,
    product.yuPackSize,
    product.yuPackWeight,
    JSON.stringify(product.customShippingMethods)
  ]);

  const handleShippingOptionChange = (name: string, checked: boolean) => {
    const event = {
      target: {
        name,
        type: 'checkbox',
        checked
      }
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

  const handleShippingResponsibilityChange = (value: string) => {
    onChange({
      target: { name: 'shippingResponsibility', value }
    } as React.ChangeEvent<HTMLSelectElement>);
  };

  const handleCustomShippingAdd = () => {
    if (customShippingMethods.length >= 10) return;

    const newMethod = {
      name: '',
      price: '',
      hokkaido: '',
      okinawa: '',
      island: ''
    };
    const newMethods = [...customShippingMethods, newMethod];
    setCustomShippingMethods(newMethods);
    onChange({
      target: { name: 'customShippingMethods', value: newMethods }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleCustomShippingUpdate = (index: number, updatedMethod: any) => {
    const newMethods = [...customShippingMethods];
    newMethods[index] = updatedMethod;
    setCustomShippingMethods(newMethods);
    onChange({
      target: { name: 'customShippingMethods', value: newMethods }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleCustomShippingRemove = (index: number) => {
    const newMethods = customShippingMethods.filter((_, i) => i !== index);
    setCustomShippingMethods(newMethods);
    onChange({
      target: { name: 'customShippingMethods', value: newMethods }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const showShippingPrices = !product.shippingCOD && product.shippingResponsibility !== 'seller';
  const showShippingMethods = true;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* Left side: Shipping settings */}
      <div className="space-y-6">
        {/* Condition Settings */}
        <ConditionSettings
          value={product.condition}
          onChange={onChange}
        />

        {/* Shipping Options */}
        <div className="bg-white rounded-lg p-4 border border-gray-200">
          <h4 className="font-medium mb-4">送料設定オプション</h4>
          <ShippingOptions
            shippingLater={product.shippingLater}
            shippingCOD={product.shippingCOD}
            shippingResponsibility={product.shippingResponsibility}
            onChange={handleShippingOptionChange}
            onShippingResponsibilityChange={handleShippingResponsibilityChange}
          />
        </div>

        {/* All Shipping Methods */}
        {showShippingMethods && (
          <ShippingMethodsGroup
            product={product}
            onChange={onChange}
            onCustomMethodAdd={handleCustomShippingAdd}
            onCustomMethodUpdate={handleCustomShippingUpdate}
            onCustomMethodRemove={handleCustomShippingRemove}
            showPrices={showShippingPrices}
          />
        )}

        {/* Shipping Location */}
        <ShippingLocation
          value={product.shippingLocation}
          onChange={onChange}
        />
      </div>

      {/* Right side: Basic settings */}
      <div className="space-y-6">
        {/* Category */}
        <CategorySelector
          value={product.category}
          onChange={onChange}
        />

        {/* Basic Settings */}
        <BasicSettings product={product} onChange={onChange} />

        {/* Product Description */}
        <DescriptionInput
          description={product.description}
          condition={product.condition}
          product={product}
          onChange={onChange}
          onTemplateChange={onDescriptionTemplateChange}
          useTemplate={useDescriptionTemplate}
        />

        {/* Featured Auction Settings */}
        <FeaturedAuctionSettings
          isFeatured={product.isFeatured}
          featuredMinPrice={product.featuredMinPrice}
          featuredMaxPrice={product.featuredMaxPrice}
          recommendedCollection={product.recommendedCollection}
          recommendedCollectionRate={product.recommendedCollectionRate}
          onChange={onChange}
        />

        {/* Auto Extension Settings */}
        <AutoExtensionSettings
          autoExtension={product.autoExtension}
          autoExtensionRate={product.autoExtensionRate}
          autoExtensionCount={product.autoExtensionCount}
          onChange={onChange}
        />

        {/* Auction Options */}
        <AuctionOptions
          ratingRestriction={product.ratingRestriction}
          badRatingRestriction={product.badRatingRestriction}
          bidderAuthentication={product.bidderAuthentication}
          returnsAccepted={product.returnsAccepted}
          autoExtensionByBid={product.autoExtensionByBid}
          onChange={onChange}
          showAutoExtension={product.listingType === 'auction'}
        />
      </div>
    </div>
  );
};