import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Mail, CheckCircle, AlertCircle } from 'lucide-react';

export function VerifyEmailPage() {
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;

  useEffect(() => {
    if (!email) {
      navigate('/signup', { replace: true });
      return;
    }

    const timer = setTimeout(() => {
      setIsVerifying(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [email, navigate]);

  if (!email) {
    return null;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 text-center">
        <div className="mx-auto w-24 h-24 flex items-center justify-center rounded-full bg-blue-100">
          <Mail className="w-12 h-12 text-blue-600" />
        </div>

        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          メール認証
        </h2>

        {isVerifying ? (
          <div className="mt-2 text-sm text-gray-600">
            <p className="mb-4">認証メールを送信しました。</p>
            <p>メールに記載されたリンクをクリックして、</p>
            <p>メールアドレスの認証を完了してください。</p>
          </div>
        ) : error ? (
          <div className="mt-2">
            <div className="flex items-center justify-center gap-2 text-red-600">
              <AlertCircle className="w-5 h-5" />
              <span>{error}</span>
            </div>
            <button
              onClick={() => setIsVerifying(true)}
              className="mt-4 text-sm text-blue-600 hover:text-blue-500"
            >
              認証メールを再送信
            </button>
          </div>
        ) : (
          <div className="mt-2">
            <div className="flex items-center justify-center gap-2 text-green-600">
              <CheckCircle className="w-5 h-5" />
              <span>メールアドレスの認証が完了しました</span>
            </div>
            <button
              onClick={() => navigate('/login')}
              className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              ログインページへ
            </button>
          </div>
        )}

        <div className="mt-2 text-sm text-gray-500">
          <p>{email}</p>
        </div>
      </div>
    </div>
  );
}