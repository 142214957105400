import React from 'react';
import { AlertTriangle } from 'lucide-react';
import type { Member } from '../../../types/member';

interface MemberDeleteModalProps {
  member: Member;
  onConfirm: () => void;
  onCancel: () => void;
}

export function MemberDeleteModal({ member, onConfirm, onCancel }: MemberDeleteModalProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center gap-3 mb-4">
          <div className="flex-shrink-0">
            <AlertTriangle className="w-6 h-6 text-red-600" />
          </div>
          <h3 className="text-lg font-medium text-gray-900">強制退会の確認</h3>
        </div>
        
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
          <p className="text-sm text-red-600 font-medium mb-2">
            重要な警告
          </p>
          <ul className="text-sm text-red-600 space-y-2">
            <li>• このアカウントは24時間後に強制退会となります</li>
            <li>• アカウントはブラックリストに登録され、再登録が不可能になります</li>
            <li>• この操作は取り消すことができません</li>
          </ul>
        </div>

        <div className="mb-6">
          <h4 className="text-sm font-medium text-gray-700 mb-2">退会対象のアカウント</h4>
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-xs text-gray-500">会員ID</p>
                <p className="text-sm font-medium text-gray-900">{member.id}</p>
              </div>
              <div>
                <p className="text-xs text-gray-500">メールアドレス</p>
                <p className="text-sm font-medium text-gray-900">{member.email}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            キャンセル
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
          >
            強制退会を実行
          </button>
        </div>
      </div>
    </div>
  );
}