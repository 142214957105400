import type { Product } from '../types';

export const MAX_TOTAL_LENGTH = 65;

export interface CharacterCount {
  total: number;
  title: number;
  itemNumbers: number;
  memo: number;
  memoReplacement: number;
  isValid: boolean;
}

function countFullWidthCharacters(text: string): number {
  let count = 0;
  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i);
    // Count full-width characters (including Japanese characters) as 1
    // and half-width characters as 0.5
    if (
      (code >= 0x3000 && code <= 0x9FFF) ||   // Japanese and other CJK characters
      (code >= 0xFF00 && code <= 0xFFEF) ||   // Full-width roman chars and half-width katakana
      (code >= 0x20000 && code <= 0x2A6DF) || // CJK Unified Ideographs Extension B
      (code >= 0x2A700 && code <= 0x2B73F) || // CJK Unified Ideographs Extension C
      (code >= 0x2B740 && code <= 0x2B81F) || // CJK Unified Ideographs Extension D
      (code >= 0x2B820 && code <= 0x2CEAF) || // CJK Unified Ideographs Extension E
      (code >= 0x2CEB0 && code <= 0x2EBEF)    // CJK Unified Ideographs Extension F
    ) {
      count += 1;
    } else {
      count += 0.5;
    }
  }
  return Math.ceil(count);
}

function countMemoReplacements(text: string, memo: string): number {
  if (!text || !memo) return 0;
  const asteriskCount = (text.match(/\*/g) || []).length;
  return countFullWidthCharacters(memo) * asteriskCount;
}

export function calculateCharacterCount(product: Product): CharacterCount {
  // Get base title length (without asterisks)
  const baseTitle = product.title.replace(/\*/g, '');
  const baseTitleLength = countFullWidthCharacters(baseTitle);
  
  // Calculate memo replacements in title
  const titleMemoReplacements = countMemoReplacements(product.title, product.memo);

  // Calculate item number lengths
  let itemNumbersLength = 0;
  let activeNumbers = 0;

  // Process first item number
  if (product.itemNumber && (product.itemNumberPrefix || product.itemNumberSuffix)) {
    let number1 = product.itemNumber;
    if (number1.includes('*') && product.memo) {
      number1 = number1.replace(/\*/g, product.memo);
    }
    if (product.itemNumberSerial) {
      number1 = `${product.serialNumber}${number1}`;
    }
    itemNumbersLength += countFullWidthCharacters(number1);
    activeNumbers++;
  }

  // Process second item number independently
  if (product.itemNumber2 && (product.memoPrefix || product.memoSuffix)) {
    let number2 = product.itemNumber2;
    if (number2.includes('*') && product.memo) {
      number2 = number2.replace(/\*/g, product.memo);
    }
    if (product.memoSerial) {
      number2 = `${number2}${product.serialNumber}`;
    }
    if (!product.itemNumber || number2 !== product.itemNumber) {
      itemNumbersLength += countFullWidthCharacters(number2);
      activeNumbers++;
    }
  }

  // Add spaces between item numbers if there are multiple
  const spacesNeeded = Math.max(0, activeNumbers - 1);
  
  // Calculate total length
  const total = baseTitleLength + 
    titleMemoReplacements + 
    itemNumbersLength + 
    spacesNeeded;

  return {
    total,
    title: baseTitleLength,
    itemNumbers: itemNumbersLength,
    memo: countFullWidthCharacters(product.memo),
    memoReplacement: titleMemoReplacements,
    isValid: total <= MAX_TOTAL_LENGTH
  };
}