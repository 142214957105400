import React from 'react';
import { TrendingDown } from 'lucide-react';

const HOURLY_RATE = 1200;
const BASIC_PLAN_MONTHLY = 2980;
const PRODUCTS_PER_MONTH = 1000;
const MANUAL_TIME_PER_PRODUCT = 5;
const BOOST_TIME_PER_PRODUCT = 0.2;

export function CostComparisonSummary() {
  // 手動作業の計算
  const manualTotalMinutes = PRODUCTS_PER_MONTH * MANUAL_TIME_PER_PRODUCT;
  const manualTotalHours = manualTotalMinutes / 60;
  const manualCost = manualTotalHours * HOURLY_RATE;

  // Boost Hackの計算
  const boostTotalMinutes = PRODUCTS_PER_MONTH * BOOST_TIME_PER_PRODUCT;
  const boostTotalHours = boostTotalMinutes / 60;
  const boostLaborCost = boostTotalHours * HOURLY_RATE;
  const boostTotalCost = boostLaborCost + BASIC_PLAN_MONTHLY;

  // 削減額の計算
  const savings = manualCost - boostTotalCost;
  const savingsPercentage = ((manualCost - boostTotalCost) / manualCost) * 100;

  return (
    <div className="mt-8 bg-gradient-to-r from-green-50 to-emerald-50 rounded-xl p-6 max-w-4xl mx-auto">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-green-100 rounded-lg">
          <TrendingDown className="w-6 h-6 text-green-600" />
        </div>
        <h3 className="text-xl font-bold text-green-800">
          月間コストを{savingsPercentage.toFixed(1)}%削減
        </h3>
      </div>
      <div className="space-y-4">
        <p className="text-green-700">
          Boost Hackを使用することで、月間{PRODUCTS_PER_MONTH.toLocaleString()}商品の出品作業にかかるコストを
          <span className="font-bold">¥{savings.toLocaleString()}</span> 削減できます。
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white bg-opacity-50 rounded-lg p-4">
          <div>
            <p className="text-sm text-gray-600">作業時間の削減</p>
            <p className="text-lg font-medium text-gray-900">
              {(manualTotalHours - boostTotalHours).toFixed(1)}時間/月
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-600">コストの削減</p>
            <p className="text-lg font-medium text-gray-900">
              ¥{savings.toLocaleString()}/月
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}