import React, { useState } from 'react';
import { X, Save } from 'lucide-react';
import { motion } from 'framer-motion';
import type { ProductTemplate } from '../../utils/templates/productTemplates';
import { CATEGORIES } from '../../utils/categories';
import { useDescriptionTemplateStore } from '../../stores/descriptionTemplateStore';

interface TemplateContentEditorProps {
  template: ProductTemplate;
  onSave: (template: ProductTemplate) => void;
  onClose: () => void;
}

export function TemplateContentEditor({ template, onSave, onClose }: TemplateContentEditorProps) {
  const [editedTemplate, setEditedTemplate] = useState<ProductTemplate>({ ...template });
  const { templates: descriptionTemplates } = useDescriptionTemplateStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    setEditedTemplate(prev => ({
      ...prev,
      fields: {
        ...prev.fields,
        [name]: type === 'number' ? Number(value) : value
      }
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setEditedTemplate(prev => ({
      ...prev,
      fields: {
        ...prev.fields,
        [name]: checked
      }
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(editedTemplate);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] flex flex-col"
      >
        <div className="p-6 border-b flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            テンプレートの内容を編集: {template.name}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto">
          <form onSubmit={handleSubmit} className="p-6 space-y-8">
            {/* Basic Settings */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  カテゴリ
                </label>
                <select
                  name="category"
                  value={editedTemplate.fields.category}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                >
                  <option value="">選択してください</option>
                  {CATEGORIES.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  商品の状態
                </label>
                <select
                  name="condition"
                  value={editedTemplate.fields.condition}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                >
                  <option value="">選択してください</option>
                  <option value="new">未使用</option>
                  <option value="like-new">未使用に近い</option>
                  <option value="good">目立った傷や汚れなし</option>
                  <option value="fair">やや傷や汚れあり</option>
                  <option value="poor">傷や汚れあり</option>
                  <option value="bad">全体的に状態が悪い</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  開始価格
                </label>
                <input
                  type="number"
                  name="startPrice"
                  value={editedTemplate.fields.startPrice}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                  min="0"
                />
              </div>

              {editedTemplate.fields.listingType === 'auction' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    即決価格
                  </label>
                  <input
                    type="number"
                    name="buyItNowPrice"
                    value={editedTemplate.fields.buyItNowPrice || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                    min="0"
                  />
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  出品形式
                </label>
                <select
                  name="listingType"
                  value={editedTemplate.fields.listingType}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                >
                  <option value="auction">オークション</option>
                  <option value="fixed">定額</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  商品説明テンプレート
                </label>
                <select
                  name="descriptionTemplateId"
                  value={editedTemplate.fields.descriptionTemplateId || ''}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                >
                  <option value="">選択してください</option>
                  {descriptionTemplates.map(template => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Item Numbers */}
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-700">管理番号設定</h4>
              <div className="grid grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">管理番号1</label>
                    <input
                      type="text"
                      name="itemNumber"
                      value={editedTemplate.fields.itemNumber}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                      placeholder="管理番号1を入力"
                    />
                  </div>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="itemNumberPrefix"
                        checked={editedTemplate.fields.itemNumberPrefix}
                        onChange={handleCheckboxChange}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">文頭</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="itemNumberSuffix"
                        checked={editedTemplate.fields.itemNumberSuffix}
                        onChange={handleCheckboxChange}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">文尾</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="itemNumberSerial"
                        checked={editedTemplate.fields.itemNumberSerial}
                        onChange={handleCheckboxChange}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">連番</span>
                    </label>
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">管理番号2</label>
                    <input
                      type="text"
                      name="itemNumber2"
                      value={editedTemplate.fields.itemNumber2}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                      placeholder="管理番号2を入力"
                    />
                  </div>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="memoPrefix"
                        checked={editedTemplate.fields.memoPrefix}
                        onChange={handleCheckboxChange}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">文頭</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="memoSuffix"
                        checked={editedTemplate.fields.memoSuffix}
                        onChange={handleCheckboxChange}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">文尾</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="memoSerial"
                        checked={editedTemplate.fields.memoSerial}
                        onChange={handleCheckboxChange}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">連番</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* Shipping Settings */}
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-700">送料設定オプション</h4>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">送料負担</label>
                  <select
                    name="shippingResponsibility"
                    value={editedTemplate.fields.shippingResponsibility}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                  >
                    <option value="">選択してください</option>
                    <option value="buyer">落札者</option>
                    <option value="seller">出品者</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">発送までの日数</label>
                  <select
                    name="shippingTime"
                    value={editedTemplate.fields.shippingTime}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                  >
                    <option value="">選択してください</option>
                    <option value="1-2">1～2日</option>
                    <option value="2-3">2～3日</option>
                    <option value="3-7">3～7日</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="shippingLater"
                      checked={editedTemplate.fields.shippingLater}
                      onChange={handleCheckboxChange}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">落札後に送料を連絡する</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="shippingCOD"
                      checked={editedTemplate.fields.shippingCOD}
                      onChange={handleCheckboxChange}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">着払いにする</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Basic Settings */}
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-700">基本設定</h4>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">個数</label>
                  <input
                    type="number"
                    name="quantity"
                    value={editedTemplate.fields.quantity}
                    onChange={handleChange}
                    min="1"
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">開催期間</label>
                  <select
                    name="auctionDuration"
                    value={editedTemplate.fields.auctionDuration}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                  >
                    {[1, 2, 3, 4, 5, 6, 7].map(days => (
                      <option key={days} value={days}>{days}日</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">終了時間</label>
                  <select
                    name="endTime"
                    value={editedTemplate.fields.endTime}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                  >
                    {Array.from({ length: 24 }, (_, i) => (
                      <option key={i} value={i}>{i}時</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Description */}
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-700">商品説明</h4>
              <textarea
                name="description"
                value={editedTemplate.fields.description}
                onChange={handleChange}
                rows={10}
                className="block w-full rounded-md border-gray-300 bg-gray-50 font-mono text-sm"
                placeholder="商品説明を入力"
              />
            </div>

            {/* Auto Extension Settings */}
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-700">自動再出品設定</h4>
              <div className="space-y-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="autoExtension"
                    checked={editedTemplate.fields.autoExtension}
                    onChange={handleCheckboxChange}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">自動再出品を有効にする</span>
                </label>

                {editedTemplate.fields.autoExtension && (
                  <div className="grid grid-cols-2 gap-4 pl-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">値下げ率</label>
                      <select
                        name="autoExtensionRate"
                        value={editedTemplate.fields.autoExtensionRate}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                      >
                        {[5, 10, 15, 20, 25, 30].map(rate => (
                          <option key={rate} value={rate}>{rate}%</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">再出品回数</label>
                      <select
                        name="autoExtensionCount"
                        value={editedTemplate.fields.autoExtensionCount}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
                      >
                        {[1, 2, 3].map(count => (
                          <option key={count} value={count}>{count}回</option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Auction Options */}
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-700">オプション設定</h4>
              <div className="space-y-3">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="ratingRestriction"
                    checked={editedTemplate.fields.ratingRestriction}
                    onChange={handleCheckboxChange}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">総合評価で入札を制限する</span>
                </label>
                
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="badRatingRestriction"
                    checked={editedTemplate.fields.badRatingRestriction}
                    onChange={handleCheckboxChange}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">悪い評価の割合で入札を制限する</span>
                </label>
                
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="bidderAuthentication"
                    checked={editedTemplate.fields.bidderAuthentication}
                    onChange={handleCheckboxChange}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">入札者認証制限</span>
                </label>

                {editedTemplate.fields.listingType === 'auction' && (
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="autoExtensionByBid"
                      checked={editedTemplate.fields.autoExtensionByBid}
                      onChange={handleCheckboxChange}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">入札による自動延長</span>
                  </label>
                )}
                
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="returnsAccepted"
                    checked={editedTemplate.fields.returnsAccepted}
                    onChange={handleCheckboxChange}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">返品を受け付ける</span>
                </label>
              </div>
            </div>

            <div className="flex justify-end gap-3 pt-4 border-t">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
              >
                キャンセル
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                保存
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </div>
  );
}