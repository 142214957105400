import React, { useState, useEffect } from 'react';
import { Settings } from 'lucide-react';
import { useDescriptionTemplateStore } from '../stores/descriptionTemplateStore';
import { DescriptionTemplateEditor } from './templates/description/DescriptionTemplateEditor';
import type { Product } from '../types';

interface DescriptionInputProps {
  description: string;
  condition: string;
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onTemplateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  useTemplate: boolean;
}

export function DescriptionInput({
  description,
  condition,
  product,
  onChange,
  onTemplateChange,
  useTemplate
}: DescriptionInputProps) {
  const [showTemplateEditor, setShowTemplateEditor] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [localDescription, setLocalDescription] = useState(description);
  const { 
    templates, 
    activeTemplateId, 
    setActiveTemplate, 
    getTemplateByCondition 
  } = useDescriptionTemplateStore();

  // Update local description when prop changes
  useEffect(() => {
    setLocalDescription(description);
    setIsDirty(false);
  }, [description]);

  // Update description when condition changes and template is in use
  useEffect(() => {
    if (useTemplate && condition) {
      // If product has a saved template ID, use that template
      const templateId = product.descriptionTemplateId || activeTemplateId;
      const template = templateId ? 
        templates.find(t => t.id === templateId)?.contents[condition] :
        getTemplateByCondition(condition);

      if (template) {
        setLocalDescription(template);
        onChange({
          target: { name: 'description', value: template }
        } as React.ChangeEvent<HTMLTextAreaElement>);

        // Update template ID in product if it's different
        if (templateId !== product.descriptionTemplateId) {
          onChange({
            target: { name: 'descriptionTemplateId', value: templateId }
          } as React.ChangeEvent<HTMLInputElement>);
        }
      }
    }
  }, [useTemplate, condition, activeTemplateId, product.descriptionTemplateId]);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalDescription(e.target.value);
    setIsDirty(true);
  };

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const templateId = e.target.value || null;
    
    // Save template ID to product
    onChange({
      target: { name: 'descriptionTemplateId', value: templateId }
    } as React.ChangeEvent<HTMLInputElement>);

    // Apply template content if condition is set
    if (templateId && condition) {
      const template = templates.find(t => t.id === templateId)?.contents[condition];
      if (template) {
        setLocalDescription(template);
        onChange({
          target: { name: 'description', value: template }
        } as React.ChangeEvent<HTMLTextAreaElement>);
      }
    }
  };

  const handleSave = () => {
    if (isDirty) {
      onChange({
        target: { name: 'description', value: localDescription }
      } as React.ChangeEvent<HTMLTextAreaElement>);
      setIsDirty(false);
    }
  };

  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <div className="flex items-center justify-between mb-4">
        <h4 className="font-medium">商品説明</h4>
        <div className="flex items-center gap-4">
          <button
            onClick={() => setShowTemplateEditor(true)}
            className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
          >
            <Settings className="w-4 h-4" />
            <span>テンプレートを編集</span>
          </button>
          <div className="flex items-center gap-4">
            <select
              value={product.descriptionTemplateId || ''}
              onChange={handleTemplateChange}
              className="block w-48 rounded-md border-gray-300 text-sm"
              disabled={!useTemplate}
            >
              <option value="">テンプレートを選択...</option>
              {templates.map(template => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={useTemplate}
                onChange={onTemplateChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">状態に合わせたテンプレを使用</span>
            </label>
          </div>
        </div>
      </div>
      <textarea
        name="description"
        value={localDescription}
        onChange={handleDescriptionChange}
        rows={12}
        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 font-mono text-sm"
        placeholder="商品の説明を入力してください"
        disabled={useTemplate && condition !== ''}
      />

      {isDirty && (
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSave}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            保存
          </button>
        </div>
      )}

      {showTemplateEditor && (
        <DescriptionTemplateEditor
          onClose={() => setShowTemplateEditor(false)}
        />
      )}
    </div>
  );
}