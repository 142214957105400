import React, { useState } from 'react';
import { X, Save, Trash2 } from 'lucide-react';
import { useDescriptionTemplateStore } from '../../../stores/descriptionTemplateStore';
import type { DescriptionTemplate } from '../../../types/descriptionTemplate';

interface DescriptionTemplateEditorProps {
  onClose: () => void;
}

const CONDITIONS = [
  { value: 'new', label: '未使用' },
  { value: 'like-new', label: '未使用に近い' },
  { value: 'good', label: '目立った傷や汚れなし' },
  { value: 'fair', label: 'やや傷や汚れあり' },
  { value: 'poor', label: '傷や汚れあり' },
  { value: 'bad', label: '全体的に状態が悪い' }
] as const;

export function DescriptionTemplateEditor({ onClose }: DescriptionTemplateEditorProps) {
  const { templates, addTemplate, updateTemplate, deleteTemplate } = useDescriptionTemplateStore();
  const [name, setName] = useState('');
  const [contents, setContents] = useState<DescriptionTemplate['contents']>({
    new: '',
    'like-new': '',
    good: '',
    fair: '',
    poor: '',
    bad: ''
  });
  const [editingTemplate, setEditingTemplate] = useState<string | null>(null);
  const [activeCondition, setActiveCondition] = useState<keyof DescriptionTemplate['contents']>('new');

  const handleSave = () => {
    if (!name || Object.values(contents).every(content => !content)) return;

    if (editingTemplate) {
      updateTemplate(editingTemplate, { name, contents });
    } else {
      addTemplate(name, contents);
    }

    setName('');
    setContents({
      new: '',
      'like-new': '',
      good: '',
      fair: '',
      poor: '',
      bad: ''
    });
    setEditingTemplate(null);
  };

  const handleEdit = (template: DescriptionTemplate) => {
    setName(template.name);
    setContents({ ...template.contents });
    setEditingTemplate(template.id);
  };

  const handleDelete = (id: string) => {
    deleteTemplate(id);
    if (editingTemplate === id) {
      setName('');
      setContents({
        new: '',
        'like-new': '',
        good: '',
        fair: '',
        poor: '',
        bad: ''
      });
      setEditingTemplate(null);
    }
  };

  const handleContentChange = (condition: keyof DescriptionTemplate['contents'], value: string) => {
    setContents(prev => ({
      ...prev,
      [condition]: value
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-6xl w-full mx-4 max-h-[90vh] flex flex-col">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-medium text-gray-900">商品説明テンプレートを編集</h2>
          <button
            onClick={onClose}
            className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">テンプレート名</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300"
                  placeholder="テンプレート名を入力"
                />
              </div>

              <div className="space-y-4">
                <div className="flex gap-2">
                  {CONDITIONS.map(({ value, label }) => (
                    <button
                      key={value}
                      onClick={() => setActiveCondition(value as keyof DescriptionTemplate['contents'])}
                      className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors ${
                        activeCondition === value
                          ? 'bg-blue-100 text-blue-700'
                          : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      {label}
                    </button>
                  ))}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {CONDITIONS.find(c => c.value === activeCondition)?.label}のテンプレート
                  </label>
                  <textarea
                    value={contents[activeCondition]}
                    onChange={(e) => handleContentChange(activeCondition, e.target.value)}
                    rows={20}
                    className="block w-full rounded-md border-gray-300 font-mono text-sm"
                    placeholder="商品説明のテンプレートを入力"
                  />
                </div>
              </div>

              <div className="flex justify-end gap-3">
                {editingTemplate && (
                  <button
                    onClick={() => {
                      setName('');
                      setContents({
                        new: '',
                        'like-new': '',
                        good: '',
                        fair: '',
                        poor: '',
                        bad: ''
                      });
                      setEditingTemplate(null);
                    }}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                  >
                    キャンセル
                  </button>
                )}
                <button
                  onClick={handleSave}
                  disabled={!name || Object.values(contents).every(content => !content)}
                  className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400"
                >
                  <Save className="w-4 h-4" />
                  <span>保存</span>
                </button>
              </div>
            </div>

            <div className="border-t lg:border-t-0 lg:border-l pt-4 lg:pt-0 lg:pl-6">
              <h3 className="text-sm font-medium text-gray-700 mb-4">保存済みテンプレート</h3>
              <div className="space-y-4">
                {templates.map((template) => (
                  <div
                    key={template.id}
                    className="flex items-center justify-between p-3 rounded-lg border border-gray-200 hover:bg-gray-50"
                  >
                    <div className="min-w-0 flex-1">
                      <h4 className="text-sm font-medium text-gray-900 truncate">
                        {template.name}
                      </h4>
                      <p className="text-xs text-gray-500">
                        最終更新: {new Date(template.updatedAt).toLocaleString()}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 ml-4">
                      <button
                        onClick={() => handleEdit(template)}
                        className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
                      >
                        <Save className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(template.id)}
                        className="p-1 text-red-400 hover:text-red-600 rounded-full hover:bg-red-50"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}

                {templates.length === 0 && (
                  <p className="text-sm text-gray-500 text-center py-4">
                    保存されたテンプレートはありません
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}