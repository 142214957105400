import React, { useState } from 'react';
import { User, CreditCard, Bell, Shield, LogOut } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import { AccountSection } from './AccountSection';
import { NotificationSettings } from './NotificationSettings';
import { SecuritySettings } from './SecuritySettings';
import { PlanDetails } from './PlanDetails';
import { DeleteAccountModal } from './DeleteAccountModal';

export function AccountPage() {
  const user = useAuthStore(state => state.user);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">マイアカウント</h1>
          
          <div className="space-y-6">
            {/* Basic Info */}
            <AccountSection
              icon={<User className="w-6 h-6 text-blue-600" />}
              title="基本情報"
            >
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-500">メールアドレス</label>
                  <p className="mt-1 text-sm text-gray-900">{user.email}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-500">登録日</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {new Date(user.createdAt).toLocaleDateString('ja-JP')}
                  </p>
                </div>
              </div>
            </AccountSection>

            {/* Plan Details */}
            <AccountSection
              icon={<CreditCard className="w-6 h-6 text-green-600" />}
              title="プラン情報"
            >
              <PlanDetails />
            </AccountSection>

            {/* Notification Settings */}
            <AccountSection
              icon={<Bell className="w-6 h-6 text-amber-600" />}
              title="通知設定"
            >
              <NotificationSettings />
            </AccountSection>

            {/* Security Settings */}
            <AccountSection
              icon={<Shield className="w-6 h-6 text-purple-600" />}
              title="セキュリティ設定"
            >
              <SecuritySettings />
            </AccountSection>

            {/* Account Deletion */}
            <AccountSection
              icon={<LogOut className="w-6 h-6 text-red-600" />}
              title="退会"
            >
              <div className="space-y-4">
                <p className="text-sm text-gray-600">
                  退会すると、すべてのデータが完全に削除され、この操作は取り消すことができません。
                </p>
                <button
                  onClick={() => setShowDeleteModal(true)}
                  className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-red-600 bg-red-50 hover:bg-red-100 rounded-md transition-colors"
                >
                  <LogOut className="w-4 h-4" />
                  退会する
                </button>
              </div>
            </AccountSection>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteAccountModal onClose={() => setShowDeleteModal(false)} />
      )}
    </div>
  );
}