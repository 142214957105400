import { STORES, INDEXES } from '../config';
import { executeTransaction } from './baseOperations';
import type { ProductTemplate } from '../../utils/templates/productTemplates';
import { useAuthStore } from '../../stores/authStore';

export async function saveTemplate(template: ProductTemplate): Promise<void> {
  const user = useAuthStore.getState().user;
  if (!user) throw new Error('User not authenticated');

  return executeTransaction(STORES.TEMPLATES, 'readwrite', async (tx) => {
    await tx.objectStore(STORES.TEMPLATES).put({
      ...template,
      userId: user.email,
      updatedAt: new Date().toISOString()
    });
  });
}

export async function getTemplates(): Promise<ProductTemplate[]> {
  const user = useAuthStore.getState().user;
  if (!user) return [];

  return executeTransaction(STORES.TEMPLATES, 'readonly', async (tx) => {
    const templates = await tx.objectStore(STORES.TEMPLATES)
      .index(INDEXES.USER_ID)
      .getAll(user.email);
    
    return templates.sort((a, b) => 
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  });
}

export async function deleteTemplate(id: string): Promise<void> {
  return executeTransaction(STORES.TEMPLATES, 'readwrite', async (tx) => {
    await tx.objectStore(STORES.TEMPLATES).delete(id);
  });
}

export async function getTemplateById(id: string): Promise<ProductTemplate | undefined> {
  return executeTransaction(STORES.TEMPLATES, 'readonly', async (tx) => {
    return tx.objectStore(STORES.TEMPLATES).get(id);
  });
}

export async function replaceAllTemplates(templates: ProductTemplate[]): Promise<void> {
  const user = useAuthStore.getState().user;
  if (!user) throw new Error('User not authenticated');

  return executeTransaction(STORES.TEMPLATES, 'readwrite', async (tx) => {
    const store = tx.objectStore(STORES.TEMPLATES);
    
    // Delete all existing templates for the user
    const existingKeys = await store.index(INDEXES.USER_ID).getAllKeys(user.email);
    await Promise.all(existingKeys.map(key => store.delete(key)));
    
    // Add new templates
    await Promise.all(templates.map(template => 
      store.add({
        ...template,
        userId: user.email,
        updatedAt: new Date().toISOString()
      })
    ));
  });
}