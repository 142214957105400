import React, { useState } from 'react';
import { TwoFactorAuthSetup } from './security/TwoFactorAuthSetup';

export function SecuritySettings() {
  const [showTwoFactorSetup, setShowTwoFactorSetup] = useState(false);
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-sm font-medium text-gray-900 mb-4">パスワードの変更</h3>
        <form className="space-y-4">
          <div>
            <label className="block text-sm text-gray-700">現在のパスワード</label>
            <input
              type="password"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-700">新しいパスワード</label>
            <input
              type="password"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-700">新しいパスワード（確認）</label>
            <input
              type="password"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              パスワードを変更
            </button>
          </div>
        </form>
      </div>

      <div className="pt-6 border-t">
        <h3 className="text-sm font-medium text-gray-900 mb-4">二段階認証</h3>
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-gray-600">
              {isTwoFactorEnabled
                ? '二段階認証が有効です'
                : 'アカウントの安全性を高めるため、二段階認証の設定を推奨します'}
            </p>
            {isTwoFactorEnabled && (
              <p className="text-xs text-gray-500 mt-1">
                最終更新: 2024年3月15日
              </p>
            )}
          </div>
          <button
            onClick={() => setShowTwoFactorSetup(true)}
            className={`inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md transition-colors ${
              isTwoFactorEnabled
                ? 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                : 'border-transparent text-white bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {isTwoFactorEnabled ? '設定を変更' : '設定する'}
          </button>
        </div>
      </div>

      {showTwoFactorSetup && (
        <TwoFactorAuthSetup
          onClose={() => {
            setShowTwoFactorSetup(false);
            setIsTwoFactorEnabled(true);
          }}
        />
      )}
    </div>
  );
}