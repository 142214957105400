import React from 'react';

export function Logo() {
  return (
    <div>
      <div className="flex justify-center">
        <div className="w-12 h-12 transform -rotate-12">
          <svg viewBox="0 0 24 24" className="w-full h-full filter drop-shadow-lg">
            <path
              d="M13 10V3L4 14h7v7l9-11h-7z"
              fill="url(#boost-gradient)"
              className="filter drop-shadow-md"
            />
            <defs>
              <linearGradient id="boost-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#60A5FA' }} />
                <stop offset="100%" style={{ stopColor: '#F59E0B' }} />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Boost Hack
      </h2>
      <p className="mt-2 text-center text-sm text-gray-600">
        ログインしてください
      </p>
    </div>
  );
}