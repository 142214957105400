import React, { useState } from 'react';
import { formatDate } from '../../../utils/dateUtils';
import { MemberStatus } from './MemberStatus';
import { AICreditsEditor } from './credits/AICreditsEditor';
import { PaymentHistoryModal } from './PaymentHistoryModal';
import { MemberDeleteModal } from './MemberDeleteModal';
import { CancelDeletionModal } from './CancelDeletionModal';
import { Trash2, RotateCcw, User, CreditCard, Calendar, Sparkles, Hash, Coins } from 'lucide-react';
import type { Member } from '../../../types/member';

interface MemberListItemProps {
  member: Member;
  onUpdateMember: (memberId: string, updates: Partial<Member>) => void;
  onDeleteMember?: (memberId: string) => void;
  onCancelDeletion?: (memberId: string) => void;
  isSelected?: boolean;
  onSelect?: (selected: boolean) => void;
  startDate?: string;
  endDate?: string;
}

export function MemberListItem({ 
  member, 
  onUpdateMember,
  onDeleteMember,
  onCancelDeletion,
  isSelected = false,
  onSelect,
  startDate,
  endDate
}: MemberListItemProps) {
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleUpdateCredits = (memberId: string, newCredits: number) => {
    onUpdateMember(memberId, { aiCredits: newCredits });
  };

  const handleDelete = () => {
    if (onDeleteMember) {
      onDeleteMember(member.id);
    }
    setShowDeleteModal(false);
  };

  const handleCancelDeletion = () => {
    if (onCancelDeletion) {
      onCancelDeletion(member.id);
    }
    setShowCancelModal(false);
  };

  const isDeletionScheduled = member.status === 'suspended' && member.scheduledDeletionTime;
  const remainingTime = isDeletionScheduled 
    ? new Date(member.scheduledDeletionTime).getTime() - Date.now()
    : 0;
  const remainingHours = Math.max(0, Math.floor(remainingTime / (1000 * 60 * 60)));
  const remainingMinutes = Math.max(0, Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)));

  return (
    <>
      {/* Desktop View */}
      <div className="hidden lg:grid lg:grid-cols-8 gap-4 px-6 py-4 hover:bg-gray-50">
        <div className="flex items-center gap-3">
          {onSelect && (
            <input
              type="checkbox"
              checked={isSelected}
              onChange={(e) => onSelect(e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
          )}
          <div className="text-sm text-gray-900">{member.id}</div>
        </div>
        <div className="text-sm text-gray-500 -ml-[50px]">{member.email}</div>
        <div className="text-sm text-gray-900">{member.plan}</div>
        <div className="text-sm text-gray-500">{formatDate(member.registeredAt)}</div>
        <div>
          <AICreditsEditor 
            member={member}
            onUpdate={(newCredits) => handleUpdateCredits(member.id, newCredits)}
          />
        </div>
        <div className="text-sm text-gray-500">
          {member.referralCode || '-'}
        </div>
        <div>
          <button
            onClick={() => setShowPaymentHistory(true)}
            className="text-sm font-medium text-gray-900 hover:text-blue-600"
          >
            ¥{member.salesAmount.toLocaleString()}
          </button>
        </div>
        <div className="flex items-center justify-between">
          <MemberStatus status={member.status} />
          {isDeletionScheduled ? (
            <div className="flex items-center gap-2">
              <span className="text-xs text-red-600">
                {remainingHours}時間{remainingMinutes}分後に削除
              </span>
              <button
                onClick={() => setShowCancelModal(true)}
                className="p-1 text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-full transition-colors"
                title="削除をキャンセル"
              >
                <RotateCcw className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <button
              onClick={() => setShowDeleteModal(true)}
              className="p-1 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors"
              title="強制退会"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          )}
        </div>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden p-4 hover:bg-gray-50 space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {onSelect && (
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(e) => onSelect(e.target.checked)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            )}
            <div>
              <div className="flex items-center gap-2 mb-1">
                <User className="w-4 h-4 text-gray-400" />
                <span className="text-sm font-medium text-gray-900">{member.id}</span>
              </div>
              <div className="text-sm text-gray-500">{member.email}</div>
            </div>
          </div>
          <MemberStatus status={member.status} />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
              <CreditCard className="w-4 h-4" />
              <span>プラン</span>
            </div>
            <span className="text-sm font-medium text-gray-900">{member.plan}</span>
          </div>
          <div>
            <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
              <Calendar className="w-4 h-4" />
              <span>登録日</span>
            </div>
            <span className="text-sm text-gray-900">{formatDate(member.registeredAt)}</span>
          </div>
          <div>
            <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
              <Sparkles className="w-4 h-4" />
              <span>AIクレジット</span>
            </div>
            <AICreditsEditor 
              member={member}
              onUpdate={(newCredits) => handleUpdateCredits(member.id, newCredits)}
            />
          </div>
          <div>
            <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
              <Hash className="w-4 h-4" />
              <span>紹介者コード</span>
            </div>
            <span className="text-sm text-gray-900">{member.referralCode || '-'}</span>
          </div>
          <div>
            <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
              <Coins className="w-4 h-4" />
              <span>売上金</span>
            </div>
            <button
              onClick={() => setShowPaymentHistory(true)}
              className="text-sm font-medium text-gray-900 hover:text-blue-600"
            >
              ¥{member.salesAmount.toLocaleString()}
            </button>
          </div>
        </div>

        {isDeletionScheduled ? (
          <div className="flex items-center justify-between pt-2 border-t">
            <span className="text-xs text-red-600">
              {remainingHours}時間{remainingMinutes}分後に削除
            </span>
            <button
              onClick={() => setShowCancelModal(true)}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
            >
              <RotateCcw className="w-4 h-4" />
              <span>取り消し</span>
            </button>
          </div>
        ) : (
          <div className="flex justify-end pt-2 border-t">
            <button
              onClick={() => setShowDeleteModal(true)}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors"
            >
              <Trash2 className="w-4 h-4" />
              <span>強制退会</span>
            </button>
          </div>
        )}
      </div>

      {showPaymentHistory && (
        <PaymentHistoryModal
          member={member}
          onClose={() => setShowPaymentHistory(false)}
          startDate={startDate}
          endDate={endDate}
        />
      )}

      {showDeleteModal && (
        <MemberDeleteModal
          member={member}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}

      {showCancelModal && (
        <CancelDeletionModal
          member={member}
          onConfirm={handleCancelDeletion}
          onCancel={() => setShowCancelModal(false)}
        />
      )}
    </>
  );
}