import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { DescriptionTemplateStore } from '../types/descriptionTemplate';

const DEFAULT_TEMPLATE_CONTENTS = {
  new: '',
  'like-new': '',
  good: '',
  fair: '',
  poor: '',
  bad: ''
};

export const useDescriptionTemplateStore = create<DescriptionTemplateStore>()(
  persist(
    (set, get) => ({
      templates: [],
      activeTemplateId: null,

      addTemplate: (name, contents) => {
        const template = {
          id: crypto.randomUUID(),
          name,
          contents: { ...contents },
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        set(state => ({
          templates: [template, ...state.templates],
          activeTemplateId: template.id
        }));
      },

      updateTemplate: (id, updates) => {
        set(state => ({
          templates: state.templates.map(template =>
            template.id === id
              ? {
                  ...template,
                  ...updates,
                  updatedAt: new Date().toISOString()
                }
              : template
          )
        }));
      },

      deleteTemplate: (id) => {
        set(state => ({
          templates: state.templates.filter(t => t.id !== id),
          activeTemplateId: state.activeTemplateId === id ? null : state.activeTemplateId
        }));
      },

      setActiveTemplate: (id) => {
        set({ activeTemplateId: id });
      },

      getTemplateByCondition: (condition) => {
        const { templates, activeTemplateId } = get();
        const activeTemplate = templates.find(t => t.id === activeTemplateId);
        if (!activeTemplate) return '';
        
        return activeTemplate.contents[condition as keyof typeof DEFAULT_TEMPLATE_CONTENTS] || '';
      },

      replaceTemplates: (templates) => {
        set({ 
          templates,
          activeTemplateId: null
        });
      }
    }),
    {
      name: 'description-templates-storage'
    }
  )
);