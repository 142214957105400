import React, { useState, useCallback } from 'react';
import { Trash2, Sparkles } from 'lucide-react';
import { MemberListHeader } from './MemberListHeader';
import { MemberListItem } from './MemberListItem';
import { MemberDetailsModal } from './MemberDetailsModal';
import { AICreditsCompensation } from './credits/AICreditsCompensation';
import { PaymentHistoryDownload } from './PaymentHistoryDownload';
import { DateRangeSelector } from './DateRangeSelector';
import { useMemberStore } from '../../../stores/memberStore';
import { calculateCompensation } from '../../../utils/aiCredits/compensation';
import type { Member } from '../../../types/member';

export function MemberList() {
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [showCompensation, setShowCompensation] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  
  const { members, updateMember, deleteMember, cancelDeletion } = useMemberStore();

  const handleSelectAll = useCallback((selected: boolean) => {
    if (selected) {
      setSelectedMembers(new Set(members.map(m => m.id)));
    } else {
      setSelectedMembers(new Set());
    }
  }, [members]);

  const handleSelectMember = useCallback((memberId: string, selected: boolean) => {
    setSelectedMembers(prev => {
      const newSet = new Set(prev);
      if (selected) {
        newSet.add(memberId);
      } else {
        newSet.delete(memberId);
      }
      return newSet;
    });
  }, []);

  const handleCompensate = async (amount: number) => {
    Array.from(selectedMembers).forEach(memberId => {
      const member = members.find(m => m.id === memberId);
      if (member) {
        const result = calculateCompensation(member, amount, true);
        updateMember(memberId, { aiCredits: result.newCredits });
      }
    });
    setShowCompensation(false);
    setSelectedMembers(new Set());
  };

  const handleDateRangeChange = (newStartDate: string, newEndDate: string) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleDeleteMember = (memberId: string) => {
    const deletionTime = new Date(Date.now() + 24 * 60 * 60 * 1000); // 24 hours from now
    updateMember(memberId, { 
      status: 'suspended',
      scheduledDeletionTime: deletionTime.toISOString()
    });

    // Schedule actual deletion
    const timeoutId = setTimeout(() => {
      const member = members.find(m => m.id === memberId);
      if (member?.scheduledDeletionTime === deletionTime.toISOString()) {
        deleteMember(memberId);
      }
    }, 24 * 60 * 60 * 1000);

    // Store timeout ID for potential cancellation
    window.memberDeletionTimeouts = window.memberDeletionTimeouts || {};
    window.memberDeletionTimeouts[memberId] = timeoutId;
  };

  const handleCancelDeletion = (memberId: string) => {
    // Clear deletion timeout
    if (window.memberDeletionTimeouts?.[memberId]) {
      clearTimeout(window.memberDeletionTimeouts[memberId]);
      delete window.memberDeletionTimeouts[memberId];
    }

    // Update member status
    updateMember(memberId, {
      status: 'active',
      scheduledDeletionTime: undefined
    });
  };

  const selectedMembersList = members.filter(m => selectedMembers.has(m.id));

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-8">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={selectedMembers.size === members.length}
                onChange={(e) => handleSelectAll(e.target.checked)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">
                全て選択 ({selectedMembers.size}/{members.length})
              </span>
            </label>
            <DateRangeSelector
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRangeChange}
            />
          </div>
          {selectedMembers.size > 0 && (
            <div className="flex items-center gap-4">
              <PaymentHistoryDownload 
                selectedMembers={selectedMembersList}
                startDate={startDate}
                endDate={endDate}
              />
              <button
                onClick={() => setShowCompensation(true)}
                className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                <Sparkles className="w-4 h-4" />
                <span>AIクレジットを補償</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <MemberListHeader />
      <div className="divide-y divide-gray-200">
        {members.map(member => (
          <MemberListItem
            key={member.id}
            member={member}
            isSelected={selectedMembers.has(member.id)}
            onSelect={(checked) => handleSelectMember(member.id, checked)}
            onUpdateMember={updateMember}
            onDeleteMember={handleDeleteMember}
            onCancelDeletion={handleCancelDeletion}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
      </div>

      {selectedMember && (
        <MemberDetailsModal
          member={selectedMember}
          onClose={() => setSelectedMember(null)}
          onCompensate={(amount) => {
            handleCompensate(amount);
            setSelectedMember(null);
          }}
        />
      )}

      {showCompensation && (
        <AICreditsCompensation
          selectedCount={selectedMembers.size}
          onCompensate={handleCompensate}
          onClose={() => setShowCompensation(false)}
        />
      )}
    </div>
  );
}