import React, { useState } from 'react';
import { X, Plus } from 'lucide-react';
import { motion } from 'framer-motion';
import { ShippingMethodButton } from '../shipping/ShippingMethodButton';
import { CustomShippingMethod } from '../shipping/CustomShippingMethod';
import { ShippingOptions } from '../shipping/ShippingOptions';

interface ShippingMethodModalProps {
  selectedMethods: string[];
  customMethods: any[];
  shippingResponsibility?: string;
  shippingLater?: boolean;
  shippingCOD?: boolean;
  nekoEazySize?: string;
  nekoEazyWeight?: string;
  yuPackSize?: string;
  yuPackWeight?: string;
  onSelect: (methods: string[], customMethods: any[], options?: {
    shippingResponsibility?: string;
    shippingLater?: boolean;
    shippingCOD?: boolean;
    nekoEazySize?: string;
    nekoEazyWeight?: string;
    yuPackSize?: string;
    yuPackWeight?: string;
  }) => void;
  onClose: () => void;
}

export function ShippingMethodModal({ 
  selectedMethods = [], 
  customMethods = [],
  shippingResponsibility = 'buyer',
  shippingLater = false,
  shippingCOD = false,
  nekoEazySize = '',
  nekoEazyWeight = '',
  yuPackSize = '',
  yuPackWeight = '',
  onSelect, 
  onClose 
}: ShippingMethodModalProps) {
  const [localSelectedMethods, setLocalSelectedMethods] = useState<string[]>(selectedMethods);
  const [localCustomMethods, setLocalCustomMethods] = useState<any[]>(customMethods);
  const [localResponsibility, setLocalResponsibility] = useState(shippingResponsibility);
  const [localLater, setLocalLater] = useState(shippingLater);
  const [localCOD, setLocalCOD] = useState(shippingCOD);
  const [localNekoEazySize, setLocalNekoEazySize] = useState(nekoEazySize);
  const [localNekoEazyWeight, setLocalNekoEazyWeight] = useState(nekoEazyWeight);
  const [localYuPackSize, setLocalYuPackSize] = useState(yuPackSize);
  const [localYuPackWeight, setLocalYuPackWeight] = useState(yuPackWeight);

  const handleMethodToggle = (method: string) => {
    setLocalSelectedMethods(prev => {
      const isSelected = prev.includes(method);
      return isSelected 
        ? prev.filter(m => m !== method)
        : [...prev, method];
    });
  };

  const handleCustomMethodAdd = () => {
    if (localCustomMethods.length >= 10) return;
    setLocalCustomMethods(prev => [
      ...prev,
      {
        name: 'other',
        carrierName: '',
        price: '',
        hokkaido: '',
        okinawa: '',
        island: ''
      }
    ]);
  };

  const handleCustomMethodUpdate = (index: number, updatedMethod: any) => {
    setLocalCustomMethods(prev => {
      const newMethods = [...prev];
      newMethods[index] = updatedMethod;
      return newMethods;
    });
  };

  const handleCustomMethodRemove = (index: number) => {
    setLocalCustomMethods(prev => prev.filter((_, i) => i !== index));
  };

  const handleShippingOptionChange = (name: string, checked: boolean) => {
    if (name === 'shippingLater') {
      setLocalLater(checked);
      if (checked) setLocalCOD(false);
    } else if (name === 'shippingCOD') {
      setLocalCOD(checked);
      if (checked) setLocalLater(false);
    }
  };

  const handleResponsibilityChange = (value: string) => {
    setLocalResponsibility(value);
    if (value === 'seller') {
      setLocalLater(false);
      setLocalCOD(false);
    }
  };

  const handleSave = () => {
    onSelect(
      localSelectedMethods, 
      localCustomMethods,
      {
        shippingResponsibility: localResponsibility,
        shippingLater: localLater,
        shippingCOD: localCOD,
        nekoEazySize: localNekoEazySize,
        nekoEazyWeight: localNekoEazyWeight,
        yuPackSize: localYuPackSize,
        yuPackWeight: localYuPackWeight
      }
    );
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-lg max-w-2xl w-full mx-4 max-h-[90vh] flex flex-col"
      >
        <div className="p-4 border-b flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">配送方法の設定</h3>
          <button
            onClick={onClose}
            className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          <div className="bg-white rounded-lg p-4 border border-gray-200">
            <h4 className="font-medium mb-4">送料設定オプション</h4>
            <ShippingOptions
              shippingLater={localLater}
              shippingCOD={localCOD}
              shippingResponsibility={localResponsibility}
              onChange={handleShippingOptionChange}
              onShippingResponsibilityChange={handleResponsibilityChange}
            />
          </div>

          <div className="space-y-4">
            <h4 className="text-sm font-medium text-gray-700">ヤマト運輸</h4>
            <div className="space-y-2">
              <ShippingMethodButton
                name="nekoposu"
                label="ネコポス"
                price="全国一律 230円"
                checked={localSelectedMethods.includes('nekoposu')}
                onChange={() => handleMethodToggle('nekoposu')}
              />
              <ShippingMethodButton
                name="nekoCompact"
                label="宅急便コンパクト"
                price="480円〜"
                checked={localSelectedMethods.includes('nekoCompact')}
                onChange={() => handleMethodToggle('nekoCompact')}
              />
              <ShippingMethodButton
                name="nekoEazy"
                label="宅急便"
                price="750円〜"
                checked={localSelectedMethods.includes('nekoEazy')}
                onChange={() => handleMethodToggle('nekoEazy')}
                showSizeSelector={true}
                size={localNekoEazySize}
                weight={localNekoEazyWeight}
                onSizeChange={setLocalNekoEazySize}
                onWeightChange={setLocalNekoEazyWeight}
                shippingLater={localLater}
              />
            </div>
          </div>

          <div className="space-y-4">
            <h4 className="text-sm font-medium text-gray-700">日本郵便</h4>
            <div className="space-y-2">
              <ShippingMethodButton
                name="yuPacketMini"
                label="ゆうパケットポストmini"
                price="全国一律 180円"
                checked={localSelectedMethods.includes('yuPacketMini')}
                onChange={() => handleMethodToggle('yuPacketMini')}
              />
              <ShippingMethodButton
                name="yuPacket"
                label="ゆうパケット"
                price="全国一律 230円"
                checked={localSelectedMethods.includes('yuPacket')}
                onChange={() => handleMethodToggle('yuPacket')}
              />
              <ShippingMethodButton
                name="yuPacketPlus"
                label="ゆうパケットプラス"
                price="全国一律 410円"
                checked={localSelectedMethods.includes('yuPacketPlus')}
                onChange={() => handleMethodToggle('yuPacketPlus')}
              />
              <ShippingMethodButton
                name="yuPack"
                label="ゆうパック"
                price="750円〜"
                checked={localSelectedMethods.includes('yuPack')}
                onChange={() => handleMethodToggle('yuPack')}
                showSizeSelector={true}
                size={localYuPackSize}
                weight={localYuPackWeight}
                onSizeChange={setLocalYuPackSize}
                onWeightChange={setLocalYuPackWeight}
                shippingLater={localLater}
              />
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium text-gray-700">その他の配送方法</h4>
              {localCustomMethods.length < 10 && (
                <button
                  onClick={handleCustomMethodAdd}
                  className="flex items-center gap-1 px-3 py-1.5 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
                >
                  <Plus className="w-4 h-4" />
                  <span>配送方法を追加</span>
                </button>
              )}
            </div>
            <div className="space-y-4">
              {localCustomMethods.map((method, index) => (
                <CustomShippingMethod
                  key={index}
                  method={method}
                  onUpdate={(updatedMethod) => handleCustomMethodUpdate(index, updatedMethod)}
                  onRemove={() => handleCustomMethodRemove(index)}
                  showPrices={!localCOD && localResponsibility !== 'seller'}
                  shippingResponsibility={localResponsibility}
                  shippingLater={localLater}
                  shippingCOD={localCOD}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            キャンセル
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            保存
          </button>
        </div>
      </motion.div>
    </div>
  );
}