import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { AiCreditsState } from '../types/aiCredits';
import { scheduleMonthlyReset } from '../utils/aiCredits/resetScheduler';

export const useAiCreditsStore = create<AiCreditsState>()(
  persist(
    (set, get) => ({
      planCredits: 200, // Default for basic plan
      maxPlanCredits: 200,
      chargedCredits: 0,
      maxChargedCredits: 0,
      usageHistory: [],
      lastResetDate: new Date().toISOString(),

      useCredit: async () => {
        const { planCredits, chargedCredits } = get();
        
        // Check if any credits are available
        if (planCredits <= 0 && chargedCredits <= 0) {
          throw new Error('AIクレジットが不足しています');
        }

        // Use charged credits first if plan credits are depleted
        if (planCredits <= 0 && chargedCredits > 0) {
          set(state => ({
            chargedCredits: state.chargedCredits - 1,
            usageHistory: [
              ...state.usageHistory,
              { timestamp: new Date().toISOString(), type: 'charged' }
            ]
          }));
        } else {
          set(state => ({
            planCredits: state.planCredits - 1,
            usageHistory: [
              ...state.usageHistory,
              { timestamp: new Date().toISOString(), type: 'plan' }
            ]
          }));
        }
      },

      addChargedCredits: (amount: number) => {
        set(state => ({
          chargedCredits: state.chargedCredits + amount,
          maxChargedCredits: state.maxChargedCredits + amount
        }));
      },

      setMaxPlanCredits: (amount: number) => {
        set({ maxPlanCredits: amount });
      },

      resetPlanCredits: () => {
        set(state => ({
          planCredits: state.maxPlanCredits,
          lastResetDate: new Date().toISOString()
        }));
      }
    }),
    {
      name: 'ai-credits-storage',
      partialize: (state) => ({
        planCredits: state.planCredits,
        maxPlanCredits: state.maxPlanCredits,
        chargedCredits: state.chargedCredits,
        maxChargedCredits: state.maxChargedCredits,
        usageHistory: state.usageHistory,
        lastResetDate: state.lastResetDate
      }),
      onRehydrateStorage: () => {
        scheduleMonthlyReset();
      }
    }
  )
);