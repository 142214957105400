import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface BulkAIConfirmationProps {
  selectedCount: number;
  onConfirm: () => void;
  onCancel: () => void;
}

export function BulkAIConfirmation({ selectedCount, onConfirm, onCancel }: BulkAIConfirmationProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-lg p-6 max-w-md w-full mx-4"
      >
        <div className="flex items-center gap-3 mb-4">
          <div className="p-2 bg-amber-100 rounded-lg">
            <AlertTriangle className="w-6 h-6 text-amber-600" />
          </div>
          <h3 className="text-lg font-medium text-gray-900">AIタイトル生成の確認</h3>
        </div>

        <div className="space-y-4">
          <p className="text-gray-600">
            選択された{selectedCount}件の商品に対してAIタイトル生成を実行します。
          </p>

          <div className="bg-amber-50 border border-amber-200 rounded-lg p-4">
            <p className="text-sm text-amber-800">
              <span className="font-medium">注意事項：</span>
              <br />
              • 各商品の1～3枚目の画像を使用してタイトルを生成します
              <br />
              • AIクレジットを{selectedCount}回分消費します
              <br />
              • 処理には時間がかかる場合があります
            </p>
          </div>
        </div>

        <div className="flex justify-end gap-3 mt-6">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            キャンセル
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            生成を開始
          </button>
        </div>
      </motion.div>
    </div>
  );
}