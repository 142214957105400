import React, { useState } from 'react';
import { AICreditsEditModal } from './AICreditsEditModal';
import { AICreditsBadge } from './AICreditsBadge';
import type { Member } from '../../../../types/member';

interface AICreditsEditorProps {
  member: Member;
  onUpdate: (newCredits: number) => void;
}

export function AICreditsEditor({ member, onUpdate }: AICreditsEditorProps) {
  const [showEditModal, setShowEditModal] = useState(false);

  const handleUpdate = (newCredits: number) => {
    onUpdate(newCredits);
    setShowEditModal(false);
  };

  return (
    <>
      <button
        onClick={() => setShowEditModal(true)}
        className="group relative hover:opacity-75 transition-opacity"
      >
        <AICreditsBadge current={member.aiCredits} max={member.maxAiCredits} />
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-5 rounded-full transition-colors" />
      </button>

      {showEditModal && (
        <AICreditsEditModal
          member={member}
          onUpdate={handleUpdate}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
}