import { useState } from 'react';
import { validateCreditsEdit } from '../../../../../utils/aiCredits/validation';

export function useAICreditsEdit(initialCredits: number) {
  const [credits, setCredits] = useState(initialCredits.toString());
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCreditsChange = (value: string) => {
    setCredits(value);
    setError(null);
  };

  const handleConfirmChange = (checked: boolean) => {
    setConfirmed(checked);
    setError(null);
  };

  const validate = () => {
    const newCredits = parseInt(credits);
    const validation = validateCreditsEdit(newCredits);

    if (!validation.isValid) {
      setError(validation.error);
      return false;
    }

    if (!confirmed) {
      setError('変更を確認するにはチェックボックスをオンにしてください');
      return false;
    }

    return true;
  };

  return {
    credits,
    confirmed,
    error,
    handleCreditsChange,
    handleConfirmChange,
    validate,
    parsedCredits: parseInt(credits)
  };
}