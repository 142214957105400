import React, { useState } from 'react';
import { X, Save } from 'lucide-react';
import { motion } from 'framer-motion';
import type { ProductTemplate } from '../../utils/templates/productTemplates';
import { CATEGORIES } from '../../utils/categories';

interface TemplateValueEditorProps {
  template: ProductTemplate;
  onSave: (updatedTemplate: ProductTemplate) => void;
  onClose: () => void;
}

export function TemplateValueEditor({ template, onSave, onClose }: TemplateValueEditorProps) {
  const [editedTemplate, setEditedTemplate] = useState<ProductTemplate>({ ...template });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setEditedTemplate(prev => ({
      ...prev,
      fields: {
        ...prev.fields,
        [name]: type === 'number' ? Number(value) : value
      }
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(editedTemplate);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-lg max-w-2xl w-full mx-4"
      >
        <div className="p-6 border-b flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            テンプレートの値を編集: {template.name}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                カテゴリ
              </label>
              <select
                name="category"
                value={editedTemplate.fields.category}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300"
              >
                <option value="">選択してください</option>
                {CATEGORIES.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                商品の状態
              </label>
              <select
                name="condition"
                value={editedTemplate.fields.condition}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300"
              >
                <option value="">選択してください</option>
                <option value="new">未使用</option>
                <option value="like-new">未使用に近い</option>
                <option value="good">目立った傷や汚れなし</option>
                <option value="fair">やや傷や汚れあり</option>
                <option value="poor">傷や汚れあり</option>
                <option value="bad">全体的に状態が悪い</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                開始価格
              </label>
              <input
                type="number"
                name="startPrice"
                value={editedTemplate.fields.startPrice}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300"
                min="0"
              />
            </div>

            {editedTemplate.fields.listingType === 'auction' && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  即決価格
                </label>
                <input
                  type="number"
                  name="buyItNowPrice"
                  value={editedTemplate.fields.buyItNowPrice || ''}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300"
                  min="0"
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700">
                出品形式
              </label>
              <select
                name="listingType"
                value={editedTemplate.fields.listingType}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300"
              >
                <option value="auction">オークション</option>
                <option value="fixed">定額</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                発送までの日数
              </label>
              <select
                name="shippingTime"
                value={editedTemplate.fields.shippingTime}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300"
              >
                <option value="">選択してください</option>
                <option value="1-2">1～2日</option>
                <option value="2-3">2～3日</option>
                <option value="3-7">3～7日</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              キャンセル
            </button>
            <button
              type="submit"
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              <Save className="w-4 h-4" />
              <span>保存</span>
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
}