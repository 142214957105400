import React from 'react';

interface AiCreditsCounterProps {
  remaining: number;
  max: number;
  isLowCredits: boolean;
}

export function AiCreditsCounter({ remaining, max, isLowCredits }: AiCreditsCounterProps) {
  return (
    <div className="flex items-baseline gap-1">
      <span className={`font-bold ${
        isLowCredits ? 'text-red-600' : 'text-gray-900'
      }`}>
        {remaining?.toLocaleString() || '0'}
      </span>
      <span className="text-xs text-gray-400 font-medium">
        /{max?.toLocaleString() || '0'}
      </span>
    </div>
  );
}