import React from 'react';
import { Sparkles } from 'lucide-react';

interface AICreditsBadgeProps {
  current: number;
  max: number;
}

export function AICreditsBadge({ current, max }: AICreditsBadgeProps) {
  const percentage = (current / max) * 100;
  const isLow = percentage < 20;

  return (
    <div className={`inline-flex items-center gap-2 px-3 py-1 rounded-full text-sm ${
      isLow ? 'bg-red-50 text-red-600' : 'bg-purple-50 text-purple-600'
    }`}>
      <Sparkles className="w-4 h-4" />
      <span className="font-medium">{current.toLocaleString()}</span>
      <span className="text-gray-500">/ {max.toLocaleString()}</span>
    </div>
  );
}