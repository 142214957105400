import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../../stores/authStore';
import { validateEmail, validatePassword } from '../../../../utils/auth/validation';
import { sendVerificationEmail } from '../../../../utils/auth/verification';

export function useSignupForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const signup = useAuthStore(state => state.signup);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      // Validate inputs
      if (!validateEmail(email)) {
        throw new Error('有効なメールアドレスを入力してください');
      }

      if (!validatePassword(password)) {
        throw new Error('パスワードは8文字以上で、英数字を含める必要があります');
      }

      if (password !== confirmPassword) {
        throw new Error('パスワードが一致しません');
      }

      // Register user
      await signup({ email, password });

      // Send verification email
      await sendVerificationEmail(email);

      // Redirect to verification page
      navigate('/verify-email', { 
        state: { email },
        replace: true 
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('登録に失敗しました');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    email,
    password,
    confirmPassword,
    error,
    isLoading,
    handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    handleConfirmPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value),
    handleSubmit
  };
}