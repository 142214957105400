import { useState, useEffect } from 'react';
import { useAuthStore } from '../../../../stores/authStore';
import { sendContactForm } from '../../../../utils/help/contact';

export function useContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const user = useAuthStore(state => state.user);

  // Auto-populate member ID and email when component mounts
  useEffect(() => {
    if (user) {
      setName(user.id || ''); // Use member ID as name
      setEmail(user.email || '');
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      await sendContactForm({ name, email, category, message });
      setSuccess('お問い合わせを受け付けました。内容を確認次第、ご連絡させていただきます。');
      // Don't reset name and email since they're auto-populated
      setCategory('');
      setMessage('');
    } catch (err) {
      setError('送信に失敗しました。時間をおいて再度お試しください。');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    name,
    email,
    category,
    message,
    error,
    success,
    isLoading,
    handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value),
    handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    handleCategoryChange: (e: React.ChangeEvent<HTMLSelectElement>) => setCategory(e.target.value),
    handleMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value),
    handleSubmit
  };
}