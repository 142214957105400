import React from 'react';
import { Settings } from 'lucide-react';
import type { ProductTemplate } from '../../utils/templates/productTemplates';

interface TemplateSelectorProps {
  templates: ProductTemplate[];
  selectedTemplate: ProductTemplate | null;
  onSelect: (template: ProductTemplate | null) => void;
  onEdit?: (template: ProductTemplate) => void;
}

export function TemplateSelector({ 
  templates, 
  selectedTemplate, 
  onSelect,
  onEdit
}: TemplateSelectorProps) {
  if (templates.length === 0) {
    return (
      <span className="text-lg text-gray-500">
        保存されたテンプレートはありません
      </span>
    );
  }

  return (
    <div className="flex items-center gap-4">
      <select
        value={selectedTemplate?.id || ''}
        onChange={(e) => {
          const template = templates.find(t => t.id === e.target.value);
          onSelect(template || null);
        }}
        className="flex-1 h-14 px-4 rounded-lg text-lg font-medium border-2 border-indigo-500 
          bg-gradient-to-r from-indigo-50 via-purple-50 to-pink-50
          hover:from-indigo-100 hover:via-purple-100 hover:to-pink-100
          focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500
          transition-all duration-300 cursor-pointer shadow-sm
          hover:shadow-md"
      >
        <option value="" className="text-lg bg-white text-gray-500">
          テンプレートを選択してください
        </option>
        {templates.map(template => (
          <option 
            key={template.id} 
            value={template.id}
            className="text-lg bg-white text-gray-900 py-2"
          >
            {template.name}
          </option>
        ))}
      </select>
      {onEdit && (
        <button
          onClick={() => {
            if (selectedTemplate) {
              onEdit(selectedTemplate);
            } else if (templates.length > 0) {
              // If no template is selected, select and edit the first one
              const firstTemplate = templates[0];
              onSelect(firstTemplate);
              onEdit(firstTemplate);
            }
          }}
          className={`p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors ${
            !selectedTemplate && templates.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          title="テンプレートの値を編集"
          disabled={templates.length === 0}
        >
          <Settings className="w-6 h-6" />
        </button>
      )}
    </div>
  );
}