import React from 'react';
import { KeyRound } from 'lucide-react';
import { usePasswordReset } from './hooks/usePasswordReset';
import { ErrorMessage } from '../login/ErrorMessage';
import { SuccessMessage } from './SuccessMessage';
import { BackToLogin } from './BackToLogin';

export function PasswordResetForm() {
  const {
    email,
    error,
    success,
    isLoading,
    handleEmailChange,
    handleSubmit
  } = usePasswordReset();

  return (
    <div className="mt-8 space-y-6">
      <div>
        <h2 className="text-center text-xl font-medium text-gray-900">
          パスワードの再設定
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          登録済みのメールアドレスを入力してください。
          <br />
          パスワード再設定用のリンクをお送りします。
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="email-address" className="sr-only">
            メールアドレス
          </label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            value={email}
            onChange={handleEmailChange}
            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            placeholder="メールアドレス"
          />
        </div>

        <ErrorMessage error={error} />
        <SuccessMessage message={success} />

        <div>
          <button
            type="submit"
            disabled={isLoading}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400 disabled:cursor-not-allowed"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <KeyRound className={`h-5 w-5 text-blue-500 group-hover:text-blue-400 ${
                isLoading ? 'animate-pulse' : ''
              }`} />
            </span>
            {isLoading ? '送信中...' : 'パスワードを再設定'}
          </button>
        </div>
      </form>

      <BackToLogin />
    </div>
  );
}