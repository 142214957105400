import React, { useState } from 'react';
import { Save, X } from 'lucide-react';
import { useTemplateStore } from '../../stores/templateStore';
import { useDescriptionTemplateStore } from '../../stores/descriptionTemplateStore';
import type { Product } from '../../types';

interface SaveTemplateDialogProps {
  product: Product;
  onClose: () => void;
  onSaved: () => void;
}

export function SaveTemplateDialog({ product, onClose, onSaved }: SaveTemplateDialogProps) {
  const [name, setName] = useState('');
  const [folderId, setFolderId] = useState('default');
  const [error, setError] = useState('');
  const addTemplate = useTemplateStore(state => state.addTemplate);
  const { templates: descriptionTemplates, addTemplate: addDescriptionTemplate } = useDescriptionTemplateStore();
  const folders = useTemplateStore(state => state.folders);

  const handleSave = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      setError('テンプレート名を入力してください');
      return;
    }

    // Save product template
    addTemplate(trimmedName, product, folderId);

    // If product is using a description template, create a matching description template
    if (product.descriptionTemplateId) {
      const sourceTemplate = descriptionTemplates.find(t => t.id === product.descriptionTemplateId);
      if (sourceTemplate) {
        addDescriptionTemplate(trimmedName, sourceTemplate.contents);
      }
    }

    onSaved();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-medium text-gray-900">Boost Hackテンプレートとして保存</h2>
          <button
            onClick={onClose}
            className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              テンプレート名
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setError('');
              }}
              className={`mt-2 block w-full rounded-md ${
                error ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
              }`}
              placeholder="テンプレート名を入力"
              autoFocus
            />
            {error && (
              <p className="mt-1 text-sm text-red-600">{error}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              保存先フォルダ
            </label>
            <select
              value={folderId}
              onChange={(e) => setFolderId(e.target.value)}
              className="mt-2 block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="default">お気に入り</option>
              {folders.map(folder => (
                <option key={folder.id} value={folder.id}>
                  {folder.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-6 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            キャンセル
          </button>
          <button
            onClick={handleSave}
            disabled={!name.trim()}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400"
          >
            <Save className="w-4 h-4" />
            <span>保存</span>
          </button>
        </div>
      </div>
    </div>
  );
}