import React from 'react';
import { LogIn } from 'lucide-react';
import { useLoginForm } from './hooks/useLoginForm';
import { ErrorMessage } from './ErrorMessage';
import { RememberMe } from './RememberMe';
import { SocialLogin } from './SocialLogin';
import { LoginLinks } from './LoginLinks';

export function LoginForm() {
  const {
    email,
    password,
    rememberMe,
    error,
    isLoading,
    handleEmailChange,
    handlePasswordChange,
    handleRememberMeChange,
    handleSubmit
  } = useLoginForm();

  return (
    <div className="mt-8 space-y-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email-address" className="sr-only">
              メールアドレス
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={handleEmailChange}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="メールアドレス"
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              パスワード
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              value={password}
              onChange={handlePasswordChange}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="パスワード"
            />
          </div>
        </div>

        <ErrorMessage error={error} />

        <div className="flex items-center justify-between">
          <RememberMe checked={rememberMe} onChange={handleRememberMeChange} />
          <LoginLinks />
        </div>

        <div>
          <button
            type="submit"
            disabled={isLoading}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400 disabled:cursor-not-allowed"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LogIn className={`h-5 w-5 text-blue-500 group-hover:text-blue-400 ${
                isLoading ? 'animate-pulse' : ''
              }`} />
            </span>
            {isLoading ? 'ログイン中...' : 'ログイン'}
          </button>
        </div>
      </form>

      <SocialLogin />
    </div>
  );
}