import React, { useState } from 'react';
import { ChevronDown, Package, Tag, Star, Truck, Trash2, Gavel, Folder, Hash, Upload, Wand2, StickyNote, Sparkles, Edit, FileText } from 'lucide-react';
import type { Product } from '../types';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { ImagePopup } from './folders/ImagePopup';
import { PriceDisplay } from './prices/PriceDisplay';
import { EditableTitle } from './EditableTitle';
import { MemoInput } from './MemoInput';
import { DragDropImageUpload } from './DragDropImageUpload';
import { createDisplayTitle } from '../utils/memoUtils';
import { generateTitleFromImages } from '../utils/openai';
import { calculateCharacterCount } from '../utils/characterCount';
import { getItemNumberDisplay } from '../utils/titleProcessor';
import { useDescriptionTemplateStore } from '../stores/descriptionTemplateStore';
import {
  getCategoryText,
  getConditionText,
  getShippingMethods
} from '../utils/productViewUtils';

interface ProductCompactViewProps {
  product: Product;
  onExpand: () => void;
  onDelete: () => void;
  isSelected?: boolean;
  onSelect?: (selected: boolean, event?: React.MouseEvent) => void;
  onUpdate?: (product: Product) => void;
  isGeneratingBulk?: boolean;
}

export function ProductCompactView({
  product,
  onExpand,
  onDelete,
  isSelected = false,
  onSelect,
  onUpdate,
  isGeneratingBulk = false
}: ProductCompactViewProps) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [previewImageIndex, setPreviewImageIndex] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const { templates } = useDescriptionTemplateStore();

  const displayTitle = createDisplayTitle(product);
  const itemNumbers = getItemNumberDisplay(product);
  const characterCount = calculateCharacterCount(product);
  const shippingMethods = getShippingMethods(product);

  // Get template name if a template is being used
  const templateName = product.descriptionTemplateId ? 
    templates.find(t => t.id === product.descriptionTemplateId)?.name : 
    null;

  const handleMemoChange = (value: string) => {
    if (onUpdate) {
      const updatedProduct = {
        ...product,
        memo: value
      };
      onUpdate(updatedProduct);
    }
  };

  const handleTitleChange = (value: string) => {
    if (onUpdate) {
      const updatedProduct = {
        ...product,
        title: value
      };
      onUpdate(updatedProduct);
    }
  };

  const handlePriceUpdate = (startPrice: number, buyItNowPrice?: number) => {
    if (onUpdate) {
      const updatedProduct = {
        ...product,
        startPrice,
        buyItNowPrice
      };
      onUpdate(updatedProduct);
    }
  };

  const handleGenerateTitle = async () => {
    if (!product.images.length || !onUpdate) return;

    setIsGenerating(true);
    try {
      const generatedTitle = await generateTitleFromImages(product.images);
      const updatedProduct = {
        ...product,
        title: generatedTitle
      };
      onUpdate(updatedProduct);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('タイトルの生成に失敗しました');
      }
      setTimeout(() => setError(null), 3000);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageClick = (index: number) => {
    setPreviewImageIndex(index);
  };

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onSelect) {
      onSelect(!isSelected, e);
    }
  };

  // Only show wave animation if the product is selected and bulk generation is active
  const showWaveAnimation = isSelected && isGeneratingBulk;

  if (!onUpdate) {
    return null;
  }

  return (
    <DragDropImageUpload product={product} onUpdate={onUpdate}>
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 hover:border-gray-300 transition-colors">
        <div className="p-5">
          <div className="flex flex-col lg:flex-row gap-4">
            {/* Image Section */}
            <div className="flex items-start gap-4 lg:w-48">
              {onSelect && (
                <div className="pt-0.5" onClick={handleCheckboxClick}>
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => {}}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </div>
              )}

              <div className="flex-1 lg:w-full">
                <div className="relative">
                  {product.images.length > 0 ? (
                    <div className="aspect-square w-full">
                      <img
                        src={product.images[0]}
                        alt="商品画像"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImageClick(0);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="aspect-square w-full bg-gray-50 border-2 border-dashed border-gray-200 rounded-md flex flex-col items-center justify-center text-gray-400 hover:bg-gray-100 hover:border-gray-300 transition-colors">
                      <Upload className="w-8 h-8 mb-2" />
                      <span className="text-sm">写真を追加</span>
                      <span className="text-xs mt-1">ドラッグ＆ドロップ可能</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div className="flex-1 min-w-0 flex flex-col">
              {/* Tags Section */}
              <div className="flex flex-wrap gap-2 mb-4">
                <span className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
                  product.condition === 'new' ? 'bg-blue-50 text-blue-600' :
                  product.condition === 'like-new' ? 'bg-green-50 text-green-600' :
                  product.condition === 'good' ? 'bg-green-50 text-green-600' :
                  product.condition === 'fair' ? 'bg-yellow-50 text-yellow-600' :
                  product.condition === 'poor' ? 'bg-orange-50 text-orange-600' :
                  product.condition === 'bad' ? 'bg-red-50 text-red-600' :
                  'bg-gray-100 text-gray-600'
                }`}>
                  {getConditionText(product.condition)}
                </span>

                <span className={`inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium ${
                  product.shippingResponsibility === 'seller'
                    ? 'bg-red-50 text-red-600'
                    : product.shippingCOD
                      ? 'bg-yellow-50 text-yellow-600'
                      : product.shippingLater
                        ? 'bg-purple-50 text-purple-600'
                        : 'bg-red-50 text-red-600'
                }`}>
                  <Truck className="w-4 h-4" />
                  {product.shippingResponsibility === 'seller'
                    ? '送料込み'
                    : product.shippingCOD
                      ? '着払い'
                      : product.shippingLater
                        ? '落札後に送料を連絡'
                        : '落札者負担'}
                </span>

                <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-yellow-50 text-yellow-600">
                  <Folder className="w-4 h-4" />
                  {getCategoryText(product.category)}
                </span>

                {product.isFeatured && (
                  <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-amber-50 text-amber-600">
                    <Star className="w-4 h-4" />
                    注目のオークション
                  </span>
                )}

                {product.recommendedCollection && (
                  <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-amber-50 text-amber-600">
                    <Sparkles className="w-4 h-4" />
                    おすすめコレクション
                  </span>
                )}

                {templateName && (
                  <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-indigo-50 text-indigo-600">
                    <FileText className="w-4 h-4" />
                    {templateName}
                  </span>
                )}
              </div>

              {/* Title Section */}
              <div className="relative mb-6">
                <EditableTitle
                  title={isTitleEditing ? product.title : displayTitle}
                  isGenerating={isGenerating || isGeneratingBulk}
                  product={product}
                  onTitleChange={handleTitleChange}
                  onEditStateChange={setIsTitleEditing}
                  isGeneratingBulk={showWaveAnimation}
                />
                <div className="absolute -bottom-7 right-0 flex flex-wrap gap-2 text-xs">
                  <span className={`font-bold ${characterCount.isValid ? 'text-blue-700' : 'text-red-600'}`}>
                    {characterCount.total}/65文字
                  </span>
                  {characterCount.itemNumbers > 0 && (
                    <span className="font-bold text-blue-700">
                      管理番号: {characterCount.itemNumbers}文字
                    </span>
                  )}
                  {characterCount.memo > 0 && (
                    <span className="font-bold text-blue-700">
                      メモ: {characterCount.memo}文字
                    </span>
                  )}
                </div>
              </div>

              {/* Price Section */}
              <div className="mt-3">
                <PriceDisplay
                  listingType={product.listingType}
                  startPrice={product.startPrice}
                  buyItNowPrice={product.buyItNowPrice}
                  onUpdate={handlePriceUpdate}
                />
              </div>

              {/* Shipping Methods */}
              {shippingMethods && (
                <div className="mt-2 flex items-center gap-2 text-sm text-gray-500">
                  <Package className="w-4 h-4 flex-shrink-0" />
                  <span className="truncate">{shippingMethods}</span>
                </div>
              )}

              {/* Bottom Section */}
              <div className="mt-auto pt-4 border-t">
                <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4">
                  <div className="flex-1 min-w-0">
                    <MemoInput
                      value={product.memo}
                      onChange={handleMemoChange}
                      onClick={(e) => e.stopPropagation()}
                      product={product}
                    />
                  </div>
                  
                  <div className="flex items-center gap-2 w-full lg:w-auto">
                    <button
                      onClick={onExpand}
                      className="flex items-center justify-center gap-2 px-4 py-2 bg-white text-gray-700 rounded-md shadow-sm border border-gray-200 text-sm font-medium transition-colors hover:bg-gray-50 flex-1 lg:flex-none"
                    >
                      <Edit className="w-4 h-4" />
                      <span>編集</span>
                    </button>

                    {product.images.length > 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleGenerateTitle();
                        }}
                        disabled={isGenerating || isGeneratingBulk}
                        className={`btn-ai-compact flex items-center justify-center gap-2 px-4 ${
                          showWaveAnimation ? 'animate-pulse' : ''
                        }`}
                        title="AIでタイトルを生成"
                      >
                        <Wand2 className={`w-4 h-4 ${isGenerating ? 'animate-spin' : ''}`} />
                        <span>AIで生成</span>
                      </button>
                    )}
                    
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteConfirmation(true);
                      }}
                      className="p-2 text-red-500 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          productTitle={displayTitle}
          onConfirm={() => {
            onDelete();
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}

      {previewImageIndex !== null && (
        <ImagePopup
          images={product.images}
          currentIndex={previewImageIndex}
          title={displayTitle}
          onClose={() => setPreviewImageIndex(null)}
          onPrevious={() => setPreviewImageIndex(prev => Math.max(0, prev - 1))}
          onNext={() => setPreviewImageIndex(prev => Math.min(product.images.length - 1, prev + 1))}
        />
      )}
    </DragDropImageUpload>
  );
}