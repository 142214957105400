import React from 'react';
import { SignupForm } from './SignupForm';
import { Logo } from '../login/Logo';

export function SignupPage() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <Logo />
        <SignupForm />
      </div>
    </div>
  );
}