import React from 'react';
import { Clock } from 'lucide-react';
import { CostBreakdown } from '../shared/CostBreakdown';
import { useCostCalculation } from '../hooks/useCostCalculation';

export function ManualProcessCard() {
  const { manualTotalHours, manualCost } = useCostCalculation();

  return (
    <div className="bg-white rounded-xl p-6 shadow-md border border-gray-200">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-gray-100 rounded-lg">
          <Clock className="w-6 h-6 text-gray-600" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900">手動作業の場合</h3>
      </div>
      
      <div className="space-y-4">
        <div className="bg-gray-50 rounded-lg p-4">
          <p className="font-medium text-gray-900">月間1,000商品の場合</p>
          <CostBreakdown
            hours={manualTotalHours}
            laborCost={manualCost}
            systemCost={0}
            totalCost={manualCost}
          />
          <div className="mt-3 text-sm text-gray-600 bg-gray-100 p-3 rounded-lg">
            <p>※ 出品スタッフの一般的な平均値です。</p>
          </div>
        </div>
      </div>
    </div>
  );
}