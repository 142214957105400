import { useState } from 'react';
import { useAuthStore } from '../../../../stores/authStore';
import { sendPasswordResetEmail } from '../../../../utils/auth/passwordReset';

export function usePasswordReset() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(email);
      setSuccess('パスワード再設定用のメールを送信しました。メールをご確認ください。');
      setEmail('');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('パスワード再設定メールの送信に失敗しました');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    email,
    error,
    success,
    isLoading,
    handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    handleSubmit
  };
}