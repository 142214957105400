import React, { useState } from 'react';
import { Download, FileSpreadsheet, Loader2, Plus, Settings } from 'lucide-react';
import { useProductStore } from '../../stores/productStore';
import { useFolderStore } from '../../stores/folderStore';
import { createImageZip, downloadZip } from '../../utils/imageCompression';
import { downloadCSV } from '../../utils/csvExport';
import { ImportFromZip } from './ImportFromZip';
import { WatermarkSettings } from './WatermarkSettings';
import { DownloadProgress } from './DownloadProgress';

export function DownloadActions() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showWatermarkSettings, setShowWatermarkSettings] = useState(false);
  const [watermarkEnabled, setWatermarkEnabled] = useState(false);
  const [watermarkSize, setWatermarkSize] = useState(100); // 100%
  const [compressionQuality, setCompressionQuality] = useState(70); // 70%
  const [currentImage, setCurrentImage] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const products = useProductStore(state => state.products);
  const addProduct = useProductStore(state => state.addProduct);
  const currentFolderId = useFolderStore(state => state.currentFolderId);

  const selectedProducts = products.filter(p => p.isSelected);
  const targetProducts = selectedProducts.length > 0 ? selectedProducts : products;

  const handleDownloadAll = async () => {
    if (isProcessing || targetProducts.length === 0) return;

    const allImages = targetProducts.flatMap(product => product.images);
    if (allImages.length === 0) return;

    setIsProcessing(true);
    setCurrentImage(0);
    setTotalImages(allImages.length);

    try {
      const zipBlob = await createImageZip(
        allImages,
        targetProducts,
        {
          watermark: watermarkEnabled,
          watermarkSize: watermarkSize,
          quality: compressionQuality / 100 // Convert percentage to decimal
        },
        (current) => setCurrentImage(current)
      );
      const timestamp = new Date().toISOString().split('T')[0];
      downloadZip(zipBlob, `yahoo_auction_${timestamp}.zip`);
    } catch (error) {
      console.error('Failed to create ZIP:', error);
    } finally {
      setIsProcessing(false);
      setCurrentImage(0);
      setTotalImages(0);
    }
  };

  const handleDownloadCSV = () => {
    if (targetProducts.length === 0) return;
    downloadCSV(targetProducts, { encoding: 'utf8' });
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-6">
      <div className="p-4 flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
          <button
            onClick={handleDownloadCSV}
            disabled={targetProducts.length === 0}
            className="flex items-center justify-center gap-2 px-4 py-3 h-18 rounded-md text-sm font-medium transition-colors w-full sm:w-auto text-white bg-gradient-to-r from-green-500 to-emerald-600 hover:from-green-600 hover:to-emerald-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FileSpreadsheet className="w-5 h-5" />
            <span>CSVをダウンロード{selectedProducts.length > 0 ? ` (${selectedProducts.length}件)` : ''}</span>
          </button>

          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <button
              onClick={handleDownloadAll}
              disabled={isProcessing || targetProducts.length === 0}
              className="flex items-center justify-center gap-2 px-4 py-3 h-18 rounded-md text-sm font-medium transition-colors w-full sm:w-auto text-white bg-gradient-to-r from-purple-600 to-indigo-700 hover:from-purple-700 hover:to-indigo-800 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isProcessing ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Download className="w-5 h-5" />
              )}
              <span>画像とCSVをダウンロード{selectedProducts.length > 0 ? ` (${selectedProducts.length}件)` : ''}</span>
            </button>

            <button
              onClick={() => setShowWatermarkSettings(true)}
              className="flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
            >
              <Settings className="w-4 h-4" />
              <span>画像設定</span>
            </button>
          </div>
        </div>

        {currentFolderId !== null && (
          <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
            <ImportFromZip />
            <button
              onClick={() => addProduct(false)}
              className="flex items-center justify-center gap-2 px-4 py-3 h-18 rounded-md text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition-colors w-full sm:w-auto"
            >
              <Plus className="w-5 h-5" />
              <span>新規作成</span>
            </button>
          </div>
        )}
      </div>

      {showWatermarkSettings && (
        <WatermarkSettings
          enabled={watermarkEnabled}
          size={watermarkSize}
          quality={compressionQuality}
          onEnabledChange={setWatermarkEnabled}
          onSizeChange={setWatermarkSize}
          onQualityChange={setCompressionQuality}
          onClose={() => setShowWatermarkSettings(false)}
        />
      )}

      <DownloadProgress
        current={currentImage}
        total={totalImages}
        isVisible={isProcessing}
      />
    </div>
  );
}