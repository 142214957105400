import { saveTemplate as dbSaveTemplate, getTemplates as dbGetTemplates, deleteTemplate as dbDeleteTemplate } from '../../db/operations/templateOperations';
import type { ProductTemplate } from './productTemplates';

export async function saveTemplate(template: ProductTemplate): Promise<void> {
  try {
    await dbSaveTemplate(template);
  } catch (error) {
    console.error('Failed to save template:', error);
    throw new Error('テンプレートの保存に失敗しました');
  }
}

export async function getTemplates(): Promise<ProductTemplate[]> {
  try {
    const templates = await dbGetTemplates();
    // Only return non-deleted templates
    return templates.filter(t => !t.isDeleted);
  } catch (error) {
    console.error('Failed to load templates:', error);
    throw new Error('テンプレートの読み込みに失敗しました');
  }
}

export async function deleteTemplate(id: string): Promise<void> {
  try {
    // Completely delete the template from storage
    await dbDeleteTemplate(id);
  } catch (error) {
    console.error('Failed to delete template:', error);
    throw new Error('テンプレートの削除に失敗しました');
  }
}