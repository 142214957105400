import React from 'react';
import { AlertCircle } from 'lucide-react';

interface ErrorMessageProps {
  error: string;
}

export function ErrorMessage({ error }: ErrorMessageProps) {
  if (!error) return null;

  return (
    <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-md">
      <AlertCircle className="w-5 h-5 flex-shrink-0" />
      <p className="text-sm">{error}</p>
    </div>
  );
}