import React from 'react';
import { Clock, Zap } from 'lucide-react';

export function TimeComparisonGrid() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
      {/* Manual Process */}
      <div className="bg-white rounded-xl p-6 shadow-md border border-gray-200">
        <div className="flex items-center gap-3 mb-4">
          <div className="p-2 bg-gray-100 rounded-lg">
            <Clock className="w-6 h-6 text-gray-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">通常の出品作業</h3>
        </div>
        
        <div className="space-y-4">
          <p className="text-gray-600">
            1商品あたり約5分
          </p>
          <div className="bg-gray-50 rounded-lg p-4">
            <p className="font-medium text-gray-900">100商品の場合</p>
            <p className="text-2xl font-bold text-gray-900 mt-2">
              約8.3時間
            </p>
            <p className="text-sm text-gray-500 mt-1">
              (100商品 × 5分 = 500分)
            </p>
          </div>
        </div>
      </div>

      {/* Boost Hack Process */}
      <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-6 shadow-md border border-blue-100">
        <div className="flex items-center gap-3 mb-4">
          <div className="p-2 bg-blue-100 rounded-lg">
            <Zap className="w-6 h-6 text-blue-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Boost Hackを使用</h3>
        </div>
        
        <div className="space-y-4">
          <p className="text-gray-600">
            1商品あたり約10～12秒
          </p>
          <div className="bg-white bg-opacity-50 rounded-lg p-4">
            <p className="font-medium text-gray-900">100商品の場合</p>
            <p className="text-2xl font-bold text-blue-600 mt-2">
              約10～20分
            </p>
            <p className="text-sm text-gray-500 mt-1">
              (100商品 × 10-12秒 = 1000-1200秒)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}