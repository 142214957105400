import type { Product } from '../../types';

export interface ProductTemplate {
  id: string;
  name: string;
  folderId: string;
  fields: {
    listingType: 'auction' | 'fixed';
    startPrice: number;
    buyItNowPrice?: number;
    itemNumber: string;
    itemNumber2: string;
    itemNumberPrefix: boolean;
    itemNumberSuffix: boolean;
    itemNumberSerial: boolean;
    memoPrefix: boolean;
    memoSuffix: boolean;
    memoSerial: boolean;
    condition: string;
    description: string;
    descriptionTemplateId: string | null;
    category: string;
    shippingResponsibility: string;
    shippingLater: boolean;
    shippingCOD: boolean;
    quantity: number;
    auctionDuration: number;
    endTime: string;
    shippingTime: string;
    shippingLocation: string;
    nekoposu: boolean;
    nekoCompact: boolean;
    nekoEazy: boolean;
    nekoEazySize: string;
    nekoEazyWeight: string;
    yuPacketMini: boolean;
    yuPacket: boolean;
    yuPacketPlus: boolean;
    yuPack: boolean;
    yuPackSize: string;
    yuPackWeight: string;
    customShippingMethods: any[];
    autoExtension: boolean;
    autoExtensionRate: number;
    autoExtensionCount: number;
    autoExtensionByBid: boolean;
    ratingRestriction: boolean;
    badRatingRestriction: boolean;
    bidderAuthentication: boolean;
    returnsAccepted: boolean;
  };
  createdAt: string;
  updatedAt: string;
  isDeleted?: boolean;
}

export function createTemplateFromProduct(product: Product, name: string, folderId: string = 'default'): ProductTemplate {
  return {
    id: crypto.randomUUID(),
    name,
    folderId,
    fields: {
      listingType: product.listingType,
      startPrice: product.startPrice,
      buyItNowPrice: product.buyItNowPrice,
      itemNumber: product.itemNumber,
      itemNumber2: product.itemNumber2,
      itemNumberPrefix: product.itemNumberPrefix,
      itemNumberSuffix: product.itemNumberSuffix,
      itemNumberSerial: product.itemNumberSerial,
      memoPrefix: product.memoPrefix,
      memoSuffix: product.memoSuffix,
      memoSerial: product.memoSerial,
      condition: product.condition,
      description: product.description,
      descriptionTemplateId: product.descriptionTemplateId,
      category: product.category,
      shippingResponsibility: product.shippingResponsibility,
      shippingLater: product.shippingLater,
      shippingCOD: product.shippingCOD,
      quantity: product.quantity,
      auctionDuration: product.auctionDuration,
      endTime: product.endTime,
      shippingTime: product.shippingTime,
      shippingLocation: product.shippingLocation,
      nekoposu: product.nekoposu,
      nekoCompact: product.nekoCompact,
      nekoEazy: product.nekoEazy,
      nekoEazySize: product.nekoEazySize,
      nekoEazyWeight: product.nekoEazyWeight,
      yuPacketMini: product.yuPacketMini,
      yuPacket: product.yuPacket,
      yuPacketPlus: product.yuPacketPlus,
      yuPack: product.yuPack,
      yuPackSize: product.yuPackSize,
      yuPackWeight: product.yuPackWeight,
      customShippingMethods: [...product.customShippingMethods],
      autoExtension: product.autoExtension,
      autoExtensionRate: product.autoExtensionRate,
      autoExtensionCount: product.autoExtensionCount,
      autoExtensionByBid: product.autoExtensionByBid,
      ratingRestriction: product.ratingRestriction,
      badRatingRestriction: product.badRatingRestriction,
      bidderAuthentication: product.bidderAuthentication,
      returnsAccepted: product.returnsAccepted
    },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };
}

export function applyTemplateToProduct(product: Product, template: ProductTemplate): Product {
  return {
    ...product,
    ...template.fields,
    customShippingMethods: [...template.fields.customShippingMethods]
  };
}