import JSZip from 'jszip';
import type { ProductTemplate } from './productTemplates';
import type { DescriptionTemplate } from '../../types/descriptionTemplate';

interface Folder {
  id: string;
  name: string;
}

interface ExportData {
  productTemplates: ProductTemplate[];
  descriptionTemplates: DescriptionTemplate[];
  folders: Folder[];
  version: string;
  exportDate: string;
  metadata: {
    totalTemplates: number;
    totalFolders: number;
  };
}

export async function exportTemplates(
  productTemplates: ProductTemplate[],
  descriptionTemplates: DescriptionTemplate[],
  folders: Folder[]
): Promise<Blob> {
  const zip = new JSZip();

  // Create metadata.json
  const metadata: ExportData = {
    productTemplates,
    descriptionTemplates,
    folders,
    version: '1.0',
    exportDate: new Date().toISOString(),
    metadata: {
      totalTemplates: productTemplates.length + descriptionTemplates.length,
      totalFolders: folders.length
    }
  };

  // Add metadata file
  zip.file('metadata.json', JSON.stringify(metadata, null, 2));

  // Create folder structure
  folders.forEach(folder => {
    const folderTemplates = productTemplates.filter(t => t.folderId === folder.id);
    if (folderTemplates.length > 0) {
      const folderPath = `templates/${folder.name}/`;
      folderTemplates.forEach(template => {
        zip.file(`${folderPath}${template.name}.json`, JSON.stringify(template, null, 2));
      });
    }
  });

  // Add description templates
  if (descriptionTemplates.length > 0) {
    const descriptionPath = 'templates/descriptions/';
    descriptionTemplates.forEach(template => {
      zip.file(`${descriptionPath}${template.name}.json`, JSON.stringify(template, null, 2));
    });
  }

  return zip.generateAsync({ type: 'blob' });
}

export async function importTemplates(file: File): Promise<{
  productTemplates: ProductTemplate[];
  descriptionTemplates: DescriptionTemplate[];
  folders: Folder[];
} | null> {
  try {
    const zip = await JSZip.loadAsync(file);
    
    // Read metadata first
    const metadataFile = zip.file('metadata.json');
    if (!metadataFile) {
      throw new Error('メタデータファイルが見つかりません');
    }

    const metadataContent = await metadataFile.async('string');
    const metadata = JSON.parse(metadataContent) as ExportData;

    // Validate version
    if (!metadata.version || !metadata.version.startsWith('1.')) {
      throw new Error('互換性のないバージョンのテンプレートファイルです');
    }

    // Import folders directly from metadata
    const folders = metadata.folders;
    
    // Ensure default folder exists
    if (!folders.some(f => f.id === 'default')) {
      folders.unshift({
        id: 'default',
        name: 'お気に入り'
      });
    }

    // Import templates directly from metadata
    const productTemplates = metadata.productTemplates.map(template => ({
      ...template,
      // Ensure template has a valid folder ID
      folderId: folders.some(f => f.id === template.folderId) ? template.folderId : 'default'
    }));

    const descriptionTemplates = metadata.descriptionTemplates;

    // Validate imported data
    if (!Array.isArray(productTemplates) || !Array.isArray(descriptionTemplates)) {
      throw new Error('テンプレートデータの形式が不正です');
    }

    return {
      productTemplates,
      descriptionTemplates,
      folders
    };
  } catch (error) {
    console.error('Failed to import templates:', error);
    return null;
  }
}

export async function downloadTemplates(
  productTemplates: ProductTemplate[],
  descriptionTemplates: DescriptionTemplate[],
  folders: Folder[]
): Promise<void> {
  try {
    const zipBlob = await exportTemplates(productTemplates, descriptionTemplates, folders);
    const url = URL.createObjectURL(zipBlob);
    const link = document.createElement('a');
    const timestamp = new Date().toISOString().split('T')[0];
    
    link.href = url;
    link.download = `boost-hack-templates_${timestamp}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Failed to download templates:', error);
    throw error;
  }
}

export function readTemplateFile(file: File): Promise<{
  productTemplates: ProductTemplate[];
  descriptionTemplates: DescriptionTemplate[];
  folders: Folder[];
} | null> {
  return importTemplates(file);
}