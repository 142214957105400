import React from 'react';
import { Link } from 'react-router-dom';

export function SignupLinks() {
  return (
    <div className="text-center">
      <p className="text-sm text-gray-600">
        すでにアカウントをお持ちの方は{' '}
        <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
          ログイン
        </Link>
      </p>
    </div>
  );
}