import React from 'react';
import { Users } from 'lucide-react';

export function AdminHeader() {
  return (
    <div className="mb-8">
      <div className="flex items-center gap-4 mb-4">
        <div className="p-3 bg-blue-100 rounded-lg">
          <Users className="w-6 h-6 text-blue-600" />
        </div>
        <h1 className="text-2xl font-bold text-gray-900">会員管理</h1>
      </div>
      <p className="text-gray-600">
        登録されている会員の一覧と管理を行うことができます。
      </p>
    </div>
  );
}