export function getMonthlyResetDate(): Date {
  const now = new Date();
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  nextMonth.setHours(0, 0, 0, 0);
  return nextMonth;
}

export function getTimeUntilReset(): number {
  const nextReset = getMonthlyResetDate();
  const now = new Date();
  return nextReset.getTime() - now.getTime();
}

export function formatTimeUntilReset(): string {
  const msUntilReset = getTimeUntilReset();
  const days = Math.floor(msUntilReset / (1000 * 60 * 60 * 24));
  const hours = Math.floor((msUntilReset % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  
  return `${days}日${hours}時間`;
}