import type { ShippingMethodOption } from '../../types/shipping';

export const SHIPPING_FEE_URLS = {
  'sagawa-express': 'https://www.sagawa-exp.co.jp/send/fare/faretable04.html',
  'yu-pack-flat': 'https://www.post.japanpost.jp/send/fee/kokunai/overview.html',
  'yamato-transport': 'https://www.kuronekoyamato.co.jp/ytc/search/estimate/ichiran.html',
  'seino-transport': 'http://stc.deliveryseino.jp/unchin/unchin.20240601.pdf'
} as const;

export const PREDEFINED_SHIPPING_METHODS: ShippingMethodOption[] = [
  { value: 'clickpost', label: 'クリックポスト', hasRegionalPricing: false },
  { value: 'regular-mail', label: '定形郵便', hasRegionalPricing: false },
  { value: 'non-regular-mail', label: '定形外郵便', hasRegionalPricing: false },
  { value: 'letter-pack-plus', label: 'レターパックプラス', hasRegionalPricing: false },
  { value: 'letter-pack-light', label: 'レターパックライト', hasRegionalPricing: false },
  { value: 'yu-mail', label: 'ゆうメール', hasRegionalPricing: false },
  { value: 'yu-pack-flat', label: 'ゆうパック', hasRegionalPricing: true },
  { value: 'yamato-transport', label: '宅急便（ヤマト運輸）', hasRegionalPricing: true },
  { value: 'sagawa-express', label: '飛脚宅急便（佐川急便）', hasRegionalPricing: true },
  { value: 'seino-transport', label: 'カンガルー便（西濃運輸）', hasRegionalPricing: true },
  { value: 'other', label: 'その他', hasRegionalPricing: true }
];