import React, { useState } from 'react';
import { Settings, Plus } from 'lucide-react';
import { useProductStore } from '../../stores/productStore';
import { useTemplateStore } from '../../stores/templateStore';
import { TemplateList } from './TemplateList';
import { TemplateImportExport } from './TemplateImportExport';

export function TemplateManager() {
  const [showTemplateList, setShowTemplateList] = useState(false);
  const addProduct = useProductStore(state => state.addProduct);
  const { templates, activeTemplateId } = useTemplateStore();

  const handleCreateFromTemplate = async () => {
    if (activeTemplateId) {
      await addProduct(true);
    }
  };

  return (
    <div className="px-4 pb-4 border-t">
      <div className="pt-4">
        <h3 className="text-sm font-medium text-gray-700 mb-2">テンプレート</h3>
        
        <div className="space-y-2">
          <button
            onClick={() => setShowTemplateList(!showTemplateList)}
            className="w-full flex items-center justify-between px-3 py-2 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-md transition-colors"
          >
            <div className="flex items-center gap-2">
              <Settings className="w-4 h-4" />
              <span>テンプレートを管理</span>
            </div>
            <span className="text-xs text-gray-500">
              {templates.length}件
            </span>
          </button>

          {showTemplateList && (
            <div className="mt-2 space-y-4">
              <TemplateImportExport />
              <TemplateList />
              {activeTemplateId && (
                <button
                  onClick={handleCreateFromTemplate}
                  className="w-full flex items-center justify-center gap-2 px-3 h-[100px] text-sm font-medium text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-md transition-colors"
                >
                  <Plus className="w-4 h-4" />
                  <span>テンプレートから作成</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}