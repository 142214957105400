import React from 'react';
import { ManualProcessCard } from './cards/ManualProcessCard';
import { BoostHackCard } from './cards/BoostHackCard';

export function CostComparisonGrid() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
      <ManualProcessCard />
      <BoostHackCard />
    </div>
  );
}