import { useCallback } from 'react';
import { useAiCreditsStore } from '../stores/aiCreditsStore';

export function useAiCredits() {
  const { 
    planCredits,
    maxPlanCredits,
    chargedCredits,
    maxChargedCredits,
    useCredit
  } = useAiCreditsStore();

  const getLowCreditsThreshold = useCallback(() => {
    return Math.floor(maxPlanCredits * 0.1); // 10% of max plan credits
  }, [maxPlanCredits]);

  const generateWithAi = useCallback(async () => {
    try {
      await useCredit();
      return true;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`AIクレジットエラー: ${error.message}`);
      }
      throw error;
    }
  }, [useCredit]);

  return {
    planCredits,
    maxPlanCredits,
    chargedCredits,
    maxChargedCredits,
    isLowCredits: planCredits <= getLowCreditsThreshold() && chargedCredits === 0,
    generateWithAi
  };
}