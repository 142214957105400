import React from 'react';
import { Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

export function SocialLogin() {
  return (
    <div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-gray-50 text-gray-500">または</span>
        </div>
      </div>

      <div className="mt-6">
        <button
          onClick={() => {/* TODO: Implement Gmail login */}}
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <Mail className="w-5 h-5 text-red-500 mr-2" />
          <span>Gmailでログイン</span>
        </button>
      </div>

      <p className="mt-6 text-center text-sm text-gray-600">
        アカウントをお持ちでない方は{' '}
        <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-500">
          新規会員登録
        </Link>
      </p>
    </div>
  );
}