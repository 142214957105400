import React, { useState, useRef, useEffect } from 'react';
import { Save, ChevronUp } from 'lucide-react';
import { ProductCompactView } from './ProductCompactView';
import { DetailSettings } from './DetailSettings';
import { ListingInfo } from './ListingInfo';
import { ImageUpload } from './ImageUpload';
import { TitleInput } from './TitleInput';
import { SaveTemplateDialog } from './templates/SaveTemplateDialog';
import { createTitleWithItemNumbers } from '../utils/titleManager';
import { calculateCharacterCount } from '../utils/characterCount';
import type { Product } from '../types';

interface ProductFormProps {
  product: Product;
  onUpdate: (product: Product) => Promise<void>;
  onDelete: (productId: number) => Promise<void>;
  isSelected?: boolean;
  onSelect?: (selected: boolean, event?: React.MouseEvent) => void;
  isExpanded: boolean;
  onExpand: () => void;
  isGeneratingBulk?: boolean;
}

export function ProductForm({
  product,
  onUpdate,
  onDelete,
  isSelected = false,
  onSelect,
  isExpanded,
  onExpand,
  isGeneratingBulk = false
}: ProductFormProps) {
  const [draftProduct, setDraftProduct] = useState(product);
  const [isDirty, setIsDirty] = useState(false);
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [useDescriptionTemplate, setUseDescriptionTemplate] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef<HTMLDivElement>(null);
  const saveTimeoutRef = useRef<number | null>(null);

  React.useEffect(() => {
    setDraftProduct(product);
    setIsDirty(false);
  }, [product]);

  useEffect(() => {
    if (!isExpanded) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        if (isDirty) {
          handleSave();
        }
      }
    };

    // Add event listener with a small delay to prevent immediate triggering
    const timeoutId = setTimeout(() => {
      document.addEventListener('mousedown', handleClickOutside);
    }, 100);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      clearTimeout(timeoutId);
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [isExpanded, isDirty, draftProduct]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    
    setDraftProduct(prev => ({
      ...prev,
      [name]: newValue
    }));
    setIsDirty(true);

    // Clear any existing save timeout
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    // Set a new save timeout
    saveTimeoutRef.current = window.setTimeout(() => {
      handleSave();
    }, 1000);
  };

  const handleTitleChange = (value: string) => {
    setDraftProduct(prev => ({
      ...prev,
      title: value
    }));
    setIsDirty(true);

    // Clear any existing save timeout
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    // Set a new save timeout
    saveTimeoutRef.current = window.setTimeout(() => {
      handleSave();
    }, 1000);
  };

  const handleListingTypeChange = (type: 'auction' | 'fixed') => {
    setDraftProduct(prev => ({
      ...prev,
      listingType: type,
      buyItNowPrice: type === 'fixed' ? undefined : prev.buyItNowPrice
    }));
    setIsDirty(true);

    // Clear any existing save timeout
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    // Set a new save timeout
    saveTimeoutRef.current = window.setTimeout(() => {
      handleSave();
    }, 1000);
  };

  const handleImagesChange = (images: string[]) => {
    setDraftProduct(prev => ({
      ...prev,
      images
    }));
    setIsDirty(true);
    
    onUpdate({
      ...draftProduct,
      images
    });
  };

  const handleSave = async () => {
    const charCount = calculateCharacterCount(draftProduct);
    if (!charCount.isValid) {
      return;
    }

    try {
      setIsSaving(true);
      const updatedProduct = {
        ...draftProduct,
        title: createTitleWithItemNumbers(draftProduct)
      };
      await onUpdate(updatedProduct);
      onExpand();
      setIsDirty(false);
      
      // Scroll to the product after saving
      const productElement = document.getElementById(`product-${product.id}`);
      if (productElement) {
        productElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } catch (error) {
      console.error('Failed to save product:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = () => {
    onDelete(product.id);
  };

  const handleTemplateSaved = () => {
    setShowSaveTemplate(false);
  };

  if (!isExpanded) {
    return (
      <ProductCompactView
        product={draftProduct}
        onExpand={onExpand}
        onDelete={handleDelete}
        isSelected={isSelected}
        onSelect={onSelect}
        onUpdate={onUpdate}
        isGeneratingBulk={isGeneratingBulk}
      />
    );
  }

  return (
    <div ref={formRef} className="bg-white rounded-lg shadow-sm border border-gray-200" id={`product-${product.id}`}>
      <div className="p-4 sm:p-6 space-y-6">
        <div className="flex items-center justify-between">
          <button
            onClick={onExpand}
            className="flex items-center gap-2 text-gray-500 hover:text-gray-700"
          >
            <ChevronUp className="w-5 h-5" />
            <span>折りたたむ</span>
          </button>
          <div className="flex items-center gap-3">
            <button
              onClick={() => setShowSaveTemplate(true)}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-green-600 bg-green-100 rounded-md hover:bg-green-200"
            >
              <Save className="w-4 h-4" />
              <span>テンプレートとして保存</span>
            </button>
            <button
              onClick={handleSave}
              disabled={isSaving}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              <Save className="w-4 h-4" />
              <span>{isSaving ? '保存中...' : '保存'}</span>
            </button>
          </div>
        </div>

        <div className="space-y-6">
          <ImageUpload
            images={draftProduct.images}
            onImagesChange={handleImagesChange}
            title={draftProduct.title}
          />
          
          <div className="space-y-6">
            <TitleInput
              value={draftProduct.title}
              onChange={handleTitleChange}
              product={draftProduct}
            />

            <ListingInfo
              product={draftProduct}
              onChange={handleChange}
              onListingTypeChange={handleListingTypeChange}
            />
          </div>
        </div>

        <DetailSettings
          product={draftProduct}
          onChange={handleChange}
          useDescriptionTemplate={useDescriptionTemplate}
          onDescriptionTemplateChange={(e) => setUseDescriptionTemplate(e.target.checked)}
        />

        {/* Bottom Save Button */}
        <div className="flex justify-end gap-3 pt-6 border-t">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            <Save className="w-4 h-4" />
            <span>{isSaving ? '保存中...' : '保存'}</span>
          </button>
        </div>
      </div>

      {showSaveTemplate && (
        <SaveTemplateDialog
          product={draftProduct}
          onClose={() => setShowSaveTemplate(false)}
          onSaved={handleTemplateSaved}
        />
      )}
    </div>
  );
}