import React from 'react';

export function TimeComparisonSummary() {
  return (
    <div className="mt-8 bg-green-50 rounded-xl p-6 max-w-4xl mx-auto">
      <h3 className="text-lg font-semibold text-green-800 mb-2">
        作業時間を最大96%削減
      </h3>
      <p className="text-green-700">
        Boost Hackを使用することで、通常8時間以上かかる作業をわずか20分程度で完了できます。
        一括アップロード、AIタイトル生成、テンプレート機能により、作業効率が大幅に向上します。
      </p>
    </div>
  );
}