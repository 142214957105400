import { useMemo } from 'react';

const HOURLY_RATE = 1200; // 時給1,200円
const STANDARD_PLAN_MONTHLY = 4980; // スタンダードプラン月額
const PRODUCTS_PER_MONTH = 1000; // 月間想定商品数
const MANUAL_TIME_PER_PRODUCT = 5; // 手動作業時間（分）
const BOOST_TIME_PER_PRODUCT = 0.2; // Boost Hack使用時間（分）

export function useCostCalculation() {
  return useMemo(() => {
    // 手動作業の計算
    const manualTotalMinutes = PRODUCTS_PER_MONTH * MANUAL_TIME_PER_PRODUCT;
    const manualTotalHours = manualTotalMinutes / 60;
    const manualCost = manualTotalHours * HOURLY_RATE;

    // Boost Hackの計算
    const boostTotalMinutes = PRODUCTS_PER_MONTH * BOOST_TIME_PER_PRODUCT;
    const boostTotalHours = boostTotalMinutes / 60;
    const boostLaborCost = boostTotalHours * HOURLY_RATE;
    const boostTotalCost = boostLaborCost + STANDARD_PLAN_MONTHLY;

    // 削減額の計算
    const savings = manualCost - boostTotalCost;
    const savingsPercentage = ((manualCost - boostTotalCost) / manualCost) * 100;

    return {
      STANDARD_PLAN_MONTHLY,
      PRODUCTS_PER_MONTH,
      manualTotalHours,
      manualCost,
      boostTotalHours,
      boostLaborCost,
      boostTotalCost,
      savings,
      savingsPercentage
    };
  }, []);
}