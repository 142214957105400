import React from 'react';
import { StickyNote } from 'lucide-react';
import { processTitleWithMemo } from '../../utils/titleProcessor';

interface MemoPreviewProps {
  title: string;
  memo: string;
}

export function MemoPreview({ title, memo }: MemoPreviewProps) {
  if (!title || !memo) return null;

  const processedTitle = processTitleWithMemo(title, memo);
  const hasPlaceholder = title.includes('*');

  if (!hasPlaceholder) return null;

  return (
    <div className="mt-2 bg-gray-50 rounded-md p-3">
      <div className="flex items-center gap-2 text-sm text-gray-500 mb-2">
        <StickyNote className="w-4 h-4" />
        <span>メモの置換プレビュー</span>
      </div>
      <div className="text-sm">
        <div className="mb-1">
          <span className="text-gray-500">元のタイトル: </span>
          <span className="text-gray-700">{title}</span>
        </div>
        <div>
          <span className="text-gray-500">置換後: </span>
          <span className="text-gray-900 font-medium">{processedTitle}</span>
        </div>
      </div>
    </div>
  );
}