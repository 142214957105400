import React from 'react';
import { Download } from 'lucide-react';
import { Member } from '../../../types/member';
import { downloadPaymentHistory } from '../../../utils/admin/paymentHistory';

interface PaymentHistoryDownloadProps {
  selectedMembers: Member[];
  startDate?: string;
  endDate?: string;
}

export function PaymentHistoryDownload({ 
  selectedMembers,
  startDate,
  endDate
}: PaymentHistoryDownloadProps) {
  const handleDownload = async () => {
    try {
      await downloadPaymentHistory(selectedMembers, startDate, endDate);
    } catch (error) {
      console.error('Failed to download payment history:', error);
    }
  };

  return (
    <button
      onClick={handleDownload}
      disabled={selectedMembers.length === 0}
      className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors ${
        selectedMembers.length > 0
          ? 'text-white bg-green-600 hover:bg-green-700'
          : 'text-gray-400 bg-gray-100 cursor-not-allowed'
      }`}
    >
      <Download className="w-4 h-4" />
      <span>支払い履歴をダウンロード</span>
    </button>
  );
}