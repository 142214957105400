import React from 'react';
import { useAiCredits } from '../../hooks/useAiCredits';
import { AiCreditsCounter } from './credits/AiCreditsCounter';
import { AiCreditsIcon } from './credits/AiCreditsIcon';
import { AiCreditsTooltip } from './credits/AiCreditsTooltip';

export function AiCreditsDisplay() {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const { 
    planCredits = 0,
    maxPlanCredits = 0,
    chargedCredits = 0,
    maxChargedCredits = 0,
    isLowCredits = false
  } = useAiCredits();

  return (
    <div 
      className={`
        relative flex items-center gap-4 px-4 py-2 rounded-md transition-colors
        ${isLowCredits 
          ? 'bg-red-50 animate-pulse' 
          : 'bg-gradient-to-r from-purple-50 to-indigo-50'}
      `}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {/* Plan Credits */}
      <div className="flex items-center gap-2">
        <AiCreditsIcon isLowCredits={isLowCredits} type="plan" />
        <div className="text-sm">
          <span className="text-xs text-gray-500">プラン</span>
          <AiCreditsCounter
            remaining={planCredits}
            max={maxPlanCredits}
            isLowCredits={isLowCredits}
          />
        </div>
      </div>

      {/* Divider */}
      <div className="h-8 w-px bg-gray-200" />

      {/* Charged Credits */}
      <div className="flex items-center gap-2">
        <AiCreditsIcon isLowCredits={false} type="charged" />
        <div className="text-sm">
          <span className="text-xs text-gray-500">追加</span>
          <AiCreditsCounter
            remaining={chargedCredits}
            max={maxChargedCredits}
            isLowCredits={false}
          />
        </div>
      </div>

      {showTooltip && (
        <AiCreditsTooltip
          planCredits={planCredits}
          maxPlanCredits={maxPlanCredits}
          chargedCredits={chargedCredits}
          maxChargedCredits={maxChargedCredits}
        />
      )}
    </div>
  );
}