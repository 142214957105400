interface ContactFormData {
  name: string;
  email: string;
  category: string;
  message: string;
}

export async function sendContactForm(data: ContactFormData): Promise<void> {
  // TODO: 実際のお問い合わせ送信ロジックを実装
  // 開発環境では模擬的な遅延を入れる
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  console.log('Contact form submitted:', data);
}