import React from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { ZoomableImage } from '../images/ZoomableImage';

interface ImagePopupProps {
  images: string[];
  currentIndex: number;
  title?: string;
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

export function ImagePopup({
  images,
  currentIndex,
  title,
  onClose,
  onPrevious,
  onNext
}: ImagePopupProps) {
  const [isZoomed, setIsZoomed] = React.useState(false);
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isZoomed) return;
    
    switch (e.key) {
      case 'ArrowLeft':
        if (currentIndex > 0) onPrevious();
        break;
      case 'ArrowRight':
        if (currentIndex < images.length - 1) onNext();
        break;
      case 'Escape':
        onClose();
        break;
    }
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Swipe handlers
  const handleTouchStart = (e: React.TouchEvent) => {
    if (isZoomed) return;
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (isZoomed) return;
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd || isZoomed) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe && currentIndex < images.length - 1) {
      onNext();
    }

    if (isRightSwipe && currentIndex > 0) {
      onPrevious();
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, isZoomed]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-50"
        onClick={handleBackdropClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Title */}
        {title && (
          <div className="absolute top-0 left-0 right-0 p-4 bg-gradient-to-b from-black/80 to-transparent">
            <h2 className="text-white text-lg font-medium text-center max-w-4xl mx-auto">
              {title}
            </h2>
          </div>
        )}

        {/* Close button - Desktop */}
        <button
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
          className="absolute top-4 right-4 text-white hover:text-gray-300 z-50 md:block hidden"
        >
          <X className="w-8 h-8" />
        </button>

        {/* Navigation buttons */}
        {!isZoomed && (
          <>
            {currentIndex > 0 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onPrevious();
                }}
                className="absolute left-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300 md:opacity-75 hover:opacity-100 transition-opacity"
              >
                <ChevronLeft className="w-12 h-12" />
              </button>
            )}

            {currentIndex < images.length - 1 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onNext();
                }}
                className="absolute right-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300 md:opacity-75 hover:opacity-100 transition-opacity"
              >
                <ChevronRight className="w-12 h-12" />
              </button>
            )}
          </>
        )}

        {/* Main image container */}
        <div 
          ref={containerRef}
          className="relative max-w-[90vw] max-h-[90vh] overflow-hidden"
          onClick={e => e.stopPropagation()}
        >
          {/* Close button - Mobile (positioned relative to image) */}
          <button
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            className="absolute top-2 right-2 text-white hover:text-gray-300 z-50 md:hidden bg-black bg-opacity-50 rounded-full p-1"
          >
            <X className="w-6 h-6" />
          </button>

          <ZoomableImage
            src={images[currentIndex]}
            alt={`商品画像 ${currentIndex + 1}`}
            onZoom={setIsZoomed}
          />

          {/* Image counter */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white bg-black bg-opacity-50 px-3 py-1 rounded-full">
            {currentIndex + 1} / {images.length}
          </div>
        </div>

        {/* Help text */}
        <div className="absolute bottom-4 left-4 text-white text-sm space-y-1">
          {!isZoomed && (
            <>
              <div className="bg-black bg-opacity-50 px-2 py-1 rounded md:block hidden">
                <kbd className="px-1 bg-gray-700 rounded">←</kbd>
                <kbd className="px-1 bg-gray-700 rounded ml-1">→</kbd> 画像切替
              </div>
              <div className="bg-black bg-opacity-50 px-2 py-1 rounded md:block hidden">
                <kbd className="px-1 bg-gray-700 rounded">ESC</kbd> 閉じる
              </div>
              <div className="bg-black bg-opacity-50 px-2 py-1 rounded md:hidden">
                スワイプで画像切替
              </div>
            </>
          )}
          <div className="bg-black bg-opacity-50 px-2 py-1 rounded">
            クリックまたはホイールで拡大/縮小
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}