import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export function BackToLogin() {
  return (
    <div className="text-center">
      <Link
        to="/login"
        className="inline-flex items-center gap-2 text-sm font-medium text-blue-600 hover:text-blue-500"
      >
        <ArrowLeft className="w-4 h-4" />
        <span>ログインページに戻る</span>
      </Link>
    </div>
  );
}